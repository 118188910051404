import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useSideModalScrollToTop } from "hooks";

import { selectUserDetails, selectUserDashboardDetails, selectUserAdditionalData } from "store/user/account/selectors";
import { setInvestmentStepData } from "store/user/investment/actions";
import { setUserDetailsStart } from "store/user/account/actions";

const pick = require("lodash/pick");
import { HIDE_KYC_TILE_STATUS_STEP, USER_KYC_DETAILS_INPUT } from "constants/index";

import { RenderKycStep } from "components/KycModal";

const UserData = ({
    setStep,
    setInvestmentStepData,
    userAdditionalData,
    setUserDetailsStart,
    onCloseButtonClick,
    userDashboardDetails,
    open,
    setBackSlideEffect,
}) => {
    useSideModalScrollToTop();

    const [step, setKYCStep] = useState(1);

    const StatusKYC = userDashboardDetails && userDashboardDetails.StatusKYC;

    const isKycInitiated = !!HIDE_KYC_TILE_STATUS_STEP.find((status) => status === StatusKYC);

    useEffect(() => {
        isKycInitiated && setKYCStep(3);
    }, [isKycInitiated]);

    function setUserDetails(userAdditionalData) {
        let userDetails = { ...userAdditionalData.personal, ...userAdditionalData.identification };
        userDetails = pick(userDetails, USER_KYC_DETAILS_INPUT);
        setUserDetailsStart(userDetails);
    }

    return useMemo(() => {
        return (
            <RenderKycStep
                isWithSecondaryMarket
                onPersonalBack={() => {
                    setStep("start");
                    setBackSlideEffect("step-slide-right");
                }}
                setStep={(step) => (step === null ? onCloseButtonClick() : setKYCStep(step))}
                step={step}
                userAdditionalData={userAdditionalData}
                setInvestmentStepData={setInvestmentStepData}
                setUserDetailsStart={setUserDetails}
                kycCompleted={isKycInitiated}
                onCloseButtonClick={onCloseButtonClick}
                open={open}
            />
        );
    }, [step, userAdditionalData, isKycInitiated, open]);
};

const mapStateToProps = createStructuredSelector({
    userAdditionalData: selectUserAdditionalData,
    userDashboardDetails: selectUserDashboardDetails,
    userData: selectUserDetails,
});

const mapDispatchToProps = (dispatch) => ({
    setInvestmentStepData: (data) => dispatch(setInvestmentStepData(data)),
    setUserDetailsStart: (data) => dispatch(setUserDetailsStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserData);
