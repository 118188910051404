import { adequacySkipLinkText } from "i18n/messages/buttonTitles";
import PrevButton from "components/PrevButton";
import NextButton from "components/NextButton";
import { CURRENT_JOB_DATA } from "constants/index";
import { useSideModalScrollToTop } from "hooks";

const CurrentJob = ({
    backSlideEffect = "",
    setBackSlideEffect,
    formData,
    setFormData,
    setAdequacyStart,
    adequacyData,
    formatMessage,
    messages,
    labelIDPrefix,
    open,
    // setPreviousStep,
    StatusAdequacy,
}) => {
    useSideModalScrollToTop();

    return (
        <>
            <div className={`current-education multisteps ${backSlideEffect}`}>
                <div className="header-part">
                    <h4 className="primary-color">{formatMessage(messages.jobHeading)}</h4>
                </div>
                <div className="content">
                    <form
                        method="post"
                        id={labelIDPrefix ? `${labelIDPrefix}_current_job` : "current_job"}
                        className="survey-form"
                    >
                        <h3 className="mt-4 mb-7">{formatMessage(messages.jobQuestion)}</h3>
                        <div className="tabs">
                            {CURRENT_JOB_DATA()?.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <input
                                            onChange={({ target: { value: TypeJob } }) =>
                                                setFormData((prevState) => ({ ...prevState, TypeJob }))
                                            }
                                            value={item.value}
                                            id={labelIDPrefix ? `${labelIDPrefix}_${item.value}` : item.value}
                                            type="radio"
                                            name="job-question"
                                            checked={item.value === formData?.TypeJob}
                                        />
                                        <label
                                            className="new-btn medium mw-100"
                                            htmlFor={labelIDPrefix ? `${labelIDPrefix}_${item.value}` : item.value}
                                        >
                                            {item.label}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </form>
                </div>
                {StatusAdequacy != "renew" && (
                    <span
                        onClick={() => {
                            setAdequacyStart({ step: 2 });
                            setBackSlideEffect("step-slide-right");
                        }}
                        className="skip-link"
                    >
                        {formatMessage(adequacySkipLinkText)}
                    </span>
                )}
            </div>
            {open && (
                <div className="bottom-bar-container">
                    <PrevButton
                        onClick={() => {
                            setAdequacyStart({ step: 1 });
                            setBackSlideEffect("step-slide-right");
                        }}
                    />
                    <NextButton
                        className={`${formData?.TypeJob ? "" : "disable-grey"}`}
                        onClick={() => {
                            setBackSlideEffect("step-slide-left");
                            formData?.TypeJob &&
                                setAdequacyStart({
                                    step: 4,
                                    survey_first_step: {
                                        ...adequacyData.survey_first_step,
                                        ...formData,
                                        step: 2,
                                    },
                                });
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default CurrentJob;
