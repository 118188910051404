import React, { useState } from "react";
import Checkbox from "components/Checkbox";
import Loader from "components/Loader";
import { useFormattedMessage } from "hooks";
import { subscribeTag } from "api";
import { PROJECT_STATUS } from "constants/status";

const messages = {
    [PROJECT_STATUS.ADVERTISING]: "project_status_advertising",
    [PROJECT_STATUS.VIP_FUNDING]: "project_status_vip_funding",
    [PROJECT_STATUS.FUNDING_ACTIVE]: "project_status_funding_active",
    [PROJECT_STATUS.FUNDING_CLOSED]: "project_status_funding_closed",
    [PROJECT_STATUS.FINANCING_COMPLETED]: "project_status_financing_completed",
    [PROJECT_STATUS.MARKETPLACE]: "project_status_marketplace",
    [PROJECT_STATUS.MARKETPLACE_PAUSED]: "project_status_marketplace_paused",
    [PROJECT_STATUS.SUCCESSFULLY_PAID_BACK]: "project_status_successfully_paid_back",
    [PROJECT_STATUS.CLUB_DEAL]: "exclusive_clubdeal",
    default: "status_default",
};

const Product = ({ className = "", project, refetchUserSubscriptions = () => {} }) => {
    const { formatMessage } = useFormattedMessage();
    const [isSubmitting, setSubmitting] = useState(false);

    const onClick = async (Tag, value) => {
        try {
            if (isSubmitting) return false;
            setSubmitting(!isSubmitting);
            const res = await subscribeTag({ tags: [{ Tag: Tag, StatusTag: value ? "active" : "inactive" }] });
            if (res.data && res.data.errors && res.data.errors[0]) {
                console.error(res.data.errors, "res.data.errors");
            } else {
                project.isSubscribed = false;
                refetchUserSubscriptions();
            }
            setSubmitting(false);
        } catch (error) {
            console.error(error, "catch.errors");
            setSubmitting(false);
        }
    };
    return (
        <div
            className={`${
                project.isSubscribed ? "" : "connector-low-opacity"
            } ${className} shadow flex flex-wrap order-item-block`}
        >
            <div className="img-block">
                <img className="image" src={`${project.images.teaserImage.path}?w=360`} alt="" />
            </div>
            <div className="details-container mb-0">
                <div>
                    <div className="flex justify-between">
                        <div className="flex column w-80">
                            <div className="flex column">
                                <h5 className="mt-0 mb-1 property-item-text-overflow">{project.title}</h5>
                                <span className="fw-500 text-14">{project.address.city}</span>
                            </div>
                            <div className="flex justify-between align-center mt-5">
                                <span className="text-12 text-sm-10 fw-500 deactivated-tag">
                                    {formatMessage(messages[project.status.status] || messages["default"])}
                                </span>
                            </div>
                        </div>
                        <div className="flex align-center">
                            {isSubmitting ? (
                                <Loader className="primary-color" />
                            ) : (
                                <Checkbox
                                    inputId="project.projectID"
                                    name="project.projectID"
                                    value={project.isSubscribed}
                                    onChange={() => onClick()}
                                    isDisabled={!project.isSubscribed}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;
