import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { finishButtonText } from "i18n/messages/buttonTitles";
import Card from "assets/images/svg/card.svg";
import { selectAuthToken } from "store/user/account/selectors";
import TopGreyDiv from "../components/topGreyDiv";
import PrevButton from "components/PrevButton";
import NextButton from "components/NextButton";
import Info from "./components/info";
import Live from "./components/live";
import { sendFormError } from "i18n/messages/errors";
import { getDocumentsHost } from "common/helper";
import { downloadHeaders } from "api";

const DOCUMENT_HOST = getDocumentsHost();

const messages = {
    rifCloseTitle: "wallet_payout_title",
    rifBackInfoTitle: "activation_for_payouts",
    rifBackInfoContent: "second_step_capture_back_id_card",
    rifBackInfoStepHeading: "back_of_id_card",
    rifBackInfoStepLabelText: "example_recording",
    rifBackInfoButtonText: "capture_back_side",
    rifBackLiveTitle: "activation_for_payouts",
    rifBackLiveContent: "using_webcame_capture_back_id_card",
    rifBackLiveStepLabelText: "live_video",
    rifBackLiveButtonText: "take_photo",
    rifBackCapturedTitle: "activation_for_payouts",
    rifBackCapturedContent: "using_webcame_capture_back_id_card",
    rifBackCapturedStepLabelText: "your_photo",
    rifBackCapturedBottomContent: "photo_legible_and_data_recognizable",
    rifBackCapturedButtonText: "retake_photo",
};

const Back = ({
    setCloseTitle,
    setRetakeStep,
    backToFront,
    setStepAndData,
    stepData,
    backSlideEffect = "",
    setBackSlideEffect,
}) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();
    const [backStep, setBackStep] = useState("info");
    const [isDocumentUploading, setDocumentUploading] = useState(false);
    const [requestError, setRequestError] = useState(false);

    useEffect(() => {
        setCloseTitle(formatMessage(messages.rifCloseTitle));
    }, []);

    const renderBackStep = {
        info: (
            <Info
                backSlideEffect={backSlideEffect}
                title={formatMessage(messages.rifBackInfoTitle)}
                content={formatMessage(messages.rifBackInfoContent)}
                stepHeading={formatMessage(messages.rifBackInfoStepHeading)}
                text={formatMessage(messages.rifBackInfoStepLabelText)}
                imageClass="border-dashed"
                image={"/images/id-back-preview.jpg"}
                buttonText={formatMessage(messages.rifBackInfoButtonText)}
                onImageClick={() => {
                    setBackStep("live");
                    setBackSlideEffect("step-slide-left");
                }}
                onClick={() => {
                    setBackStep("live");
                    setBackSlideEffect("step-slide-left");
                }}
            />
        ),
        live: (
            <Live
                backSlideEffect={backSlideEffect}
                title={formatMessage(messages.rifBackLiveTitle)}
                content={formatMessage(messages.rifBackLiveContent)}
                stepHeading={formatMessage(messages.rifBackInfoStepHeading)}
                labelText={formatMessage(messages.rifBackLiveStepLabelText)}
                buttonText={formatMessage(messages.rifBackLiveButtonText)}
                onCapture={(imgSrc) => {
                    setStepAndData("retakeIF", { ...stepData, backImg: imgSrc });
                    setBackStep("captured");
                    setBackSlideEffect("step-slide-left");
                }}
            />
        ),
        captured: (
            <Info
                backSlideEffect={backSlideEffect}
                title={formatMessage(messages.rifBackCapturedTitle)}
                content={formatMessage(messages.rifBackCapturedContent)}
                stepHeading={formatMessage(messages.rifBackInfoStepHeading)}
                text={formatMessage(messages.rifBackCapturedStepLabelText)}
                imageClass="mx-auto d-block captured-img"
                image={stepData?.backImg}
                bottomContent={formatMessage(messages.rifBackCapturedBottomContent)}
                isButtonSecondary
                buttonText={formatMessage(messages.rifBackCapturedButtonText)}
                onClick={() => {
                    setBackStep("live");
                    setBackSlideEffect("step-slide-right");
                }}
            />
        ),
    };

    const stepRender = (step) => {
        return renderBackStep[step] || renderBackStep["info"];
    };

    const onBack = () => {
        setBackSlideEffect("step-slide-right");

        if (backStep === "info") {
            setRetakeStep("front");
            backToFront("captured");
        } else if (backStep === "live") {
            setBackStep("info");
        } else if (backStep === "captured") {
            setBackStep("live");
        }
    };

    const onSubmit = () => {
        try {
            setDocumentUploading(true);
            const images = stepData;
            const promises = [];
            for (let i = 0; i < Object.keys(images).length; i++) {
                promises.push(
                    new Promise((resolve, reject) => {
                        fetch(images[Object.keys(images)[i]])
                            .then((res) => res.blob())
                            .then((blob) => {
                                const file = new File([blob], `${Object.keys(images)[i]}.jpeg`, {
                                    type: "image/jpeg",
                                });
                                resolve(file);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    })
                );
            }
            Promise.all(promises).then(function (files) {
                const data = new FormData();
                data.append("identityPic[]", files[0], "frontImg.jpeg");
                data.append("identityPic[]", files[1], "backImg.jpeg");

                fetch(`${DOCUMENT_HOST}/upload/mangopayIdentificationDocument`, {
                    "Content-Type": "application/json",
                    method: "post",
                    headers: downloadHeaders(),
                    body: data,
                })
                    .then((response) => {
                        console.log("response", response);
                        setDocumentUploading(false);
                        if (response.ok) {
                            setRetakeStep("success");
                            setRequestError(false);
                            setBackSlideEffect("step-slide-left");
                        } else {
                            setRequestError(true);
                        }
                    })
                    .catch((error) => {
                        console.log("error", error);
                        setDocumentUploading(false);
                        setRequestError(true);
                    });
            });
        } catch (error) {
            console.log("error", error);
        }
    };

    return (
        <div className="retakeif-back">
            <TopGreyDiv icon svgIcon={<Card style={{ maxWidth: "100px" }} />} />
            <div className="px-5 py-4 mw-450 mx-auto">
                {stepRender(backStep)}
                {requestError && !isDocumentUploading && <span className="error pl-0">{formatMessage(sendFormError)}</span>}
            </div>
            <div className="bottom-bar-container">
                <PrevButton onClick={onBack} disable={isDocumentUploading} />
                {backStep === "captured" && (
                    <NextButton
                        title={formatMessage(finishButtonText)}
                        onClick={onSubmit}
                        isLoading={isDocumentUploading}
                        disable={isDocumentUploading}
                    />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    authToken: `Bearer ${selectAuthToken(state)}`,
});

export default connect(mapStateToProps)(Back);
