import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { TENANTS_LIST } from "constants/index";
import CustomLink from "components/CustomLink";
import PrevButton from "components/PrevButton";
import { estimatedWaiting } from "api";
import { selectTenant } from "store/app/selectors";

const messages = {
    providerHeading: "please_identify_yourself",
    freeText: "free",
    onlineText: "online",
    identifyText: "identify",
    onlineHomeText: "online_from_home",
    videoChatText: "video_chat_via_web_app",
    longOpeningText: "long_opening_times",
    oneMinute: "minute",
    moteThanOneMinute: "miutes",
    waitingTimeText: "current_waiting_time_identification",
    IDnowButtonText: "continue_with_idnow",
    personallyText: "personally",
    personallyBranchText: "personally_in_the_branch",
    texhnicalKnowledgeText: "without_technical_knowledge",
    printerNeededText: "printer_is_needed",
    postidentButtonText: "continue_with_postident",
    unknownText: "unknown_text",
};

const KycProvider = ({
    className = "",
    isRequiredBackButton,
    externalUrl,
    onPostidentClick,
    onExternalLinkClick,
    onBack,
    open,
    backSlideEffect = "",
    currentTenant,
}) => {
    useSideModalScrollToTop();

    useEffect(() => {
        open &&
            estimatedWaiting().then((response) => {
                const time =
                    response &&
                    response.data &&
                    response.data.data &&
                    response.data.data.estimatedWaiting &&
                    response.data.data.estimatedWaiting.time;

                const error = response && response.data && response.data.errors && response.data.errors[0].message;

                const text = time
                    ? `${time} ${
                          time > 1 ? formatMessage(messages.moteThanOneMinute) : formatMessage(messages.oneMinute)
                      } ${formatMessage(messages.waitingTimeText)}`
                    : error === "undefined"
                    ? formatMessage(messages.unknownText)
                    : "";

                setWaitingTime(text);
            });
    }, [open]);
    const { formatMessage } = useFormattedMessage();
    const [waitingTime, setWaitingTime] = useState("");

    return (
        <>
            <div className={`kyc-provider ${className} ${backSlideEffect}`}>
                <h3 className="mb-8">{formatMessage(messages.providerHeading)}</h3>
                <div className="provider-block idnow mt-0">
                    <img src={"/images/idnow.png"} alt="" />
                    <div className="points">
                        {formatMessage(messages.freeText)}
                        <span className="v-line">|</span>
                        {formatMessage(messages.onlineText)}
                        <span className="v-line">|</span>
                        {formatMessage(messages.identifyText)}
                    </div>
                    <ul>
                        <li>
                            <img
                                src={
                                    currentTenant === TENANTS_LIST.SACHWERTINVEST
                                        ? "/images/sachwertinvest-check.png"
                                        : "/images/check.png"
                                }
                                alt=""
                            />
                            {formatMessage(messages.onlineHomeText)}
                        </li>
                        <li>
                            <img
                                src={
                                    currentTenant === TENANTS_LIST.SACHWERTINVEST
                                        ? "/images/sachwertinvest-check.png"
                                        : "/images/check.png"
                                }
                                alt=""
                            />
                            {formatMessage(messages.videoChatText)}
                        </li>
                        <li>
                            <img
                                src={
                                    currentTenant === TENANTS_LIST.SACHWERTINVEST
                                        ? "/images/sachwertinvest-check.png"
                                        : "/images/check.png"
                                }
                                alt=""
                            />
                            {formatMessage(messages.longOpeningText)}
                        </li>
                    </ul>
                    <div className="points" style={{ color: "black", wordBreak: "break-word" }}>
                        {waitingTime}
                    </div>
                    <CustomLink isExternal url={externalUrl}>
                        <button className="mw-100 mb-0" onClick={onExternalLinkClick}>
                            {formatMessage(messages.IDnowButtonText)}
                            <i className="fas fa-external-link-alt ml-3" />
                        </button>
                    </CustomLink>
                </div>
                <div className="provider-block postident">
                    <img src={"/images/postident.png"} alt="" />
                    <div className="points">
                        {formatMessage(messages.freeText)}
                        <span className="v-line">|</span>
                        {formatMessage(messages.personallyText)}
                        <span className="v-line">|</span>
                        {formatMessage(messages.identifyText)}
                    </div>
                    <ul>
                        <li>
                            <img
                                src={
                                    currentTenant === TENANTS_LIST.SACHWERTINVEST
                                        ? "/images/sachwertinvest-check.png"
                                        : "/images/check.png"
                                }
                                alt=""
                            />
                            {formatMessage(messages.personallyBranchText)}
                        </li>
                        <li>
                            <img
                                src={
                                    currentTenant === TENANTS_LIST.SACHWERTINVEST
                                        ? "/images/sachwertinvest-check.png"
                                        : "/images/check.png"
                                }
                                alt=""
                            />
                            {formatMessage(messages.texhnicalKnowledgeText)}
                        </li>
                        <li>
                            <img src={"/images/cross.png"} alt="" />
                            {formatMessage(messages.printerNeededText)}
                        </li>
                    </ul>
                    <button className="mw-100 mb-0" onClick={onPostidentClick}>
                        {formatMessage(messages.postidentButtonText)}
                    </button>
                </div>
            </div>
            {open && (
                <div className="bottom-bar-container">
                    {isRequiredBackButton && <PrevButton className="kp-back-button" onClick={onBack} />}
                </div>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    currentTenant: selectTenant,
});

export default connect(mapStateToProps)(KycProvider);
