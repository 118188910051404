import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { de } from "date-fns/locale";

import { useFormattedMessage } from "hooks";
registerLocale("de", de);

const messages = {
    dob: "date_of_birth",
};

const DatepickerField = ({
    className = "",
    placeholder,
    label,
    peekNextMonth,
    showMonthDropdown,
    showYearDropdown,
    minDate,
    maxDate,
    dateFormat = "dd.MM.yyyy",
    errorMessage = "",
    onChange,
    errorClassName = "error-element",
    success = false,
    errorIcon = true,
    error,
    onFocus,
    onBlur,
    datePickerClassName = "",
    selected,
    disabled = false,
    isDateIcon = false,
    locale = "de",
    ...rest
}) => {
    const { formatMessage } = useFormattedMessage();

    return (
        <div className={`form-element ${className} ${error && errorMessage && `${errorClassName}`}`}>
            {label && <label className="mb-2 d-inline-block">{label}</label>}
            <div className="position-relative">
                <DatePicker
                    {...rest}
                    locale={locale}
                    className={datePickerClassName}
                    placeholderText={placeholder || formatMessage(messages.dob)}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    selected={selected}
                    minDate={minDate}
                    maxDate={maxDate}
                    dateFormat={dateFormat}
                    peekNextMonth={peekNextMonth}
                    showMonthDropdown={showMonthDropdown}
                    showYearDropdown={showYearDropdown}
                    closeOnScroll
                    popperModifiers={{
                        offset: {
                            enabled: true,
                            offset: "5px, 10px",
                        },
                        preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: "viewport",
                        },
                    }}
                    disabled={disabled}
                />
                {isDateIcon && <i className="fas fa-calendar-alt"> </i>}
                {success && !errorMessage && <i className="fas fa-check" />}
                {error && (
                    <>
                        {errorMessage && errorIcon && <i className={`fas fa-exclamation-triangle${label && " with-label"}`} />}
                        {errorMessage && <label className="error-label text-left">{errorMessage}</label>}
                    </>
                )}
            </div>
        </div>
    );
};

export default DatepickerField;
