import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectAdequacy, selectFetchAdequacy } from "store/user/adequacy/selectors";
import { setAdequacyStart, fetchAdequacySuccess, setAdequacyRevoke } from "store/user/adequacy/actions";
import { userDashboardDetailsSuccess } from "store/user/account/actions";
import { selectUserDashboardDetails } from "store/user/account/selectors";
import PrevButton from "components/PrevButton";
import Loader from "components/Loader";
import { setAddequacyNotWantedData } from "api";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import IntlFormattedMessage from "components/IntlFormattedMessage";
import { nextButtonText, investmentCancelLinkText } from "i18n/messages/buttonTitles";

const messages = {
    heading: "experience_and_kowledge",
    content: "according_to_security_trading_act",
    cancelButtonText: "do_not_want_to_provide_any_info",
    cancelReturnOverview: "cancle_and_return_to_overview",
    revokeButtonText: "revoke_adequacy_data",
};

const IntroductionStep = ({
    className = "",
    setAdequacyStart,
    adequacyData,
    fetchAdequacyData,
    fetchAdequacySuccess,
    userDashboardDetailsSuccess,
    changeStepBack,
    hitButtonBack,
    onSkip,
    onBack,
    isRequiredBackButton = false,
    isRequiredSkipButton = false,
    userDashboardData,
    returnOverview,
    isWithSetting,
    backSlideEffect = "",
    setBackSlideEffect,
    setAdequacyRevoke,
}) => {
    useSideModalScrollToTop();

    useEffect(() => {
        if (changeStepBack) {
            hitButtonBack();
            onBack(userDashboardData);
        }
    }, [changeStepBack]);

    const { formatMessage } = useFormattedMessage();
    const [adequacyNotWantedLoading, setAdequacyNotWantedLoading] = useState(false);
    const { IsNotWantedConfirmed } = fetchAdequacyData;

    async function onNotWantedSurvey() {
        try {
            setAdequacyNotWantedLoading(true);
            const response = await setAddequacyNotWantedData({ IsNotWantedConfirmed: true });
            const { Email } = response.data.data.adequacyTile.user;
            if (Email) {
                setAdequacyNotWantedLoading(false);
                fetchAdequacySuccess({
                    ...fetchAdequacyData,
                    IsNotWantedConfirmed: true,
                    IsNotWantedConfirmedDate: new Date(),
                });
                userDashboardDetailsSuccess({ ...userDashboardData, StatusAdequacy: "hide" });
                setAdequacyRevoke({ step: 1 });
                setBackSlideEffect("step-slide-right");
                returnOverview(userDashboardData);
            }
        } catch (error) {
            /* error */
        }
    }

    return (
        <>
            <div className={`${className} adequacy-container first-step ${backSlideEffect}`}>
                <div className="header-part">
                    <h3>{formatMessage(messages.heading)}</h3>
                </div>
                <div className="content">
                    <IntlFormattedMessage id={messages.content} />
                </div>
                <button
                    disabled={adequacyNotWantedLoading}
                    className="my-7 mw-100"
                    onClick={() => {
                        setBackSlideEffect("step-slide-left");
                        setAdequacyStart({
                            step: 4,
                            survey_first_step: { ...adequacyData.survey_first_step, step: 1 },
                        });
                    }}
                >
                    {formatMessage(nextButtonText)}
                </button>

                {userDashboardData.StatusAdequacy == "renew" && isWithSetting && !IsNotWantedConfirmed && (
                    <button disabled={adequacyNotWantedLoading} className={"mt-7 mw-100"} onClick={onNotWantedSurvey}>
                        {adequacyNotWantedLoading ? (
                            <>
                                <Loader /> {formatMessage(messages.revokeButtonText)}
                            </>
                        ) : (
                            formatMessage(messages.revokeButtonText)
                        )}
                    </button>
                )}

                {userDashboardData.StatusAdequacy == "renew" && isWithSetting ? (
                    <button
                        disabled={adequacyNotWantedLoading}
                        className="secondary mt-7 mw-100 px-2"
                        onClick={() => {
                            returnOverview(userDashboardData);
                            setBackSlideEffect("step-slide-right");
                        }}
                    >
                        {formatMessage(messages.cancelReturnOverview)}
                    </button>
                ) : (
                    <button
                        className="secondary mt-7 mw-100 px-2"
                        onClick={() => {
                            setAdequacyStart({ step: 2 });
                            setBackSlideEffect("step-slide-left");
                        }}
                    >
                        {formatMessage(messages.cancelButtonText)}
                    </button>
                )}
            </div>
            {isRequiredSkipButton && (
                <button type="button" className="cancel medium cta-inverse" onClick={onSkip}>
                    {formatMessage(investmentCancelLinkText)}
                </button>
            )}
            {isRequiredBackButton && (
                <div className="bottom-bar-container">
                    <PrevButton onClick={() => onBack(userDashboardData)} />
                </div>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    adequacyData: selectAdequacy,
    userDashboardData: selectUserDashboardDetails,
    fetchAdequacyData: selectFetchAdequacy,
});

const mapDispatchToProps = (dispatch) => ({
    setAdequacyStart: (data) => dispatch(setAdequacyStart(data)),
    setAdequacyRevoke: (data) => dispatch(setAdequacyRevoke(data)),
    userDashboardDetailsSuccess: (data) => dispatch(userDashboardDetailsSuccess(data)),
    fetchAdequacySuccess: (data) => dispatch(fetchAdequacySuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntroductionStep);
