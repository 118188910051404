import React from "react";

import Link from "components/NavLink";

import { useFormattedMessage } from "hooks";
import { GENERAL_CONSTANTS } from "constants/index";
import AppStoreButton from "assets/images/svg/app-store-badge.svg";
import PlayStoreButton from "assets/images/svg/google-play-badge.svg";
const messages = {
    title: "mobile_app_title",
};

const MobileApp = ({ className = "", title, playStoreLink, appStoreLink, noTitle = false }) => {
    const { formatMessage } = useFormattedMessage();

    return (
        <div className={`${className}`}>
            {!noTitle && <h4>{title || formatMessage(messages.title)}</h4>}
            <ul className="app-list flex flex-wrap justify-between">
                <li className="list-item mr-4">
                    {/* <span className="google-btn" /> */}
                    <Link isExternal href={appStoreLink || GENERAL_CONSTANTS.APP_STORE_LINK} className="apple-btn app-btn">
                        <AppStoreButton style={{ height: "100%", width: "100%" }} />
                    </Link>
                </li>
                <li className="list-item ">
                    <Link isExternal href={playStoreLink || GENERAL_CONSTANTS.PLAY_STORE_LINK} className="google-btn app-btn">
                        <PlayStoreButton style={{ height: "100%", width: "100%" }} />
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default MobileApp;
