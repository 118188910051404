import { createSelector } from "reselect";
import { INVESTMENT_STATUS } from "constants/status";

const getInvestment = (state) => state.investment;
const investmentDetails = (state) => state.investment.data;

export const selectContinueInvestments = createSelector(investmentDetails, (investmentData) => {
    const continueStatus = [INVESTMENT_STATUS.RESERVED, INVESTMENT_STATUS.INITIALIZED];

    return (
        (Array.isArray(investmentData) &&
            investmentData.length &&
            investmentData.find((item) => item.StatusInvestment === continueStatus.find((i) => i === item.StatusInvestment))) ||
        {}
    );
});

export const selectIsInvestmentCreated = createSelector(getInvestment, (investment) => investment.isInvestmentCreated);

export const selectIsInvestmentLoading = createSelector(getInvestment, (investment) => investment.isInvestmentLoading);

export const selectIsInvestmentFailed = createSelector(getInvestment, (investment) => investment.error);

export const selectInvestmentDetails = createSelector(investmentDetails, (investmentData) => investmentData);

const fullInvestmentDetails = (state) => state.investment;
export const selectFullInvestmentDetails = createSelector(fullInvestmentDetails, (fullInvestmentData) => fullInvestmentData);

const investmentStepDetails = (state) => state.investment.stepData;
export const selectInvestmentStepData = createSelector(investmentStepDetails, (investmentStepData) => investmentStepData);

const investmentSuccessDetails = (state) => state.investment.successData;
export const selectInvestmentSuccessData = createSelector(
    investmentSuccessDetails,
    (investmentSuccessData) => investmentSuccessData
);

const investmentFailure = (state) => state.investment.error;
export const selectInvestmentFailure = createSelector(investmentFailure, (investmentSuccessData) => investmentSuccessData);

const investmentAmountError = (state) => state.investment.amountError;
const investmentAmountValue = (state) => state.investment.totalInvestmentAmount;
const investmentAmountLoading = (state) => state.investment.isInvestmentAmountLoading;

export const selectInvestmentAmountResult = createSelector(
    investmentAmountLoading,
    investmentAmountValue,
    investmentAmountError,
    (isAmountloading, investmentAmountData, amountError) => {
        return { isAmountloading, investmentAmountData, amountError };
    }
);
