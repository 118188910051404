import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useRouter } from "next/router";

import { useFormattedMessage, useUtils } from "hooks";
import SelectInput from "components/SelectInput";
import Loader from "components/Loader";
import { setLocale } from "store/app/actions";
import { setInvestmentStepData } from "store/user/investment/actions";
import { setMySettings } from "api";
import { selectUserAdditionalData } from "store/user/account/selectors";
import i18nInstance from "i18n/i18n";

const messages = {
    pleaseSelect: "please_select",
    languageSubTitle: "language_sub_title",
    nextButtonText: "tipster_form_button_text",
    languageEnglish: "language_english",
    languageGerman: "language_german",
};

const LanguageModal = ({ locale, setInvestmentStepData, setLocale, userAdditionalData }) => {
    const { formatMessage } = useFormattedMessage();
    const languageOption = [
        { label: formatMessage(messages.languageEnglish), value: "en", searchLabel: formatMessage(messages.languageEnglish) },
        { label: formatMessage(messages.languageGerman), value: "de", searchLabel: formatMessage(messages.languageGerman) },
    ];
    const router = useRouter();
    const [isSubmitting, setSubmitting] = useState(false);
    const isApp = useUtils().isPlatformSourceFromApp;

    const lang = languageOption.find((item) => item.value == locale);
    const [language, setLanguage] = useState(lang);
    const [isChanged, setChanged] = useState(false);

    useEffect(() => {
        locale != language.value && setChanged(true);
    }, [language]);

    const onLanguageSubmit = async (finalPreferredLanguage) => {
        setSubmitting(true);
        setInvestmentStepData({
            userData: {
                identificationData: {
                    ...userAdditionalData.identificationData,
                    PreferredLanguage: finalPreferredLanguage,
                },
            },
        });

        setMySettings({ preferredLanguage: finalPreferredLanguage }).then(() => {
            setSubmitting(false);
            setChanged(false);
            finalPreferredLanguage && setLocale(finalPreferredLanguage.toLowerCase().trim());
            finalPreferredLanguage && i18nInstance.changeLanguage(finalPreferredLanguage.toLowerCase().trim());

            if (isApp) {
                window?.ReactNativeWebView?.postMessage(`locale=${finalPreferredLanguage}`);
                router.push(`/account?source=app&locale=${finalPreferredLanguage}`);
            }
        });
    };

    return (
        <div className="flex column py-3">
            <div className="text-16">{formatMessage(messages.languageSubTitle)}</div>
            <div className="mt-5">
                <SelectInput
                    className="mb-0 w-100"
                    name="language"
                    options={languageOption}
                    placeholder={formatMessage(messages.pleaseSelect)}
                    value={language}
                    onChange={setLanguage}
                    isMulti={false}
                    isDisabled={isSubmitting}
                />
                <button
                    className={`mt-10 mw-100 ${!isChanged || isSubmitting ? "disable-grey" : ""}`}
                    onClick={() => onLanguageSubmit(language.value)}
                    disabled={!isChanged || isSubmitting}
                >
                    {isSubmitting ? (
                        <>
                            <Loader /> {formatMessage(messages.nextButtonText)}
                        </>
                    ) : (
                        formatMessage(messages.nextButtonText)
                    )}
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    userAdditionalData: selectUserAdditionalData,
});

const mapDispatchToProps = (dispatch) => ({
    setLocale: (locale) => dispatch(setLocale(locale)),
    setInvestmentStepData: (data) => dispatch(setInvestmentStepData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageModal);
