import React, { useMemo, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useFormattedMessage } from "hooks";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Input from "components/Input";
import SelectInput from "components/SelectInput";
import NextButton from "components/NextButton";
import PrevButton from "components/PrevButton";
import { updateUserTaxDataTransitions, updateUserTaxDataStart } from "store/user/account/actions";
import { selectUpdateUserTaxDataTransitions, selectTaxData } from "store/user/account/selectors";
import { country, taxIdentificationNumber, religion, federalState, dataActions } from "i18n/messages/formElements";
import { FederalStatesGermanyOptions, groupedCountries } from "constants";
import { allCountries } from "mocks";
import { selectedCountry } from "constants/index";
import { validationButNotRequired } from "../../utils/yupValidation";

const messages = {
    ...dataActions,
    ...religion,
    ...taxIdentificationNumber,
    ...country,
    ...federalState,
    pleaseSelect: "please_select",
    submitButtonText: "tipster_form_button_text",
    taxNoteText: "note",
    taxNotePara1: "consent_to_retrieval_your_tax_data",
    taxNotePara2: "church_tax",
};

const TaxForm = ({
    taxData,
    setStep,
    open,
    transitions,
    updateUserTaxDataStart,
    updateUserTaxDataTransitions,
    backSlideEffect = "",
    setBackSlideEffect,
}) => {
    const { formatMessage } = useFormattedMessage();

    useEffect(() => {
        jQuery(".side-modal").scrollTop(0);
    }, []);

    const initialValues = useMemo(() => {
        if (taxData && Object.keys(taxData).length) {
            let country =
                taxData.Address && Array.isArray(taxData.Address) && taxData.Address.length ? taxData.Address[0].Country : null;
            const federalState = taxData.FederalState;
            country = country && allCountries ? allCountries.find((item) => item.value == country) : null;
            return {
                TaxID: taxData.TaxID,
                FederalState: federalState ? FederalStatesGermanyOptions.find((item) => item.value == federalState) : null,
                Religion: taxData.Religion,
                Country: country,
            };
        }

        return {
            TaxID: "",
            FederalState: null,
            Religion: "",
            Country: null,
        };
    }, [taxData]);

    const { isSubmitting, values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched } =
        useFormik({
            enableReinitialize: true,
            validateOnBlur: true,
            validateOnChange: true,
            initialValues: initialValues,
            validationSchema: yup.object().shape({
                Religion: yup.string(),
                TaxID: validationButNotRequired.taxIdentificationNumber,
            }),
            onSubmit: async (values) => {
                console.log("values", values);
                updateUserTaxDataStart({
                    TaxID: values?.TaxID || "",
                    FederalState: values?.FederalState?.value || "",
                    Religion: values?.Religion || "",
                    Country: values?.Country?.value || "",
                });
                setStep(1);
            },
        });

    useEffect(() => {
        if (transitions.isSuccessful) {
            updateUserTaxDataTransitions({
                isUpdating: false,
                isSuccessful: false,
                isFailed: false,
            });
        }
    }, [transitions]);

    return (
        <form method="post" onSubmit={handleSubmit} className={backSlideEffect}>
            <div className="data-block">
                <div className="details">
                    <div className="flex column">
                        <Input
                            type="text"
                            name="TaxID"
                            id="TaxID"
                            label={formatMessage(messages.taxIdentificationNumberPlaceholder)}
                            placeholder={formatMessage(messages.taxIdentificationNumberPlaceholder)}
                            onChange={handleChange}
                            value={values.TaxID}
                            errorMessage={errors.TaxID ? formatMessage(messages[errors.TaxID]) : null}
                            error={touched.TaxID && errors.TaxID}
                            onBlur={handleBlur}
                        />

                        <Input
                            type="text"
                            name="Religion"
                            id="Religion"
                            label={formatMessage(messages.religionPlaceholder)}
                            placeholder={formatMessage(messages.religionPlaceholder)}
                            onChange={handleChange}
                            value={values.Religion}
                            errorMessage={errors.Religion ? formatMessage(messages[errors.Religion]) : null}
                            error={touched.Religion && errors.Religion}
                            onBlur={handleBlur}
                        />

                        <SelectInput
                            maxMenuHeight={400}
                            isSearchable={false}
                            classNamePrefix="select"
                            name="Country"
                            options={groupedCountries()}
                            label={formatMessage(messages.countryPlaceholder)}
                            placeholder={formatMessage(messages.pleaseSelect)}
                            value={selectedCountry(values.Country?.value || "") || values.Country}
                            onChange={(value) => {
                                setFieldValue("Country", value);
                                setFieldTouched("Country", true);
                            }}
                            onBlur={handleBlur}
                        />

                        {values.Country?.value == "DE" && (
                            <>
                                <SelectInput
                                    maxMenuHeight={400}
                                    isSearchable={false}
                                    classNamePrefix="select"
                                    name="FederalState"
                                    options={FederalStatesGermanyOptions}
                                    label={formatMessage(messages.federalStatePlaceholder)}
                                    placeholder={formatMessage(messages.pleaseSelect)}
                                    value={values.FederalState}
                                    onChange={(value) => {
                                        setFieldValue("FederalState", value);
                                        setFieldTouched("Country", true);
                                    }}
                                    onBlur={handleBlur}
                                />
                                <p className="text-14 another-grey-color mb-2">{formatMessage(messages.taxNoteText)}</p>
                                <p className="text-14 another-grey-color">{formatMessage(messages.taxNotePara1)}</p>
                                <p className="text-14 another-grey-color">{formatMessage(messages.taxNotePara2)}</p>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {open && (
                <div className="bottom-bar-container">
                    <PrevButton
                        className="pd-back-button"
                        onClick={() => {
                            setStep(1);
                            setBackSlideEffect("step-slide-right");
                        }}
                    />
                    <NextButton
                        disable={!Object.keys(touched).length}
                        type="submit"
                        isLoading={isSubmitting}
                        title={formatMessage(messages.submitButtonText)}
                    />
                </div>
            )}
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    taxData: selectTaxData,
    transitions: selectUpdateUserTaxDataTransitions,
});

const mapDispatchToProps = (dispatch) => ({
    updateUserTaxDataTransitions: (value) => dispatch(updateUserTaxDataTransitions(value)),
    updateUserTaxDataStart: (value) => dispatch(updateUserTaxDataStart(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaxForm);
