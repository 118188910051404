import React, { useRef, useCallback, useState } from "react";
import Webcam from "react-webcam";

const Live = ({ title, content, stepHeading, text, buttonText, onCapture , backSlideEffect = ""}) => {
    const webcamRef = useRef(null);
    const [camHeight, setCamHeight] = useState(window.outerWidth < 768 ? 450 : "auto");

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot({ width: 550, height: 400 });
        onCapture(imageSrc);
    }, [webcamRef]);

    const videoConstraints = {
        facingMode: "environment",
    };

    return (
        <div className={backSlideEffect}>
            <h3 className="mb-6">{title}</h3>
            <p className="text-16 mb-6">{content}</p>
            <h4 className="mb-6">{stepHeading}</h4>
            <span className="text-14">{text}</span>
            <Webcam
                className="w-100 mw-100 mb-8 border-dashed"
                audio={false}
                height={camHeight}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                screenshotQuality={1}
                videoConstraints={videoConstraints}
            />
            <button className="mw-100" onClick={capture}>
                {buttonText}
            </button>
        </div>
    );
};

export default Live;
