import React from "react";

const Loader = ({ className, style = {} }) => {
    return (
        <div style={style} className={`loader-container ${className}`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}

export default Loader;
