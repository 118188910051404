import React from "react";
import { useFormattedMessage } from "hooks";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withFormik } from "formik";
import SideModal from "components/SideModal";
import Product from "./Product";
import { fetchProjectStart } from "store/project/actions";
import { selectSubscribedProject } from "store/project/selectors";
import { PROJECT_SELECTOR_LIMITED } from "common/constants";

const messages = {
    productSubscribedTitle: "product_subscribed_title",
    productSubscribedHeading: "product_subscribed_heading",
    productSubscribedSubHeading: "product_subscribed_sub_heading",
    noSubscriptionAvailable: "product_subscribed_not_available",
};

const WrappedProductSubscribedSideModal = ({
    modalOpen,
    onBackButtonClick,
    onCloseButtonClick,
    subscribedProjects,
    fetchProjectStart,
    refetchUserSubscriptions = () => {},
}) => {
    const { formatMessage } = useFormattedMessage();

    return (
        <SideModal
            open={modalOpen}
            isBackArrowOnTop
            onBackButtonClick={() => {
                onBackButtonClick();
            }}
            onCloseButtonClick={() => {
                onCloseButtonClick();
            }}
            heading={formatMessage(messages.productSubscribedTitle)}
        >
            <div className="product-subscribed">
                <div className="icon-section">
                    <i className="far fa-bell" />
                </div>
                <h4 className="heading">{formatMessage(messages.productSubscribedHeading)}</h4>
                {subscribedProjects.length > 0 && (
                    <p className="sub-heading">{formatMessage(messages.productSubscribedSubHeading)}</p>
                )}
                {subscribedProjects.length ? (
                    subscribedProjects.map((project, index) => {
                        return (
                            <Product
                                refetchUserSubscriptions={() => {
                                    refetchUserSubscriptions();
                                    fetchProjectStart();
                                }}
                                project={project}
                                key={index}
                            />
                        );
                    })
                ) : (
                    <div className="flex align-center mt-12 pt-12 px-4">
                        <p className="text-16 light-grey-color">{formatMessage(messages.noSubscriptionAvailable)}</p>
                    </div>
                )}
            </div>
        </SideModal>
    );
};

const mapStateToProps = (state) => ({
    subscribedProjects: selectSubscribedProject(state, PROJECT_SELECTOR_LIMITED),
});

const mapDispatchToProps = (dispatch) => ({
    fetchProjectStart: () => dispatch(fetchProjectStart()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withFormik({}))(WrappedProductSubscribedSideModal);
