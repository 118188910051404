import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import PrevButton from "components/PrevButton";
import { getPostidentCoupon, pdfLinkToken } from "api";
import { getDocumentsHost } from "common/helper";
import { selectUserDashboardDetails } from "store/user/account/selectors";
import { selectPlatformSourceFromApp } from "store/app/selectors";
import IntlFormattedMessage from "components/IntlFormattedMessage";

const messages = {
    content: "identify_yourself",
    stepFirstTitle: "download_print",
    stepFirstContent: "download_print_this_pdf_file",
    stepFirstButtonText: "download_pdf",
    stepTwoTitle: "identify_in_the_branch",
    stepTwoContent: "visit_post_office",
    stepTwoButtonText: "complete_identification",
};

const DOCUMENT_HOST = getDocumentsHost();

const PostidentPdfInstructions = ({ className = "", onBack, onDownloadClick, open, isApp, backSlideEffect = "" }) => {
    useSideModalScrollToTop();

    const [isDownloaded, setDownloaded] = useState(false);
    const [LinkToken, setLinkToken] = useState("");

    useEffect(() => {
        (async function getLinkFunc() {
            const response = await pdfLinkToken();
            setLinkToken(response?.data?.data?.pdfLinkToken?.Token ?? "");
        })();
    }, []);

    const { formatMessage } = useFormattedMessage();

    const getLink = () => {
        return `${DOCUMENT_HOST}/download/postident?auth=${LinkToken}`;
    };

    function openPDF() {
        return new Promise((resolve, reject) => {
            getPostidentCoupon(getLink())
                .then((response) => response.arrayBuffer())
                .then((arrayBuffer) => {
                    var file = new Blob([arrayBuffer], { type: "application/pdf" });
                    var fileURL = URL.createObjectURL(file);
                    setDownloaded(true);
                    window.open(fileURL);
                })
                .catch(() => reject());
        });
    }

    return (
        <>
            <div className={`postident-container mt-6 ${className} ${backSlideEffect}`}>
                <img className="mb-8" src={"/images/postident.png"} alt="" />
                <p className="pt-2 pb-7">{formatMessage(messages.content)}</p>
                <div className="postident-block download-print">
                    <div className="flex align-center">
                        <span className="step-num">1</span>
                        <span className="text-14 fw-600">{formatMessage(messages.stepFirstTitle)}</span>
                    </div>
                    <div className="flex justify-center align-center image-container">
                        <img src={"/images/pdf.png"} alt="" />
                        <div className="border-x-dotted" />
                        <img src={"/images/drucker.png"} alt="" />
                    </div>
                    <p>{formatMessage(messages.stepFirstContent)}</p>
                    {isApp ? (
                        <button
                            className=""
                            onClick={() => {
                                setDownloaded(true);
                                window?.ReactNativeWebView?.postMessage(JSON.stringify({ postidentLink: getLink() }));
                            }}
                        >
                            {formatMessage(messages.stepFirstButtonText)}
                        </button>
                    ) : (
                        <button
                            className=""
                            onClick={() => {
                                openPDF();
                            }}
                        >
                            {formatMessage(messages.stepFirstButtonText)}
                        </button>
                    )}
                </div>
                <div className="postident-block identify-store">
                    <div className="flex align-center">
                        <span className="step-num">2</span>
                        <span className="text-14 fw-600">{formatMessage(messages.stepTwoTitle)}</span>
                    </div>
                    <div className="flex justify-center align-center image-container">
                        <img src={"/images/brief.png"} alt="" />
                        <div className="border-x-dotted" />
                        <img src={"/images/filiale.png"} alt="" />
                    </div>
                    <p>
                        <IntlFormattedMessage id={messages.stepTwoContent} />
                    </p>
                </div>

                <button
                    onClick={() => {
                        isDownloaded && onDownloadClick && onDownloadClick();
                    }}
                    className={`mw-100 mb-0 ${!isDownloaded && "disable-grey"}`}
                >
                    {formatMessage(messages.stepTwoButtonText)}
                </button>
            </div>
            {open && (
                <div className="bottom-bar-container">
                    <PrevButton className="ppd-back-button" onClick={onBack} />
                </div>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    userDashboardData: selectUserDashboardDetails,
    isApp: selectPlatformSourceFromApp,
});

// const mapDispatchToProps = (dispatch) => ({
//     userDashboardDetailsRequest: () => dispatch(userDashboardDetailsRequest()),
// });

export default connect(mapStateToProps)(PostidentPdfInstructions);
