import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { useFormattedMessage, useUtils } from "hooks";
import Link from "components/NavLink";

import Insta from "assets/images/svg/insta.svg";
import Facebook from "assets/images/svg/fb.svg";
import Linkedin from "assets/images/svg/linkedin.svg";
import Xing from "assets/images/svg/xing.svg";
import Youtube from "assets/images/svg/youtube.svg";
import Twitter from "assets/images/svg/twitter.svg";
import Finexity from "assets/images/svg/finexity.svg";
import MobileApp from "components/MobileApp";
import { GENERAL_CONSTANTS, TENANTS_LIST, WEB_FLOW_ROUTES } from "constants/index";
import { selectLocale } from "store/app/selectors";

const messages = {
    companyTitle: "company",
    companyAbout: "about_us",
    companyTeam: "our_management",
    companyContact: "contact",
    hiring: "we_are_hiring",
    pressTitle: "press_title",
    pressBlog: "press_blog",
    valuableKnowledge: "knowledge_base_link",
    valuableGlossary: "valuable_glossary",
    valuableFaq: "faq",
    socialMediaTitle: "follow_us",
    downloadAppTitle: "download_app",
    imprint: "imprint",
    dataProtection: "footer_privacy_policy",
    footerTnC: "scc_terms_conditions",
    footerBottomLine: "footer_bottom_line",
    footerInvestmentTitle: "investment_platform",
    footerPartner: "partner_program",
    footerSaas: "by_fnxt",
    footerAmbassador: "investment_ambassador",
    footerDiscoverTitle: "discover_title",
    clubdeal: "club_deal",
    footerWPC: "whistleblower_protection_act",
};

const Footer = ({ locale }) => {
    const { formatMessage } = useFormattedMessage();
    const { isTenantFinexity, tenant } = useUtils();
    const ssgAgbLinks = { en: tenant.URLSCCGDPR_EN, de: tenant.URLSCCGDPR_DE };

    const footerElements = [
        {
            title: formatMessage(messages.footerInvestmentTitle),
            subitems: [
                {
                    title: formatMessage(messages.clubdeal),
                    link: WEB_FLOW_ROUTES.CLUB_DEAL,
                },
                {
                    title: formatMessage(messages.footerPartner),
                    link: WEB_FLOW_ROUTES.PARTNER_PROGRAM,
                },
                // {
                //     title: formatMessage(messages.footerSaas),
                //     link: WEB_FLOW_ROUTES.PLATFORM_PROGRAM,
                //     isExternal: true,
                // },
                {
                    title: formatMessage(messages.footerAmbassador),
                    link: WEB_FLOW_ROUTES.AMBASSADOR_PROGRAM,
                },
            ],
        },
        {
            title: formatMessage(messages.footerDiscoverTitle),
            subitems: [
                {
                    title: formatMessage(messages.pressBlog),
                    link: WEB_FLOW_ROUTES.BLOG,
                },
                {
                    title: formatMessage(messages.valuableKnowledge),
                    link: WEB_FLOW_ROUTES.KNOWLEDGE_BASE,
                },
                {
                    title: formatMessage(messages.valuableFaq),
                    link: WEB_FLOW_ROUTES.FAQS,
                },
                {
                    title: formatMessage(messages.valuableGlossary),
                    link: WEB_FLOW_ROUTES.GLOSSARY,
                },
            ],
        },
        {
            title: formatMessage(messages.companyTitle),
            subitems: [
                {
                    title: formatMessage(messages.companyAbout),
                    link: WEB_FLOW_ROUTES.ABOUT,
                },
                {
                    title: formatMessage(messages.companyTeam),
                    link: WEB_FLOW_ROUTES.OUR_TEAM,
                },
                {
                    title: formatMessage(messages.hiring),
                    link: GENERAL_CONSTANTS.JOBS_URL,
                },
                {
                    title: formatMessage(messages.pressTitle),
                    link: WEB_FLOW_ROUTES.PRESS,
                },
                {
                    title: formatMessage(messages.companyContact),
                    link: WEB_FLOW_ROUTES.CONTACT,
                },
            ],
        },
    ];

    return (
        <footer id="mainFooter" className="footer broad-container article multi-asset-footer">
            <div className="container flex-row">
                {isTenantFinexity && (
                    <>
                        {footerElements.map((element) => {
                            return (
                                <div key={Math.random()} className="footer__item">
                                    <h4>{element.title}</h4>
                                    <ul className="footer__company flex column">
                                        {element.subitems.map((item, subIndex) => {
                                            return (
                                                <li key={subIndex} className="footer__link-item">
                                                    <Link className="new-link" href={item.link} isExternal={item?.isExternal}>
                                                        {item.title}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            );
                        })}
                        <div className="footer__item flex-wrap justify-between">
                            <div>
                                <h4>{formatMessage(messages.socialMediaTitle)}</h4>
                                <ul className="socials flex">
                                    <li className="social-item insta ml-0">
                                        <a href="https://www.instagram.com/finexity/" target="_blank" rel="noopener noreferrer">
                                            <Insta style={{ width: 45, height: 45 }} />
                                        </a>
                                    </li>
                                    <li className="social-item fb">
                                        <a href="https://www.facebook.com/Finexity/" target="_blank" rel="noopener noreferrer">
                                            <Facebook style={{ width: 45, height: 45 }} />
                                        </a>
                                    </li>
                                    <li className="social-item linkedin">
                                        <a
                                            href="https://www.linkedin.com/company/finexity/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Linkedin style={{ width: 45, height: 45 }} />
                                        </a>
                                    </li>
                                    <li className="social-item xing">
                                        <a
                                            href="https://www.xing.com/companies/finexityag"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Xing style={{ width: 45, height: 45 }} />
                                        </a>
                                    </li>
                                    <li className="social-item">
                                        <a
                                            href="https://www.youtube.com/channel/UCmSpBGXd7jx0472vPsojLoA"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Youtube />
                                        </a>
                                    </li>
                                    <li className="social-item twitter">
                                        <a href="https://twitter.com/_Finexity_" target="_blank" rel="noopener noreferrer">
                                            <Twitter style={{ width: 45, height: 45 }} />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <MobileApp title={formatMessage(messages.downloadAppTitle)} className="footer-app-buttons" />
                        </div>
                    </>
                )}
                {/* <div className="flex-row footer__bottom footer__bottom-disclaimer">
                    <span>{formatMessage(messages.disclaimerEffecta)}</span>
                </div> */}
                <div className={`flex-row footer__bottom align-center ${isTenantFinexity ? "" : "tenant__footer"}`}>
                    <div className="footer__item">
                        <div className="flex-row footer__bottom-links justify-end">
                            <p className="footer__bottom-link">
                                <Link
                                    className="new-link"
                                    href={isTenantFinexity ? `${WEB_FLOW_ROUTES.IMPRINT}` : tenant?.URLImprint}
                                >
                                    {formatMessage(messages.imprint)}
                                </Link>
                            </p>
                            <p className="footer__bottom-link">
                                <Link
                                    className="new-link"
                                    href={isTenantFinexity ? `${WEB_FLOW_ROUTES.PRIVACY_POLICY}` : tenant?.URLDataPrivacy}
                                >
                                    {formatMessage(messages.dataProtection)}
                                </Link>
                            </p>
                            <p className="footer__bottom-link">
                                <Link className="new-link" href={ssgAgbLinks[locale]}>
                                    {formatMessage(messages.footerTnC)}
                                </Link>
                            </p>
                            {!!(tenant?.WPALink && tenant?.WPALink.trim()) && (
                                <p className="footer__bottom-link">
                                    <Link className="new-link" href={tenant?.WPALink}>
                                        {formatMessage(messages.footerWPC)}
                                    </Link>
                                </p>
                            )}
                            {!!(tenant?.CodeOfConductUrl && tenant?.CodeOfConductUrl.trim()) && (
                                <p className="footer__bottom-link">
                                    <Link className="new-link" href={tenant?.CodeOfConductUrl}>
                                        {locale === "en" ? tenant?.CodeOfConduct_EN : tenant?.CodeOfConduct_DE}
                                    </Link>
                                </p>
                            )}
                        </div>
                        {tenant?.TechnicalName !== TENANTS_LIST.WATCHVEST && (
                            <p className="footer-bottom-line body-small text-right">
                                {`${tenant?.LegalName} ${formatMessage(messages.footerBottomLine)}`}
                            </p>
                        )}
                    </div>
                    <div className="footer__logo">
                        {isTenantFinexity ? (
                            <Link className="new-link logo" href="/">
                                <Finexity />
                            </Link>
                        ) : null}
                    </div>
                </div>
            </div>
        </footer>
    );
};

const mapStateToProps = createStructuredSelector({
    locale: selectLocale,
});

export default connect(mapStateToProps)(Footer);
