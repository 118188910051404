import React, { Fragment, useState, useEffect } from "react";
import KycModal from "components/KycModal";
import { useFormattedMessage } from "hooks";
import { REKYC_STATUS, ACCOUNT_HASH_VARIABLES } from "constants/index";
import { TILE_STATUS } from "constants/status";
import ArrowNext from "assets/images/svg/next.svg";

const messages = {
    kycTileTitle: "your_identification",
    kycTileContent: "trust_and_security",
    kycTileErrorContent: "error_identifying",
    kycTileSuccessContent: "identification_completed",
    kycTileInitiatedContent: "identification_started",
    kycTilePendingContent: "confirmation_by_email",
    kycTileShowReKYCPendingContent: "identification_data_outdated",
    kycTileInitiatedReKYCPendingContent: "change_registration_address",
    tileComingSoonDeactivateButtonText: "available_shortly",
    tileComingSoonPendingButtonText: "in_processing",
};

const WrappedKycModal = ({ StatusKYC, StatusReKYC, setKycModalFlag, kycModalFlag, onCloseButtonClick, addHash, removeHash }) => {
    const { formatMessage } = useFormattedMessage();
    const [activeKycStep, setActiveKycStep] = useState(false);
    const [kycAtStep1, setKycAtStep1] = useState(false);

    const kycMessages = {
        [TILE_STATUS.ERROR]: formatMessage(messages.kycTileErrorContent),
        [TILE_STATUS.SUCCESS]: formatMessage(messages.kycTileSuccessContent),
        [TILE_STATUS.INITIATED]: formatMessage(messages.kycTileInitiatedContent),
        [TILE_STATUS.PENDING]: formatMessage(messages.kycTilePendingContent),
        [`${TILE_STATUS.SHOW}_REKYC_${REKYC_STATUS.PENDING}`]: formatMessage(messages.kycTileShowReKYCPendingContent),
        [`${TILE_STATUS.INITIATED}_REKYC_${REKYC_STATUS.PENDING}`]: formatMessage(messages.kycTileInitiatedReKYCPendingContent),
    };

    const kycMessage = (status) => {
        const newStatus =
            status === TILE_STATUS.SHOW && StatusReKYC === REKYC_STATUS.PENDING
                ? `${TILE_STATUS.SHOW}_REKYC_${REKYC_STATUS.PENDING}`
                : status === TILE_STATUS.INITIATED && StatusReKYC === REKYC_STATUS.PENDING
                ? `${TILE_STATUS.INITIATED}_REKYC_${REKYC_STATUS.PENDING}`
                : status;

        return kycMessages[newStatus] || formatMessage(messages.kycTileContent);
    };

    const comingSoonMessages = {
        [TILE_STATUS.DEACTIVATE]: formatMessage(messages.tileComingSoonDeactivateButtonText),
        [TILE_STATUS.PENDING]: formatMessage(messages.tileComingSoonPendingButtonText),
    };

    const statusClassName = {
        success: "tile-success",
        deactivate: "tile-deactive",
        initiated: "tile-initiate",
        pending: "tile-deactive",
        error: "tile-error",
    };

    const statusIcon = {
        success: "check",
        error: "times",
    };

    useEffect(() => {
        if (kycAtStep1) {
            setKycModalFlag(false);
            setKycAtStep1(false);
        }
    }, [kycAtStep1]);

    return (
        <Fragment>
            <KycModal
                open={kycModalFlag}
                onBackButtonClick={() => {
                    removeHash();
                    setKycModalFlag(false);
                }}
                onCloseButtonClick={onCloseButtonClick}
                activeStep={activeKycStep}
                setActiveStep={() => setActiveKycStep(false)}
                setKycAtStep1={() => setKycAtStep1(true)}
            />

            <div
                className={`identity-block identification-block border-radius pointer bg-primary-light ${
                    statusClassName[StatusKYC] || ""
                }`}
                onClick={() => {
                    setKycModalFlag(true);
                    addHash?.(ACCOUNT_HASH_VARIABLES.ACCOUNT_KYC);
                }}
            >
                {(StatusKYC === TILE_STATUS.SUCCESS || StatusKYC === TILE_STATUS.ERROR) && (
                    <div className="status-icon-container">
                        <div className="status-icon">
                            <i className={`fas fa-${statusIcon[StatusKYC] || ""}`} />
                        </div>
                    </div>
                )}

                <div className="d-flex justify-between slign-center">
                    <span className="body-medium">{formatMessage(messages.kycTileTitle)}</span>
                    <span className="next-arrow">
                        <ArrowNext />
                    </span>
                </div>
                <div className="description">
                    <div className="my-0 text-16 fw-600">{kycMessage(StatusKYC)}</div>
                </div>
                {StatusKYC !== TILE_STATUS.PENDING && comingSoonMessages[StatusKYC] && (
                    <button className="coming-soon-btn medium mb-0">{comingSoonMessages[StatusKYC]}</button>
                )}
            </div>
        </Fragment>
    );
};

export default WrappedKycModal;
