import { createInstance } from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "../../public/locales/en/translation.json";
import translationDE from "../../public/locales/de/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
    en: {
        translation: translationEN,
    },
    de: {
        translation: translationDE,
    },
};

const i18nInstance = createInstance({
    debug: false,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
});

i18nInstance.use(initReactI18next).use(LanguageDetector).init({});

export default i18nInstance;
