import React, { useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useFormattedMessage } from "hooks";

import SideModal from "components/SideModal";
import MessagesModal from "components/Inbox/Messages";
import MessageDetail from "components/Inbox/MessageDetail";

import { selectMessage } from "store/user/message/selectors";

const defineText = {
    tabInbox: "inbox",
};

export const RenderInboxStep = ({
    step,
    messages,
    message,
    setStep,
    setMessage,
    setAdequacyModalFlag,
    setKycModalFlag,
    setMobilePhoneModalFlag,
    offset,
    setOffset,
    open,
    backSlideEffect,
    setBackSlideEffect,
    isStepChanged,
}) => {
    function renderStep(step) {
        switch (step) {
            case 1:
                return (
                    <MessagesModal
                        open={open}
                        isStepChanged={isStepChanged}
                        messages={messages}
                        setMessage={setMessage}
                        step={step}
                        setStep={setStep}
                        setAdequacyModalFlag={setAdequacyModalFlag}
                        setKycModalFlag={setKycModalFlag}
                        setMobilePhoneModalFlag={setMobilePhoneModalFlag}
                        offset={offset}
                        setOffset={setOffset}
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                    />
                );
            case 2:
                return (
                    <MessageDetail
                        message={message}
                        setStep={setStep}
                        step={step}
                        messages={messages}
                        backSlideEffect={backSlideEffect}
                    />
                );
            default:
                return <MessagesModal />;
        }
    }
    return renderStep(step);
};

const InboxModal = ({
    open,
    onCloseButtonClick,
    messages,
    onBackButtonClick,
    setAdequacyModalFlag,
    setKycModalFlag,
    setMobilePhoneModalFlag,
}) => {
    const { formatMessage } = useFormattedMessage();
    const [step, setStep] = useState(1);
    const [message, setMessage] = useState({});
    const [offset, setOffset] = useState(1);
    const [backSlideEffect, setBackSlideEffect] = useState("");
    const [isStepChanged, setStepChanged] = useState(false);

    useEffect(() => {
        const escFunction = (event) => {
            if (event.key === "Escape") {
                onCloseButtonClick();
            }
        };
        window.addEventListener("keydown", escFunction);
        return () => window.removeEventListener("keydown", escFunction, false);
    }, []);

    useEffect(() => {
        jQuery(".side-modal").scrollTop(0);
        setStepChanged(true);
    }, [step]);

    useEffect(() => {
        !open && setStepChanged(false);
    }, [open]);

    const memoizedRenderInboxStep = useMemo(() => {
        return (
            <RenderInboxStep
                messages={messages}
                step={step}
                setStep={setStep}
                message={message}
                setMessage={setMessage}
                setAdequacyModalFlag={setAdequacyModalFlag}
                setKycModalFlag={setKycModalFlag}
                setMobilePhoneModalFlag={setMobilePhoneModalFlag}
                offset={offset}
                setOffset={setOffset}
                open={open}
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                isStepChanged={isStepChanged}
            />
        );
    }, [step, messages, message, open]);

    return (
        <SideModal
            className="notification-modal"
            heading={formatMessage(defineText.tabInbox)}
            open={open}
            isBackArrowOnTop
            onBackButtonClick={() => {
                if (step == 1) {
                    onBackButtonClick();
                    setOffset(1);
                } else {
                    setBackSlideEffect("step-slide-right");
                    setStep(step - 1);
                }
            }}
            onCloseButtonClick={() => {
                onCloseButtonClick();
                setStep(1);
            }}
        >
            {memoizedRenderInboxStep}
        </SideModal>
    );
};

const mapStateToProps = createStructuredSelector({
    messages: selectMessage,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InboxModal);
