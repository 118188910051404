import AccountActionTypes from "./types";
import InvestmentActionTypes from "../investment/types";
import { PHONE_TYPES } from "constants/index";

const initialState = {
    userName: "",
    password: "",
    isKycLoading: false,
    isLoggedIn: false,
    accountType: "",
    authToken: null,
    email: "",
    loading: false,
    isUserLoading: false,
    isLoadingTiles: true,
    error: null,
    dashboard: {},
    dashboardData: { StatusAdequacy: "hide", StatusKYC: "show", StatusMobilePhone: "hide" },
    additionalData: {
        personal: {},
        identification: {},
        tile: {},
        noNeedToBack: false,
        mobile: {
            step: 1,
            existData: false,
        },
        brokerOnboardingProcess: {
            isTipsterPdfDownloaded: false,
        },
        canMakeCall: false,
    },
    walletBalance: {},
    payments: [],
    userData: {
        subscriptions: [],
    },
    updateUserTaxDataTransitions: {
        isUpdating: false,
        isSuccessful: false,
        isFailed: false,
    },
    isSubscriptionsLoading: false,
};

const accountReducer = (state = initialState, { type, payload = {} }) => {
    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (type) {
        case AccountActionTypes.UPDATE_USER_DATA:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    ...payload,
                },
            };
        case AccountActionTypes.UPDATE_USER_TAX_DATA_TRANSITIONS:
            return {
                ...state,
                updateUserTaxDataTransitions: {
                    ...state.updateUserTaxDataTransitions,
                    ...payload,
                },
            };
        case AccountActionTypes.UPDATE_CAMPAGIN_SUCCESS:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    Campaign: [...state.userData.Campaign, ...payload],
                },
            };
        case AccountActionTypes.SET_CAN_MAKE_CALL:
            return {
                ...state,
                additionalData: {
                    ...state.additionalData,
                    canMakeCall: payload,
                },
            };
        case AccountActionTypes.UPDATE_USER_DETAIL_FIELD:
            const len = payload.route.length;
            let dataHolder = state.userData;
            for (let i = 0; i < len; i++) {
                if (i === len - 1) {
                    dataHolder[payload.route[i]] = payload.value;
                }
                dataHolder = dataHolder[payload.route[i]];
            }
            return Object.assign({}, state);
        case AccountActionTypes.SET_TIPSTER_PDF_DOWNLOADED:
            return {
                ...state,
                additionalData: {
                    ...state.additionalData,
                    brokerOnboardingProcess: {
                        ...state.additionalData.brokerOnboardingProcess,
                        isTipsterPdfDownloaded: payload,
                    },
                },
            };
        case AccountActionTypes.SET_ONBOARDING_PROCESS_STEP_DATA:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    Broker: {
                        ...state.userData.Broker,
                        LinkCompany: {
                            ...state.userData.Broker.LinkCompany,
                            CompanyName: payload.name,
                        },
                        LinkAddress: {
                            ...state.userData.Broker.LinkAddress,
                            Zipcode: payload.zipcode,
                            Streetnumber: payload.streetnumber,
                            Country: payload.country,
                            Street: payload.street,
                            City: payload.city,
                        },
                        LinkBankAccount: {
                            IBAN: payload.IBAN,
                            BIC: payload.BIC,
                            Owner: payload.owner,
                        },
                        TaxID: payload.taxID,
                        StatusBrokerOnboarding: payload.status,
                    },
                },
            };
        case AccountActionTypes.SET_ONBOARDING_PROCESS_STATUS:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    Broker: {
                        ...state.userData.Broker,
                        StatusBrokerOnboarding: payload,
                    },
                },
            };
        case AccountActionTypes.SET_USER_REGISTRATION_ON_BOARDING_STATUS:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    UserRegistrationOnboardingStatus: payload,
                },
            };
        case InvestmentActionTypes.SET_INVESTMENT_STEP_DATA:
            return {
                ...state,
                additionalData: {
                    ...state.additionalData,
                    personal: {
                        ...state.additionalData.personal,
                        ...(payload && payload.userData ? payload.userData.personalData : {}),
                    },
                    identification: {
                        ...state.additionalData.identification,
                        ...(payload && payload.userData ? payload.userData.identificationData : {}),
                    },
                    tile: {
                        ...state.additionalData.tile,
                        ...(payload && payload.userData ? payload.userData.tileData : {}),
                    },
                    noNeedToBack:
                        payload && payload.userData && typeof payload.userData.noNeedToBack !== "undefined"
                            ? payload.userData.noNeedToBack
                            : !!state.additionalData.noNeedToBack,
                    IDnowRedirectURL: payload?.userData?.IDnowRedirectURL || state.additionalData?.IDnowRedirectURL,
                },
            };
        case AccountActionTypes.SET_USER_FIELD_VALUE:
            return {
                ...state,
                [payload.key]: payload.value,
            };
        case AccountActionTypes.SET_AUTH_TOKEN_DATA:
            return {
                ...state,
                authToken: payload,
                isLoggedIn: !!payload,
            };
        case AccountActionTypes.LOGOUT:
            return {
                ...initialState,
            };
        case AccountActionTypes.USER_DASHBOARD_DETAILS_START:
        case AccountActionTypes.FETCH_PAY_IN_WALLET_BALANCE_START:
        case AccountActionTypes.FETCH_BLOCKED_WALLET_BALANCE_START:
        case AccountActionTypes.FETCH_PAYMENTS_FOR_USER_START:
        case AccountActionTypes.USER_MIN_PAYIN_AMOUNT_START:
            return {
                ...state,
                isLoading: true,
            };
        case AccountActionTypes.USER_DASHBOARD_DETAILS_SUCCESS:
            return {
                ...state,
                dashboardData: { ...state.dashboardData, ...payload },
                isLoading: false,
                isLoadingTiles: false,
            };
        case AccountActionTypes.USER_DASHBOARD_DETAILS_FAILURE:
            return {
                ...state,
                error: payload,
                isLoading: false,
                isLoadingTiles: false,
            };
        case AccountActionTypes.FETCH_ALL_USER_DETAILS_START:
        case AccountActionTypes.FETCH_USER_DETAILS_START:
            return {
                ...state,
                isUserLoading: true,
            };
        case AccountActionTypes.FETCH_ALL_USER_DETAILS_SUCCESS:
        case AccountActionTypes.FETCH_ALL_USER_DETAILS_FAILURE:
            return {
                ...state,
                isUserLoading: false,
            };
        case AccountActionTypes.FETCH_USER_DETAILS_SUCCESS:
            return {
                ...state,
                userData: { ...payload },
                isUserLoading: false,
                isUserFetched: true,
            };
        case AccountActionTypes.FETCH_USER_TERMS_AND_CONDITIONS_SUCCESS:
            return {
                ...state,
                termsAndConditions: payload,
            };
        case AccountActionTypes.FETCH_USER_DETAILS_FAILURE:
            return {
                ...state,
                error: typeof payload == "object" ? undefined : payload,
                isUserLoading: false,
            };
        case AccountActionTypes.SET_USER_DETAILS_START:
            return {
                ...state,
                isKycLoading: true,
                userDetails: { ...payload },
            };
        case AccountActionTypes.SET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                isKycLoading: false,
                additionalData: { ...state.additionalData, noNeedToBack: true },
            };
        case AccountActionTypes.SET_USER_DETAILS_FAILURE:
            return {
                ...state,
                error: payload,
                isKycLoading: false,
            };
        case AccountActionTypes.SET_USER_MOBILE_DATA:
            return {
                ...state,
                additionalData: {
                    ...state.additionalData,
                    mobile: {
                        ...state.additionalData.mobile,
                        ...(payload ? payload : {}),
                    },
                },
            };
        case AccountActionTypes.SET_USER_MOBILE_DETAILS_START:
            return {
                ...state,
                isLoading: true,
                mobileDetails: {
                    ...payload.mobileDetails,
                },
            };

        case AccountActionTypes.SET_USER_MOBILE_DETAILS_SUCCESS:
            return {
                ...state,
                additionalData: {
                    ...state.additionalData,
                    mobile: {
                        ...state.additionalData.mobile,
                        ...(payload.mobileDetails ? payload.mobileDetails : {}),
                    },
                },
                isLoading: false,
            };

        case AccountActionTypes.SET_USER_MOBILE_DETAILS_FAILURE:
        case AccountActionTypes.FETCH_PAY_IN_WALLET_BALANCE_FAILURE:
        case AccountActionTypes.FETCH_BLOCKED_WALLET_BALANCE_FAILURE:
        case AccountActionTypes.FETCH_PAYMENTS_FOR_USER_FAILURE:
        case AccountActionTypes.USER_MIN_PAYIN_AMOUNT_FAILURE:
            return {
                ...state,
                error: payload,
                isLoading: false,
            };
        case AccountActionTypes.FETCH_PAY_IN_WALLET_BALANCE_SUCCESS:
            return {
                ...state,
                walletBalance: {
                    ...state.walletBalance,
                    payInWallet: { ...payload },
                },
                isLoading: false,
            };

        case AccountActionTypes.FETCH_BLOCKED_WALLET_BALANCE_SUCCESS:
            return {
                ...state,
                walletBalance: {
                    ...state.walletBalance,
                    blockedWallet: { ...payload },
                },
                isLoading: false,
            };

        case AccountActionTypes.FETCH_PAYMENTS_FOR_USER_SUCCESS:
            return { ...state, payments: payload, isLoading: false };

        case AccountActionTypes.USER_MIN_PAYIN_AMOUNT_SUCCESS:
            return {
                ...state,
                payInAmount: payload,
                isLoading: false,
            };
        case AccountActionTypes.SET_TIPSTER_DATA:
            return {
                ...state,
                additionalData: {
                    ...state.additionalData,
                    tile: {
                        ...state.additionalData.tile,
                        tipster: {
                            ...state.userData.tipster,
                            uid: payload.ID,
                        },
                    },
                },
            };
        case AccountActionTypes.UPDATE_PRIMARY_PHONE_STATUS:
            const Phone = state.userData && state.userData.Phone;

            for (const i in Phone) {
                if (Phone[i].TypePhone === PHONE_TYPES.TypePhoneMobile) {
                    Phone[i].StatusConfirmed = payload;
                    break;
                }
            }

            return {
                ...state,
                userData: {
                    ...state.userData,
                    Phone,
                },
            };

        case AccountActionTypes.UPDATE_USER_MANGOPAY_KYC_LEVEL:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    Mangopay: {
                        ...state.userData.Mangopay,
                        KYCLevel: payload,
                    },
                },
            };

        case AccountActionTypes.FETCH_USER_SUBSCRIPTIONS_START:
            return {
                ...state,
                isSubscriptionsLoading: true,
            };
        case AccountActionTypes.FETCH_USER_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    subscriptions: payload,
                },
                isSubscriptionsLoading: false,
            };

        case AccountActionTypes.FETCH_USER_SUBSCRIPTIONS_FAILURE:
            return {
                ...state,
                error: payload,
                isSubscriptionsLoading: false,
            };
        default:
            return state;
    }
};

export default accountReducer;
