import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { selectUserMobileDetails, selectEmail, selectTypeMobilePhonelDetails } from "store/user/account/selectors";
import cn from "classnames";
import Loader from "components/Loader";
import PrevButton from "components/PrevButton";
import IntlFormattedMessage from "components/IntlFormattedMessage";
import { setUserMobileDetails } from "api";
import { PHONE_TYPES } from "constants/index";
import OTPInput from "components/OTPInput";

const messages = {
    requiredField: "required_field",
    mobileTANFormInputMobileCode: "enter_code",
    mobileTANFormMobileCodeValidationValid: "invalid_mobile_code",
    mobileSomethingWrong: "mobile_tan_something_wrong",
    mobileTANcodeExpired: "mobile_tan_code_expired",
    heading: "mobile_number_confirmation",
    content1: "sent_security_code_to_your_number",
    content2: "mobile_code_content2",
    inputLabel: "enter_code_here",
    requestCodeAgain: "request_code_again",
    invalidContent: "check_specified_mobile_number",
    expiredContent: "code_entered_expired",
    sendAgain: "resend_otp_code",
    changeNumber: "change_mobile_number",
    buttonText: "confirm_code",
    buttonprocessingText: "code_is_required",
    userBlocked: "user_blocked",
};

const errorsMessagesMapping = {
    invalid_input: <IntlFormattedMessage id={messages.mobileTANFormMobileCodeValidationValid} />,
    TAN_expired: <IntlFormattedMessage id={messages.mobileTANcodeExpired} />,
};

const MobileCode = ({ className = "", changeNumber, heading, mobile = {}, formSubmitSuccess, backSlideEffect = "" }) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();
    const [isOTPLoading, setOTPLoading] = useState(false);
    const [errorCode, setErrorCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isSubmitting, setSubmitting] = useState(false);
    const [OTP, setOTP] = useState("");
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        setErrorMessage("");
        setErrorCode("");
    }, [OTP]);

    const resendOTP = () => {
        try {
            setOTPLoading(true);
            setUserMobileDetails({
                TAN: "",
                Number: mobile.Number,
                DialCode: mobile.DialCode,
                CountryCode: mobile.CountryCode,
                TypePhone: PHONE_TYPES.TypePhoneMobile,
            })
                .then((res) => {
                    setOTPLoading(false);
                    setCounter(90);
                    if (res.status === 401 || res?.data?.errors?.[0]?.message === "user_blocked") {
                        setErrorMessage(formatMessage(messages.userBlocked));
                    } else if (res?.data?.errors) {
                        setErrorMessage(formatMessage(messages.mobileSomethingWrong));
                    }
                })
                .catch(() => {
                    setSubmitting(false);
                    setErrorMessage(formatMessage(messages.mobileSomethingWrong));
                });
        } catch (error) {
            setOTPLoading(false);
            setErrorMessage(formatMessage(messages.mobileSomethingWrong));
        }
    };

    const onSubmit = () => {
        try {
            setSubmitting(true);
            setUserMobileDetails({
                TAN: OTP,
                Number: mobile.Number,
                DialCode: mobile.DialCode,
                CountryCode: mobile.CountryCode,
                TypePhone: PHONE_TYPES.TypePhoneMobile,
            })
                .then((res) => {
                    setSubmitting(false);
                    if (res?.data?.errors) {
                        const error = res.data.errors[0].message;

                        setErrorCode(error);
                        if (errorsMessagesMapping[error]) {
                            setErrorMessage(errorsMessagesMapping[error]);
                        } else {
                            setErrorMessage(formatMessage(messages.mobileSomethingWrong));
                        }
                        return;
                    }
                    return formSubmitSuccess();
                })
                .catch(() => {
                    setSubmitting(false);
                    setErrorMessage(formatMessage(messages.mobileSomethingWrong));
                });
        } catch (error) {
            setSubmitting(false);
            setErrorMessage(formatMessage(messages.mobileSomethingWrong));
        }
    };

    useEffect(() => {
        if (counter > 0 && !isOTPLoading) {
            const interval = setInterval(() => {
                setCounter(counter - 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [isOTPLoading, counter]);

    return (
        <div className={`mobile-tan-container ${className} ${backSlideEffect}`}>
            <h3>{heading || formatMessage(messages.heading)}</h3>

            {errorCode === "invalid_input" ? (
                <p>{formatMessage(messages.invalidContent)}</p>
            ) : errorCode === "TAN_expired" ? (
                <p>{formatMessage(messages.expiredContent)}</p>
            ) : (
                <p>{`${formatMessage(messages.content1)} +${mobile.DialCode} ${mobile.Number}${formatMessage(
                    messages.content2
                )}`}</p>
            )}

            <label className="mb-2 d-inline-block">{formatMessage(messages.inputLabel)}</label>
            <OTPInput
                isShowAnimation={false}
                errorCode={errorCode}
                value={OTP}
                onChange={(value) => {
                    setErrorCode("");
                    setOTP(value);
                }}
                isSubmitting={isSubmitting}
                errorMessage={errorMessage}
                shouldAutoFocus
            />
            {!isSubmitting &&
                (isOTPLoading ? (
                    <Loader className="d-block ml-auto primary-color" />
                ) : (
                    <span
                        className={`change-action light-grey-color text-12 mb-5`}
                        onClick={counter === 0 ? resendOTP : () => {}}
                        disabled={counter !== 0}
                    >
                        {counter !== 0
                            ? `${formatMessage(messages.requestCodeAgainIn)} ${counter} sec`
                            : formatMessage(messages.requestCodeAgain)}
                    </span>
                ))}

            {!isSubmitting && errorMessage && <span className="error pl-0">{errorMessage}</span>}
            <button
                disabled={isSubmitting || OTP?.length !== 6 || isOTPLoading}
                className={cn({
                    "mb-0 mw-100 mt-12": true,
                    "disable-grey": isSubmitting || OTP?.length !== 6 || isOTPLoading,
                })}
                type="button"
                onClick={onSubmit}
            >
                {isSubmitting ? (
                    <>
                        <Loader /> {formatMessage(messages.buttonprocessingText)}
                    </>
                ) : (
                    formatMessage(messages.buttonText)
                )}
            </button>
            <div className="bottom-bar-container">
                <PrevButton className="id-back-button" onClick={changeNumber} />
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    userEmail: selectEmail,
    mobile: selectUserMobileDetails,
    modalData: selectTypeMobilePhonelDetails,
});

export default connect(mapStateToProps)(MobileCode);
