import { isClient } from "utils/index";
import AccountActionTypes from "./types";

export const updateUserTaxDataTransitions = (transitions) => ({
    type: AccountActionTypes.UPDATE_USER_TAX_DATA_TRANSITIONS,
    payload: transitions,
});

export const updateUserTaxDataStart = (taxData) => ({
    type: AccountActionTypes.UPDATE_USER_TAX_DATA_START,
    taxData,
});

export const updateUserData = (userData) => ({
    type: AccountActionTypes.UPDATE_USER_DATA,
    payload: userData,
});

export const setCanMakeCall = (payload) => ({
    type: AccountActionTypes.SET_CAN_MAKE_CALL,
    payload,
});

export const setOnboardingProcessStatus = (status) => ({
    type: AccountActionTypes.SET_ONBOARDING_PROCESS_STATUS,
    payload: status,
});

export const setUserRegistrationOnboardingStatus = (status) => ({
    type: AccountActionTypes.SET_USER_REGISTRATION_ON_BOARDING_STATUS,
    payload: status,
});

export const updateUserDataField = (route, value) => ({
    type: AccountActionTypes.UPDATE_USER_DETAIL_FIELD,
    payload: { route, value },
});

export const setTipsterPdfDownloaded = (isTipsterPdfDownloaded) => ({
    type: AccountActionTypes.SET_TIPSTER_PDF_DOWNLOADED,
    payload: isTipsterPdfDownloaded,
});

export const setOnboardingProcessData = (data) => ({
    type: AccountActionTypes.SET_ONBOARDING_PROCESS_STEP_DATA,
    payload: data,
});

export const setUserDetailsStart = (userDetails) => ({
    type: AccountActionTypes.SET_USER_DETAILS_START,
    payload: userDetails,
});
export const setUserDetailsSuccess = () => ({
    type: AccountActionTypes.SET_USER_DETAILS_SUCCESS,
});
export const setUserDetailsFailure = (error) => ({
    type: AccountActionTypes.SET_USER_DETAILS_FAILURE,
    payload: error,
});

export const setUserSubscriptionSuccess = () => ({
    type: AccountActionTypes.SET_USER_SUBSCRIPTION_SUCCESS,
});

export const setTipsterData = (payload) => ({
    type: AccountActionTypes.SET_TIPSTER_DATA,
    payload,
});

export const fetchUserAllDetailsStart = () => ({
    type: AccountActionTypes.FETCH_ALL_USER_DETAILS_START,
});

export const fetchUserAllDetailsSuccess = () => ({
    type: AccountActionTypes.FETCH_ALL_USER_DETAILS_SUCCESS,
});
export const fetchUserAllDetailsFailure = () => ({
    type: AccountActionTypes.FETCH_ALL_USER_DETAILS_FAILURE,
});

export const fetchUserDetailsStart = () => ({
    type: AccountActionTypes.FETCH_USER_DETAILS_START,
});
export const fetchUserDetailsSuccess = (userData) => ({
    type: AccountActionTypes.FETCH_USER_DETAILS_SUCCESS,
    payload: userData,
});
export const fetchUserDetailsFailure = (error) => ({
    type: AccountActionTypes.FETCH_USER_DETAILS_FAILURE,
    payload: error,
});

export const setUserMobileData = (payload) => ({
    type: AccountActionTypes.SET_USER_MOBILE_DATA,
    payload,
});

export const setUserMobileDetailsStart = (mobileDetails) => ({
    type: AccountActionTypes.SET_USER_MOBILE_DETAILS_START,
    payload: mobileDetails,
});
export const setUserMobileDetailsSuccess = (mobileDetails) => ({
    type: AccountActionTypes.SET_USER_MOBILE_DETAILS_SUCCESS,
    payload: mobileDetails,
});
export const setUserMobileDetailsFailure = (error) => ({
    type: AccountActionTypes.SET_USER_MOBILE_DETAILS_FAILURE,
    payload: error,
});

export const storeAuthTokenData = (accessToken) => {
    if (isClient()) {
        const FINX_COOKIE = require("common/constants");
        const cookie = require("js-cookie");

        const inheritUserToken = cookie.get(FINX_COOKIE.INHERIT_USER_TOKEN);
        if (!inheritUserToken) {
            localStorage.setItem(FINX_COOKIE.FINX_SESSION, accessToken);
        }
    }

    return {
        type: AccountActionTypes.SET_AUTH_TOKEN_DATA,
        payload: accessToken,
    };
};

export const logout = () => {
    clearAllAuthCookie();
    return {
        type: AccountActionTypes.LOGOUT,
    };
};

export const userDashboardDetailsStart = () => ({
    type: AccountActionTypes.USER_DASHBOARD_DETAILS_START,
});

export const userDashboardDetailsSuccess = (dashboardData) => ({
    type: AccountActionTypes.USER_DASHBOARD_DETAILS_SUCCESS,
    payload: dashboardData,
});
export const userDashboardDetailsFailure = (error) => ({
    type: AccountActionTypes.USER_DASHBOARD_DETAILS_FAILURE,
    payload: error,
});

export function fetchPayInWalletBalanceStart(payload) {
    return {
        type: AccountActionTypes.FETCH_PAY_IN_WALLET_BALANCE_START,
        payload,
    };
}
export function fetchPayInWalletBalanceSuccess(walletBalance) {
    return {
        type: AccountActionTypes.FETCH_PAY_IN_WALLET_BALANCE_SUCCESS,
        payload: walletBalance,
    };
}
export function fetchPayInWalletBalanceFailure(error) {
    return {
        type: AccountActionTypes.FETCH_PAY_IN_WALLET_BALANCE_FAILURE,
        payload: error,
    };
}

export function fetchBlockedWalletBalanceStart(payload) {
    return {
        type: AccountActionTypes.FETCH_BLOCKED_WALLET_BALANCE_START,
        payload,
    };
}
export function fetchBlockedWalletBalanceSuccess(walletBalance) {
    return {
        type: AccountActionTypes.FETCH_BLOCKED_WALLET_BALANCE_SUCCESS,
        payload: walletBalance,
    };
}
export function fetchBlockedWalletBalanceFailure(error) {
    return {
        type: AccountActionTypes.FETCH_BLOCKED_WALLET_BALANCE_FAILURE,
        payload: error,
    };
}

export function fetchUserPaymentsStart(userID) {
    return {
        type: AccountActionTypes.FETCH_PAYMENTS_FOR_USER_START,
        payload: userID,
    };
}
export function fetchUserPaymentsSuccess(payments) {
    return {
        type: AccountActionTypes.FETCH_PAYMENTS_FOR_USER_SUCCESS,
        payload: payments,
    };
}
export function fetchUserPaymentsFailure(error) {
    return {
        type: AccountActionTypes.FETCH_PAYMENTS_FOR_USER_FAILURE,
        payload: error,
    };
}

export function fetchUserMinPayInAmountStart() {
    return {
        type: AccountActionTypes.USER_MIN_PAYIN_AMOUNT_START,
    };
}
export function fetchUserMinPayInAmountSuccess(minPayInAmount) {
    return {
        type: AccountActionTypes.USER_MIN_PAYIN_AMOUNT_SUCCESS,
        payload: minPayInAmount,
    };
}
export function fetchUserMinPayInAmountFailure(error) {
    return {
        type: AccountActionTypes.USER_MIN_PAYIN_AMOUNT_FAILURE,
        payload: error,
    };
}

// Sets a field value
export const setUserFieldValue = (key, value) => ({
    type: AccountActionTypes.SET_USER_FIELD_VALUE,
    payload: { key, value },
});

export const updateUserMangopayKYCLevel = (value) => ({
    type: AccountActionTypes.UPDATE_USER_MANGOPAY_KYC_LEVEL,
    payload: value,
});

export const clearAllAuthCookie = () => {
    if (isClient()) {
        const FINX_COOKIE = require("common/constants");
        const cookie = require("js-cookie");

        localStorage.removeItem(FINX_COOKIE.FINX_ACCESS_TOKEN);
        localStorage.removeItem(FINX_COOKIE.FINX_SESSION);

        cookie.remove(FINX_COOKIE.FINX_SESSION);
        cookie.remove(FINX_COOKIE.FINX_SESSION, { domain: ".finexity.com" });

        cookie.remove(FINX_COOKIE.USER_EMAIL);
        cookie.remove(FINX_COOKIE.USER_ROLE);
        cookie.remove(FINX_COOKIE.PLATFORM_SOURCE);
        cookie.remove(FINX_COOKIE.SOURCE_END);
        cookie.remove(FINX_COOKIE.CLUB_DEAL_STATUS);
    }
};

export const exitInheritUser = () => {
    const FINX_COOKIE = require("common/constants");
    const Cookies = require("js-cookie");

    Cookies.remove(FINX_COOKIE.INHERIT_USER_TOKEN);
    Cookies.remove(FINX_COOKIE.INHERIT_USER_EMAIL);

    window.location = "/dashboard";
};

export const fetchUserTermsAndConditionSuccess = (termsAndConditions) => ({
    type: AccountActionTypes.FETCH_USER_TERMS_AND_CONDITIONS_SUCCESS,
    payload: termsAndConditions,
});

export const fetchUserSubscriptionsStart = (userID, callback = () => {}) => ({
    type: AccountActionTypes.FETCH_USER_SUBSCRIPTIONS_START,
    userID,
    callback,
});
export const fetchUserSubscriptionsSuccess = (data) => ({
    type: AccountActionTypes.FETCH_USER_SUBSCRIPTIONS_SUCCESS,
    payload: data,
});
export const fetchUserSubscriptionsFailure = (error) => ({
    type: AccountActionTypes.FETCH_USER_SUBSCRIPTIONS_FAILURE,
    payload: error,
});
