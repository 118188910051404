import React, { useState, useEffect } from "react";
import { compose } from "recompose";
import { withFormik } from "formik";
import * as yup from "yup";
import Checkbox from "components/Checkbox";
import TopGreyDiv from "../components/topGreyDiv";
import PrevButton from "components/PrevButton";
import NextButton from "components/NextButton";
import Input from "components/Input";
import cn from "classnames";
import Bank from "assets/images/svg/bank.svg";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { sendFormError } from "i18n/messages/errors";
import { ibanCheck, addBankAccount } from "api";
import { isValidBIC, isValidIBAN, friendlyFormatIBAN, electronicFormatIBAN } from "ibantools";

const messages = {
    addNewBankAccount: "add_new_bank_account",
    addNewAccount: "add_new_account",
    addBankAccountContent: "add_bank_content",
    accountOwnerPlaceholder: "enter_account_owner",
    ibanPlaceholder: "enter_idan",
    bicPlaceholder: "is_determined_automatically",
    accountOwnerLabel: "account_owner",
    ibanRequired: "iban_required",
    ibanInvalid: "iban_Invalid",
    bicRequired: "bic_required",
    bicInvalid: "bic_invalid",
    ownerRequired: "owner_required",
    setAsSelectedBankAccountPayout: "set_payout_selected_bank_account",
    addThisAccount: "add_this_account",
    bankAccountAlreadyExist: "bank_account_already_exist",
};

const AddBankAccount = ({
    setShowDate,
    setCloseTitle,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
    submitForm,
    validateForm,
    setFieldValue,
    setStep,
    status,
    backSlideEffect = "",
    setBackSlideEffect,
}) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();
    const [requestError, setRequestError] = useState(false);

    useEffect(() => {
        setShowDate(false);
        setCloseTitle(formatMessage(messages.addNewAccount));
    }, []);

    useEffect(() => {
        validateForm();
    }, [values, touched, errors]);

    useEffect(() => {
        if (values && open && values.Iban && values.Iban && electronicFormatIBAN(values.Iban.trim()).length >= 17) {
            getBIC();
        }
    }, [values.Iban, errors.Iban, open]);

    const getBIC = async () => {
        // setFieldValue('BIC', '');
        const res = await ibanCheck(electronicFormatIBAN(values.Iban.trim()));
        const Bic = res && res.data && res.data.data && res.data.data.ibanCheck && res.data.data.ibanCheck.bic;
        Bic && Bic !== values.Bic && setFieldValue("Bic", Bic);
    };

    useEffect(() => {
        if (status && status.requestError) {
            setRequestError(
                formatMessage(
                    status.requestError === "bank_account_already_exist" ? messages.bankAccountAlreadyExist : sendFormError
                )
            );
        }
    }, [status]);

    return (
        <div className={`pay-out ${backSlideEffect}`}>
            <TopGreyDiv icon svgIcon={<Bank width="65" height="65" />} />
            <div className="py-10 px-5 mw-450 mx-auto">
                <h3 className="mt-0">{formatMessage(messages.addNewBankAccount)}</h3>
                <p>{formatMessage(messages.addBankAccountContent)}</p>
                <Input
                    readOnly
                    label={formatMessage(messages.accountOwnerLabel)}
                    className="mb-8 mt-10"
                    inputClassName="bg-lighter-grey"
                    type="text"
                    name="Owner"
                    id="owner"
                    placeholder={formatMessage(messages.accountOwnerPlaceholder)}
                    value={values.Owner}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={errors.Owner ? formatMessage(messages[errors.Owner]) : null}
                    error={touched.Owner && errors.Owner}
                />
                <Input
                    className="mb-8"
                    label="IBAN"
                    type="text"
                    id="iban"
                    name="Iban"
                    inputClassName="pd-iban-input"
                    placeholder={formatMessage(messages.ibanPlaceholder)}
                    onChange={(value) => setFieldValue("Iban", friendlyFormatIBAN(value.target.value))}
                    value={values.Iban}
                    onBlur={handleBlur}
                    errorMessage={errors.Iban ? formatMessage(messages[errors.Iban]) : null}
                    error={touched.Iban && errors.Iban}
                />
                <Input
                    className="mb-10"
                    label="BIC"
                    type="text"
                    name="Bic"
                    id="bic"
                    inputClassName="pd-bic-input"
                    placeholder={formatMessage(messages.bicPlaceholder)}
                    onChange={handleChange}
                    value={values.Bic}
                    onBlur={handleBlur}
                    errorMessage={errors.Bic ? formatMessage(messages[errors.Bic]) : null}
                    error={touched.Bic && errors.Bic}
                />
                <Checkbox
                    inputId={formatMessage(messages.setAsSelectedBankAccountPayout)}
                    name="preferredBankAccount"
                    value={values.preferredBankAccount}
                    label={formatMessage(messages.setAsSelectedBankAccountPayout)}
                    onChange={() => {
                        setFieldValue("preferredBankAccount", !values.preferredBankAccount);
                    }}
                />
                {requestError && <span className="error pl-0">{requestError}</span>}
            </div>
            <div className="bottom-bar-container mt-25">
                <PrevButton
                    onClick={() => {
                        setStep("allBankAccount");
                        setBackSlideEffect("step-slide-right");
                    }}
                    disable={isSubmitting}
                />
                <NextButton
                    className={cn({
                        "disable-grey": isSubmitting || Object.keys(errors).length,
                        "mw-fit": true,
                    })}
                    disable={isSubmitting || Object.keys(errors).length}
                    onClick={submitForm}
                    title={formatMessage(messages.addThisAccount)}
                    isLoading={isSubmitting}
                />
            </div>
        </div>
    );
};

export default compose(
    withFormik({
        mapPropsToValues: (props) => {
            return {
                Owner: `${props.data.currentUser.firstName} ${props.data.currentUser.lastName}` || "",
                Iban: "",
                Bic: "",
                preferredBankAccount: false,
            };
        },
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: (props) =>
            yup.object().shape({
                Bic: yup
                    .string()
                    .test("Bic", "bicInvalid", (value) => {
                        return !!(value && isValidBIC(value.replace(/\s/g, "")));
                    })
                    .required("bicRequired")
                    .nullable(true),
                Owner: yup.string().required("ownerRequired").nullable(true),
                Iban: yup
                    .string()
                    .trim()
                    .test("Iban", "ibanInvalid", (value) => {
                        return !!(value && isValidIBAN(electronicFormatIBAN(value)));
                    })
                    .test("Iban", "bankAccountAlreadyExist", (value) => {
                        const allBankAccounts = props && props.data && props.data.allBankAccounts;
                        const accountIndex =
                            allBankAccounts &&
                            Array.isArray(allBankAccounts) &&
                            allBankAccounts.length &&
                            allBankAccounts.findIndex(
                                (x) =>
                                    x.IBAN.toLowerCase().replace(/\s/g, "") === (value && value.toLowerCase().replace(/\s/g, ""))
                            );
                        return accountIndex <= -1;
                    })
                    .required("ibanRequired")
                    .nullable(true),
            }),
        handleSubmit: (values, { props, setSubmitting, setStatus }) => {
            setSubmitting(true);
            const variables = {
                Owner: values.Owner,
                Iban: values.Iban.trim(),
                Bic: values.Bic,
                preferredBankAccount: values.preferredBankAccount,
            };

            addBankAccount({ variables })
                .then((res) => {
                    setSubmitting(false);
                    if (res?.data?.errors?.[0]) {
                        setStatus({ requestError: res.data.errors[0].message });
                    }

                    if (!res || res.errors || res.data.errors || res.data.data.errors) return;
                    const allBankAccounts = res?.data?.data?.addBankAccount?.user?.BankAccount;

                    const selectedBankUid = res?.data?.data?.addBankAccount?.user?.Mangopay?.LinkUserSelectedBankAccountPayout;
                    const selectedBankAccount =
                        Array.isArray(allBankAccounts) &&
                        allBankAccounts.length &&
                        allBankAccounts.find((x) => x.uid === selectedBankUid);
                    props.updateData({
                        ...props.data,
                        allBankAccounts,
                        selectedBankAccount: { ...selectedBankAccount },
                    });
                    props.setStep(values.preferredBankAccount ? "payOut" : "allBankAccount");
                    props.setBackSlideEffect("step-slide-right");
                })
                .catch(() => {
                    setSubmitting(false);
                });
        },
        displayName: "AddBankAccount",
    })
)(AddBankAccount);
