import React, { useEffect, useState, useRef } from "react";
import * as yup from "yup";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withFormik } from "formik";
import { createStructuredSelector } from "reselect";
import cn from "classnames";
import { phoneRegex, textNumberRegex } from "constants/validationRegex";
import { isValidBIC, isValidIBAN, friendlyFormatIBAN, electronicFormatIBAN } from "ibantools";
const pick = require("lodash/pick");
import IntlTelInput from "components/IntlTelInput";
import ErrorMessage from "components/ErrorMessage";
import PrevButton from "components/PrevButton";
import NextButton from "components/NextButton";
import SelectInput from "components/SelectInput";
import Input from "components/Input";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { familyStatuses, groupedCountries, PERSONAL_FORM_INPUTS } from "constants/index";
import { ibanCheck } from "api";
import { backButtonText, investmentCancelLinkText } from "i18n/messages/buttonTitles";
import { getTitles } from "utils";
import { selectIsMobileNumberVerified } from "store/user/account/selectors";

const messages = {
    heading: "personal_data",
    withdrawlHeading: "personal_withdrawl",
    withdrawlContent: "personal_withdrawl_content",
    accountOwner: "personal_account_owner",
    genderWoman: "miss",
    genderMan: "mister",
    genderMisc: "personal_gender_misc",
    investmentPersonalFormValidationEmailAddress: "email_address_is_invalid",
    investmentPersonalFormGenderValidationRequired: "salutation_is_required",
    investmentPersonalFormHouseNumberValidationRequired: "house_number_is_required",
    investmentPersonalFormCountryValidationRequired: "country_is_required",
    investmentPersonalFormMaritalStatusValidationRequired: "marital_status_is_required",
    investmentPersonalFormStreetValidationRequired: "street_is_required",
    investmentPersonalFormPlaceValidationRequired: "place_is_required",
    investmentPersonalFormPostCodeValidationRequired: "post_code_is_required",
    investmentPersonalFormFirstNameValidationRequired: "first_name_is_required",
    investmentPersonalFormLastNameValidationRequired: "last_name_is_required",
    investmentPersonalFormPhoneNumberValidationRequired: "phone_number_is_required",
    investmentPersonalFormPhoneNumberValidationValid: "phone_number_is_not_valid",
    investmentPersonalFormEmailValidationRequired: "email_is_required",
    investmentPersonalFormInputSelectPlaceholder: "please_select",
    investmentPersonalFormInputFirstName: "firstname",
    investmentPersonalFormInputLastName: "lastname",
    investmentPersonalFormInputStreet: "street_placeholder",
    investmentPersonalFormInputHouseNumber: "house_number",
    investmentPersonalFormInputAdditionalAddress: "additional_address",
    investmentPersonalFormInputPostCode: "post_code",
    investmentPersonalFormInputPlace: "place",
    investmentPersonalFormInputCountry: "country_placeholder",
    investmentPersonalFormInputMaritalStatus: "marital_status",
    investmentPersonalFormInputEmail: "email_address",
    investmentPersonalFormInputPhoneNumber: "mobile_phone_number",
    investmentPersonalFormInputTitle: "title_placeholder",
    investmentPersonalFormInputGender: "salutation",
    investmentPaymentMethodFormIbanValidationRequired: "iban_is_required",
    investmentPaymentMethodFormIbanValidationValid: "iban_is_not_valid",
    investmentPaymentMethodFormAdditionalAddressValidationRequired: "bic_is_required",
    investmentPaymentMethodFormAccountOwnerValidationRequired: "account_owner_is_required",
    investmentPaymentMethodFormBicValidationValid: "bic_is_not_valid",
    pleaseSelect: "please_select",
    BICPlaceholder: "is_determined_automatically",
    specialCharactersNotAllowed: "please_enter_valid_character",
};

messages.backButtonText = backButtonText;

const PersonalData = ({
    className = "",
    handleChange,
    handleBlur,
    handleSubmit,
    onBack,
    onSkip,
    values,
    setFieldValue,
    touched,
    setFieldTouched,
    errors,
    validateForm,
    submitForm,
    isRequiredBackButton,
    isRequiredSkipButton,
    isMobileNumberVerified,
    open,
    backSlideEffect = "",
    isInvestment = false,
    isWithSecondaryMarket,
}) => {
    useSideModalScrollToTop();

    const [isOwnerChanged, setOwnerChanged] = useState();
    const isInit = useRef(false);

    const femaleID = isInvestment ? "investFemale" : isWithSecondaryMarket ? "SMFemale" : "female";
    const maleID = isInvestment ? "investMale" : isWithSecondaryMarket ? "SMMale" : "male";
    const diversID = isInvestment ? "investDivers" : isWithSecondaryMarket ? "SMDivers" : "divers";

    useEffect(() => {
        if (!isInit.current) {
            validateForm();
        }
    }, [values, touched, errors]);

    useEffect(() => {
        if (errors.IBAN) {
            setFieldValue("BIC", "");
            setFieldTouched("BIC", false);
        } else if (values.IBAN && !errors.IBAN) {
            getBIC();
        }
    }, [values.IBAN, errors.IBAN]);

    useEffect(() => {
        //For Account Owner name autofill
        if (!isInit.current) {
            (!isOwnerChanged || !values.Owner) && setFieldValue("Owner", values.FirstName + " " + values.LastName);
        } else {
            isInit.current = false;
        }
    }, [values.FirstName, values.LastName]);

    const getBIC = async () => {
        // setFieldValue('BIC', '');
        const res = await ibanCheck(electronicFormatIBAN(values.IBAN.trim()));
        const BIC = res && res.data && res.data.data && res.data.data.ibanCheck && res.data.data.ibanCheck.bic;
        BIC && BIC !== values.BIC && setFieldValue("BIC", BIC);
    };

    const { formatMessage } = useFormattedMessage();

    const titles = getTitles(formatMessage);
    const onOwnerChanged = (e) => {
        setOwnerChanged(!!values.Owner);
        handleBlur(e);
    };

    return (
        <>
            <div className={`${className} personal-data ${backSlideEffect}`}>
                <h3 className="mb-8">{formatMessage(messages.heading)}</h3>
                <form method="post" onSubmit={handleSubmit}>
                    <div className="form-element mb-5">
                        <label className="mb-2 d-inline-block">
                            {formatMessage(messages.investmentPersonalFormInputGender)} *
                        </label>
                        <div className="tabs flex align-center gender tabs-input">
                            <input
                                value={"female"}
                                checked={"female" === values.TypeGender ? true : false}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id={femaleID}
                                type="radio"
                                name="TypeGender"
                            />
                            <label className="new-btn medium mw-100 text-center" htmlFor={femaleID}>
                                {formatMessage(messages.genderWoman)}
                            </label>
                            <input
                                value={"male"}
                                checked={"male" === values.TypeGender ? true : false}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id={maleID}
                                type="radio"
                                name="TypeGender"
                            />
                            <label className="new-btn medium mw-100 text-center" htmlFor={maleID}>
                                {formatMessage(messages.genderMan)}
                            </label>
                            <input
                                value={"divers"}
                                checked={"divers" === values.TypeGender ? true : false}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id={diversID}
                                type="radio"
                                name="TypeGender"
                            />
                            <label className="new-btn medium mw-100 text-center" htmlFor={diversID}>
                                {formatMessage(messages.genderMisc)}
                            </label>
                        </div>
                        {errors.gender && <ErrorMessage>{formatMessage(messages[errors.gender])}</ErrorMessage>}
                    </div>
                    <SelectInput
                        className="mb-5"
                        label={formatMessage(messages.investmentPersonalFormInputTitle)}
                        maxMenuHeight={400}
                        isSearchable={false}
                        selectClassName="pd-title-select-input"
                        classNamePrefix="select"
                        name="TypeTitle"
                        options={titles}
                        placeholder={formatMessage(messages.investmentPersonalFormInputSelectPlaceholder)}
                        value={values.TypeTitle}
                        onChange={(value) => setFieldValue("TypeTitle", value)}
                    />
                    <Input
                        className="mb-5"
                        type="text"
                        name="FirstName"
                        id="FirstName"
                        inputClassName="pd-first-name-input"
                        label={`${formatMessage(messages.investmentPersonalFormInputFirstName)} *`}
                        placeholder={`${formatMessage(messages.investmentPersonalFormInputFirstName)} *`}
                        onChange={handleChange}
                        value={values.FirstName}
                        onBlur={handleBlur}
                        errorMessage={errors.FirstName ? formatMessage(messages[errors.FirstName]) : null}
                        error={touched.FirstName && errors.FirstName}
                    />
                    <Input
                        className="mb-5"
                        label={`${formatMessage(messages.investmentPersonalFormInputLastName)} *`}
                        type="text"
                        name="LastName"
                        id="LastName"
                        inputClassName="pd-last-name-input"
                        placeholder={`${formatMessage(messages.investmentPersonalFormInputLastName)} *`}
                        onChange={handleChange}
                        value={values.LastName}
                        onBlur={handleBlur}
                        errorMessage={errors.LastName ? formatMessage(messages[errors.LastName]) : null}
                        error={touched.LastName && errors.LastName}
                    />
                    <div className="flex two-part">
                        <div className="street">
                            <Input
                                className="mb-5"
                                label={`${formatMessage(messages.investmentPersonalFormInputStreet)} *`}
                                type="text"
                                name="Street"
                                placeholder=""
                                id="Street"
                                inputClassName="pd-street-input"
                                onChange={handleChange}
                                value={values.Street}
                                onBlur={handleBlur}
                                errorMessage={errors.Street ? formatMessage(messages[errors.Street]) : null}
                                error={touched.Street && errors.Street}
                            />
                        </div>
                        <div className="house-number">
                            <Input
                                className="mb-5"
                                label={`${formatMessage(messages.investmentPersonalFormInputHouseNumber)} *`}
                                type="text"
                                name="Streetnumber"
                                placeholder=""
                                id="Streetnumber"
                                inputClassName="pd-house-number-input"
                                onChange={handleChange}
                                value={values.Streetnumber}
                                onBlur={handleBlur}
                                errorMessage={errors.Streetnumber ? formatMessage(messages[errors.Streetnumber]) : null}
                                error={touched.Streetnumber && errors.Streetnumber}
                            />
                        </div>
                    </div>
                    <Input
                        className="mb-5"
                        label={formatMessage(messages.investmentPersonalFormInputAdditionalAddress)}
                        type="text"
                        name="StreetAddition"
                        placeholder=""
                        id="StreetAddition"
                        inputClassName="pd-addition-address-input"
                        onChange={handleChange}
                        value={values.StreetAddition}
                        onBlur={handleBlur}
                        errorMessage={errors.StreetAddition ? formatMessage(messages[errors.StreetAddition]) : null}
                        error={touched.StreetAddition && errors.StreetAddition}
                    />
                    <Input
                        className="mb-5"
                        label={`${formatMessage(messages.investmentPersonalFormInputPostCode)} *`}
                        type="text"
                        name="Zipcode"
                        inputClassName="pd-post-code-input"
                        placeholder=""
                        id="Zipcode"
                        onChange={handleChange}
                        value={values.Zipcode}
                        onBlur={handleBlur}
                        errorMessage={errors.Zipcode ? formatMessage(messages[errors.Zipcode]) : null}
                        error={touched.Zipcode && errors.Zipcode}
                    />
                    <Input
                        className="mb-5"
                        label={`${formatMessage(messages.investmentPersonalFormInputPlace)} *`}
                        type="text"
                        name="City"
                        placeholder=""
                        id="City"
                        inputClassName="pd-place-input"
                        onChange={handleChange}
                        value={values.City}
                        onBlur={handleBlur}
                        errorMessage={errors.City ? formatMessage(messages[errors.City]) : null}
                        error={touched.City && errors.City}
                    />
                    <SelectInput
                        className="mb-5"
                        label={`${formatMessage(messages.investmentPersonalFormInputCountry)} *`}
                        maxMenuHeight={400}
                        isSearchable={false}
                        classNamePrefix="select"
                        name="Country"
                        selectClassName="pd-country-input"
                        options={groupedCountries()}
                        placeholder={formatMessage(messages.pleaseSelect)}
                        value={values.Country}
                        onChange={(value) => setFieldValue("Country", value)}
                        errorMessage={errors.Country ? formatMessage(messages[errors.Country]) : null}
                        error={touched.Country && errors.Country}
                    />
                    <SelectInput
                        className="mb-5"
                        label={`${formatMessage(messages.investmentPersonalFormInputMaritalStatus)} *`}
                        maxMenuHeight={400}
                        isSearchable={false}
                        classNamePrefix="select"
                        name="MaritalStatus"
                        selectClassName="pd-marital-status-input"
                        options={familyStatuses()}
                        placeholder={formatMessage(messages.pleaseSelect)}
                        value={values.MaritalStatus}
                        onChange={(value) => setFieldValue("MaritalStatus", value)}
                        errorMessage={errors.MaritalStatus ? formatMessage(messages[errors.MaritalStatus]) : null}
                        error={touched.MaritalStatus && errors.MaritalStatus}
                    />
                    <Input
                        readOnly
                        className="mb-5"
                        label={`${formatMessage(messages.investmentPersonalFormInputEmail)} *`}
                        type="text"
                        name="Email"
                        id="Email"
                        placeholder={`${formatMessage(messages.investmentPersonalFormInputEmail)} *`}
                        inputClassName="pd-email-input bg-lighter-grey"
                        onChange={handleChange}
                        value={values.Email}
                        onBlur={handleBlur}
                        errorMessage={errors.Email ? formatMessage(messages[errors.Email]) : null}
                        error={touched.Email && errors.Email}
                    />
                    <IntlTelInput
                        readOnly={isMobileNumberVerified}
                        className={`mb-8 ${isMobileNumberVerified ? "disable-pointer" : ""}`}
                        label={`${formatMessage(messages.investmentPersonalFormInputPhoneNumber)} *`}
                        showPlaceholderOnTop={false}
                        fieldName="Number"
                        fieldId="Number"
                        placeholder={`${formatMessage(messages.investmentPersonalFormInputPhoneNumber)} *`}
                        defaultCountry={values.CountryCode || "de"}
                        formatOnInit={false}
                        inputClassName={`pd-intl-tel-number-input ${isMobileNumberVerified ? "bg-lighter-grey" : ""}`}
                        onPhoneNumberChange={(...value) => {
                            setFieldValue("Number", value[1]);
                            setFieldValue("DialCode", value[2]?.dialCode);
                            setFieldValue("CountryCode", value[2]?.iso2);
                            setFieldTouched("Number", true);
                        }}
                        onSelectFlag={(...value) => {
                            setFieldValue("DialCode", value[1] && value[1].dialCode);
                            setFieldValue("CountryCode", value[1] && value[1].iso2);
                        }}
                        onPhoneNumberBlur={() => setFieldTouched("Number", true)}
                        defaultValue={values.Number}
                        value={values.Number}
                        error={touched.Number && errors.Number}
                        showErrorMessage
                        errorMessage={touched.Number && errors.Number && formatMessage(messages[errors.Number])}
                    />
                    <div className="payment-details">
                        <h4>{formatMessage(messages.withdrawlHeading)}</h4>
                        <span className="text-14 d-inline-block mb-3">{formatMessage(messages.withdrawlContent)}</span>
                        <Input
                            className="mb-5"
                            label={`${formatMessage(messages.accountOwner)} *`}
                            type="text"
                            name="Owner"
                            placeholder=""
                            inputClassName="pd-account-owner-input"
                            onChange={handleChange}
                            value={values.Owner}
                            onBlur={onOwnerChanged}
                            errorMessage={errors.Owner ? formatMessage(messages[errors.Owner]) : null}
                            error={touched.Owner && errors.Owner}
                        />
                        <Input
                            className="mb-5"
                            label="IBAN *"
                            type="text"
                            name="IBAN"
                            inputClassName="pd-iban-input"
                            placeholder=""
                            onChange={(value) => setFieldValue("IBAN", friendlyFormatIBAN(value.target.value))}
                            value={values.IBAN}
                            onBlur={handleBlur}
                            errorMessage={errors.IBAN ? formatMessage(messages[errors.IBAN]) : null}
                            error={touched.IBAN && errors.IBAN}
                        />
                        <Input
                            label="BIC *"
                            type="text"
                            name="BIC"
                            inputClassName="pd-bic-input"
                            placeholder={formatMessage(messages.BICPlaceholder)}
                            onChange={handleChange}
                            value={values.BIC}
                            onBlur={handleBlur}
                            errorMessage={errors.BIC ? formatMessage(messages[errors.BIC]) : null}
                            error={touched.BIC && errors.BIC}
                        />
                    </div>
                </form>
            </div>
            {isRequiredSkipButton && (
                <button type="button" className="cancel medium cta-inverse" onClick={() => onSkip(values)}>
                    {formatMessage(investmentCancelLinkText)}
                </button>
            )}
            {open && (
                <div className="bottom-bar-container">
                    {isRequiredBackButton && <PrevButton className="pd-back-button" onClick={onBack} />}
                    <NextButton
                        className={cn({
                            "disable-grey": Object.keys(errors).length,
                        })}
                        onClick={() => submitForm()}
                    />
                </div>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    isMobileNumberVerified: selectIsMobileNumberVerified,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        mapPropsToValues: (props) => {
            return {
                FirstName: "",
                Email: "",
                Number: "",
                DialCode: "49",
                CountryCode: "de",
                StreetAddition: "",
                City: "",
                Zipcode: "",
                Street: "",
                LastName: "",
                TypeTitle: "",
                TypeGender: "",
                Country: "",
                MaritalStatus: "",
                Streetnumber: "",
                BIC: "",
                Owner: "",
                IBAN: "",
                ...(props.data && Object.keys(props.data).length ? props.data : {}),
            };
        },
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: () =>
            yup.object().shape({
                TypeGender: yup.string().required("investmentPersonalFormGenderValidationRequired").nullable(true),
                FirstName: yup
                    .string()
                    .required("investmentPersonalFormFirstNameValidationRequired")
                    .test("FirstName", "specialCharactersNotAllowed", (value) => {
                        return !value?.startsWith(" ");
                    })
                    .matches(textNumberRegex, "specialCharactersNotAllowed")
                    .nullable(true),
                LastName: yup
                    .string()
                    .required("investmentPersonalFormLastNameValidationRequired")
                    .test("LastName", "specialCharactersNotAllowed", (value) => {
                        return !value?.startsWith(" ");
                    })
                    .matches(textNumberRegex, "specialCharactersNotAllowed")
                    .nullable(true),
                Number: yup
                    .string()
                    .matches(phoneRegex, "specialCharactersNotAllowed")
                    .required("investmentPersonalFormPhoneNumberValidationRequired")
                    .nullable(true),
                Email: yup
                    .string()
                    .email("investmentPersonalFormValidationEmailAddress")
                    .required("investmentPersonalFormEmailValidationRequired")
                    .test("Email", "specialCharactersNotAllowed", (value) => {
                        return !value?.startsWith(" ");
                    })
                    .nullable(true),
                MaritalStatus: yup.object().shape({
                    value: yup.string().required("investmentPersonalFormMaritalStatusValidationRequired").nullable(true),
                }),
                Streetnumber: yup
                    .string()
                    .required("investmentPersonalFormHouseNumberValidationRequired")
                    .test("Streetnumber", "specialCharactersNotAllowed", (value) => {
                        return !value?.startsWith(" ");
                    })
                    .matches(textNumberRegex, "specialCharactersNotAllowed")
                    .nullable(true),
                Country: yup.object().shape({
                    value: yup.string().required("investmentPersonalFormCountryValidationRequired").nullable(true),
                }),
                Street: yup
                    .string()
                    .required("investmentPersonalFormStreetValidationRequired")
                    .test("Street", "specialCharactersNotAllowed", (value) => {
                        return !value?.startsWith(" ");
                    })
                    .matches(textNumberRegex, "specialCharactersNotAllowed")
                    .nullable(true),
                City: yup
                    .string()
                    .required("investmentPersonalFormPlaceValidationRequired")
                    .test("City", "specialCharactersNotAllowed", (value) => {
                        return !value?.startsWith(" ");
                    })
                    .matches(textNumberRegex, "specialCharactersNotAllowed")
                    .nullable(true),
                Zipcode: yup
                    .string()
                    .required("investmentPersonalFormPostCodeValidationRequired")
                    .test("Zipcode", "specialCharactersNotAllowed", (value) => {
                        return !value?.startsWith(" ");
                    })
                    .matches(textNumberRegex, "specialCharactersNotAllowed")
                    .nullable(true),
                StreetAddition: yup.string().matches(textNumberRegex, "specialCharactersNotAllowed").nullable(true),
                BIC: yup
                    .string()
                    .test("BIC", "investmentPaymentMethodFormBicValidationValid", (value) => {
                        return !value ? true : !!(value && isValidBIC(value.replace(/\s/g, "")));
                    })
                    .required("investmentPaymentMethodFormAdditionalAddressValidationRequired")
                    .nullable(true),
                Owner: yup
                    .string()
                    .matches(textNumberRegex, "specialCharactersNotAllowed")
                    .test("Owners", "specialCharactersNotAllowed", (value) => {
                        return !value?.startsWith(" ");
                    })
                    .required("investmentPaymentMethodFormAccountOwnerValidationRequired")
                    .nullable(true),
                IBAN: yup
                    .string()
                    .trim()
                    .test("IBAN", "investmentPaymentMethodFormIbanValidationValid", (value) => {
                        return !value ? true : !!(value && isValidIBAN(electronicFormatIBAN(value)));
                    })
                    .required("investmentPaymentMethodFormIbanValidationRequired")
                    .nullable(true),
            }),
        handleSubmit: (values, { setSubmitting, props }) => {
            setSubmitting(false);
            const formData = pick(values, PERSONAL_FORM_INPUTS);
            props.onSubmit && props.onSubmit(formData);
        },
        displayName: "InvestmentPersonalForm",
    })
)(PersonalData);
