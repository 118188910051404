import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import Cookies from "js-cookie";

import { checkValidString, getAccessToken, getQueryString } from "utils/index";
import { rootReducer } from "store/root-reducer";

import { getTenantFromHost, getDocumentsHost } from "common/helper";

import rootSaga from "store/root-saga";

import {
    setFinxConfig,
    setHost,
    setPlatformSource,
    setTenant,
    setMpinFlag,
    setIsClubdeal,
    setMobileNotification,
    setMobilePlatform,
    setDocumentHost,
    setLocale,
} from "store/app/actions";

import { storeAuthTokenData } from "store/user/account/actions";

const FINX_COOKIE = require("common/constants");
const loadCurrentHostConfig = require("common/helper").loadCurrentHostConfig;
const FINX_CURRENT_CONFIG = loadCurrentHostConfig();

const IS_LOGGER_ENABLED = FINX_CURRENT_CONFIG.IS_LOGGER_ENABLED == "true" || FINX_CURRENT_CONFIG.IS_LOGGER_ENABLED === true;

export const initializedClientStore = (defaultState) => {
    const sagaMiddleware = createSagaMiddleware();
    const logger = createLogger({ collapsed: true });
    const middlewares = [sagaMiddleware, IS_LOGGER_ENABLED && logger].filter(Boolean);
    const enhancer = compose(applyMiddleware(...middlewares));
    const store = createStore(rootReducer, defaultState, enhancer);

    const platformSource = getQueryString("source") || Cookies.get(FINX_COOKIE.PLATFORM_SOURCE);
    const isMpin = getQueryString("mpin") || Cookies.get(FINX_COOKIE.IS_MPIN);
    const isClubdeal = getQueryString("isClubdeal") || Cookies.get(FINX_COOKIE.IS_CLUBDEAL);
    const isNotification = getQueryString("notification") || Cookies.get(FINX_COOKIE.IS_NOTIFICATION);
    const mobilePlatform = getQueryString("mobile-platform") || Cookies.get(FINX_COOKIE.MOBILE_PLATFORM);

    mobilePlatform && store.dispatch(setMobilePlatform(mobilePlatform));
    platformSource && store.dispatch(setPlatformSource(platformSource));
    isNotification && store.dispatch(setMobileNotification(isNotification));
    isClubdeal && store.dispatch(setIsClubdeal(isClubdeal));
    isMpin && store.dispatch(setMpinFlag(isMpin));

    const accessToken = platformSource === "app" ? Cookies.get(FINX_COOKIE.FINX_SESSION) : getAccessToken();
    checkValidString(accessToken) && store.dispatch(storeAuthTokenData(accessToken));

    store.dispatch(setLocale(Cookies.get(FINX_COOKIE.LOCALE)));
    store.dispatch(setFinxConfig(FINX_CURRENT_CONFIG));
    store.dispatch(setTenant(getTenantFromHost(window.location.host)));
    store.dispatch(setHost(window.location.host));
    store.dispatch(setDocumentHost(getDocumentsHost()));

    store.sagaTask = sagaMiddleware.run(rootSaga);
    return store;
};
