import { CLUBDEALS_PROJECT_STATUS, INVESTMENT_STATUS, PROJECT_STATUS, TILE_STATUS } from "constants/status";

export const checkbox = {
    kundenstamm: "client_base",
    privatkunden: "retail_customers",
    institutionelleKunden: "institutional_customers",
    kundenViaMaklerpool: "broker_pool_customer",
    mindestensEineAuswahlIstErforderlich: "one_checkbox_required",
};

export const formValidations = {
    duMusstDieDatenschutzerklärungAkzeptieren: "duMusstDieDatenschutzerklärungAkzeptieren",
};

export const extra = {
    userExists: "user_exists",
    already_exist: "already_exist",
    pleaseSelect: "please_select",
    userExistsClubPartner: "userExists_club_partner",
    phoneUserExists: "phone_user_exists",
};

export const successMessages = {
    partnerProgram: "partner_program_success_message",
};

export const investmentLookupMessages = {
    [PROJECT_STATUS.COMING_SOON]: "project_status_coming_soon",
    [PROJECT_STATUS.ADVERTISING]: "project_status_advertising",
    [PROJECT_STATUS.VIP_FUNDING]: "project_status_vip_funding",
    [PROJECT_STATUS.FUNDING_ACTIVE]: "project_status_funding_active",
    [PROJECT_STATUS.FUNDING_CLOSED]: "project_status_funding_closed",
    [PROJECT_STATUS.FINANCING_COMPLETED]: "project_status_financing_completed",
    [PROJECT_STATUS.MARKETPLACE]: "project_status_marketplace",
    [PROJECT_STATUS.MARKETPLACE_PAUSED]: "project_status_marketplace_paused",
    [PROJECT_STATUS.MARKETPLACE_REVIEW]: "project_status_marketplace_review",
    [PROJECT_STATUS.SUCCESSFULLY_PAID_BACK]: "project_status_successfully_paid_back",
    [PROJECT_STATUS.CLUB_DEAL]: "exclusive_clubdeal",
    [INVESTMENT_STATUS.RESERVED]: "reservation_made",
    [INVESTMENT_STATUS.INITIALIZED]: "investment_started",
    [INVESTMENT_STATUS.CREATED_PENDING_KYC]: "investment_offer_under_review",
    [TILE_STATUS.INITIATED]: "identification_required",
    [TILE_STATUS.PENDING]: "identification_in_check",
    [INVESTMENT_STATUS.CREATED_PENDING_LIABILITIY_UMBRELLA_CLEARANCE]: "investment_offer_under_review",
    [INVESTMENT_STATUS.CREATED_PENDING_ISSUER_CLEARANCE]: "investment_offer_under_review",
    [INVESTMENT_STATUS.CREATED_PENDING_PAYMENT]: "payment_transfer_due",
    [INVESTMENT_STATUS.CREATED_PENDING_PROJECT_FINANCING]: "waiting_for_total_funding",
    [INVESTMENT_STATUS.CREATED_PENDING_PROJECT_GO_LIVE]: "issue_of_token",
    [INVESTMENT_STATUS.ACTIVE]: "waiting_for_secondary_market",
    [INVESTMENT_STATUS.CANCELED]: "investment_offer_declined",
    [INVESTMENT_STATUS.INVESTMENT_SUCCESSFULLY_PAYED_OUT]: "successfully_payed_out",
    "Request Information": "request_information",
    default: "status_default",
};

export const adminInvestmentMessages = {
    [INVESTMENT_STATUS.RESERVED]: "reservation_made",
    [INVESTMENT_STATUS.INITIALIZED]: "investment_started",
    [INVESTMENT_STATUS.CREATED_PENDING_KYC]: "customer_kyc_pending",
    [INVESTMENT_STATUS.CREATED_PENDING_LIABILITIY_UMBRELLA_CLEARANCE]: "under_review",
    [INVESTMENT_STATUS.CREATED_PENDING_ISSUER_CLEARANCE]: "under_review_issuer",
    [INVESTMENT_STATUS.CREATED_PENDING_PAYMENT]: "customer_payment_due",
    [INVESTMENT_STATUS.CREATED_PENDING_PROJECT_FINANCING]: "complete_product_financing",
    [INVESTMENT_STATUS.CREATED_PENDING_PROJECT_GO_LIVE]: "tokenization_due",
    [INVESTMENT_STATUS.INVESTMENT_SUCCESSFULLY_PAYED_OUT]: "product_finished",
    [INVESTMENT_STATUS.SECONDARY_MARKET_BUY]: "secondary_market_purchase",
    [INVESTMENT_STATUS.SECONDARY_MARKET_SALE]: "secondary_market_sell",
    [INVESTMENT_STATUS.ACTIVE]: "order_status_active",
    [INVESTMENT_STATUS.CANCELED]: "canceled",
    default: "status_default",
};

export const clubDealMessages = {
    [CLUBDEALS_PROJECT_STATUS.ADVERTISING]: "apply_now",
    [CLUBDEALS_PROJECT_STATUS.FUNDING_CLOSED]: "financed_successfully",
    default: "status_default",
};
