import React, { useEffect, useState } from "react";
import { useSideModalScrollToTop, useSideModalScrollListener, useUtils } from "hooks";

// import Cookies from "js-cookie";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
    selectBankAccount,
    selectUserSelectedBankAccount,
    selectPrimaryBankAccount,
    selectUserDetails,
    selectUserMangopay,
    selectPayInWalletBalance,
    selectBlockedWalletBalance,
    selectStatusMangopay,
} from "store/user/account/selectors";

import { sideModalScrollHidden } from "utils";

import ArrowRight from "assets/images/svg/arrow-right.svg";

import Close from "components/Close";
import TermsNCondition from "./TermsNCondition";
import WalletOverview from "./WalletOverview";
import TransferEMoney from "./transferEMoney";
import TransferEMoneyConfirmation from "./transferEMoney/Confirmation";
import TransferEMoneyOverview from "./transferEMoney/Overview";
import PayOut from "./payOut";
import PayOutConfirmation from "./payOut/Confirmation";
import PayOutOverview from "./payOut/Overview";
import PayInInfo from "./payIn/Info";
import PayIn from "./payIn";
import PayInOverview from "./payIn/Overview";
import AllActivities from "./allActivities";
import ActivityDetails from "./activityDetails";
import AllBankAccount from "./payOut/AllBankAccount";
import AddBankAccount from "./payOut/AddBankAccount";
import ReserveOrders from "./reserveOrders";
import RetakeIF from "./retakeIF";

const EuroWalletSideModal = (props) => {
    useSideModalScrollToTop();

    const {
        open,
        onCloseButtonClick,
        initiateClose,
        userMangopayDetails,
        payInWalletBalance,
        blockedWalletBalance,
        userDetails,
        selectedBankAccount,
        primaryBankAccount,
        bankAccounts,
        statusMangopay,
        statusSecondaryMarket,
    } = props;

    const [backSlideEffect, setBackSlideEffect] = useState("");

    const [step, setStep] = useState("walletOverview");
    const [closeTitle, setCloseTitle] = useState("");
    const [stepData, setStepData] = useState({});
    const [data, updateData] = useState({});

    const isApp = useUtils().isPlatformSourceFromApp;
    useSideModalScrollListener(props, isApp);

    useEffect(() => {
        isApp && sideModalScrollHidden(open);
    }, [open]);

    useEffect(() => {
        isApp && sideModalScrollHidden(open);

        const escFunction = (event) => {
            if (event.key === "Escape") {
                onClose();
            }
        };
        window.addEventListener("keydown", escFunction);
        return () => window.removeEventListener("keydown", escFunction, false);
    }, []);

    useEffect(() => {
        updateData({
            payInWalletBalance: payInWalletBalance || 0,
            blockedWalletBalance: blockedWalletBalance || 0,
            currentUser: {
                firstName: userDetails.FirstName,
                lastName: userDetails.LastName,
                email: userDetails.Email,
            },
            selectedBankAccount: { ...selectedBankAccount },
            bankAccount: { ...primaryBankAccount },
            allBankAccounts: bankAccounts,
            mangopay: { ...userMangopayDetails },
        });
    }, [
        payInWalletBalance,
        blockedWalletBalance,
        userDetails,
        selectedBankAccount,
        primaryBankAccount,
        bankAccounts,
        userMangopayDetails,
    ]);

    useEffect(() => {
        if (initiateClose) {
            reset();
        }
    }, [initiateClose]);

    const onClose = () => {
        onCloseButtonClick();
        reset();
    };

    const reset = () => {
        setStep("walletOverview");
    };

    const setStepAndData = (step, nextStepData) => {
        setStep(step);
        setStepData({
            [step]: nextStepData,
        });
    };

    const renderStep = {
        termsNCondition: (
            <TermsNCondition
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                setShowDate={() => {}}
                setCloseTitle={setCloseTitle}
                setStep={setStep}
            />
        ),
        walletOverview: (
            <WalletOverview
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                data={data}
                statusMangopay={statusMangopay}
                statusSecondaryMarket={statusSecondaryMarket}
                setShowDate={() => {}}
                setCloseTitle={setCloseTitle}
                setStep={setStep}
                setStepAndData={setStepAndData}
            />
        ),
        transferEMoney: (
            <TransferEMoney
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                data={data}
                setStepAndData={setStepAndData}
                setShowDate={() => {}}
                setCloseTitle={setCloseTitle}
                setStep={setStep}
            />
        ),
        transferEMoneyConfirmation: (
            <TransferEMoneyConfirmation
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                stepData={stepData.transferEMoneyConfirmation}
                setStepAndData={setStepAndData}
                data={data}
                setShowDate={() => {}}
                setCloseTitle={setCloseTitle}
                setStep={setStep}
            />
        ),
        transferEMoneyOverview: (
            <TransferEMoneyOverview
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                data={data}
                stepData={stepData.transferEMoneyOverview}
                setShowDate={() => {}}
                setCloseTitle={setCloseTitle}
                setStep={setStep}
            />
        ),
        payOut: (
            <PayOut
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                data={data}
                setShowDate={() => {}}
                setCloseTitle={setCloseTitle}
                setStepAndData={setStepAndData}
                setStep={setStep}
                stepData={stepData}
                setStepData={setStepData}
            />
        ),
        payOutConfirmation: (
            <PayOutConfirmation
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                stepData={stepData.payOutConfirmation}
                setStepAndData={setStepAndData}
                data={data}
                setShowDate={() => {}}
                setCloseTitle={setCloseTitle}
                setStep={setStep}
            />
        ),
        payOutOverview: (
            <PayOutOverview
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                data={data}
                stepData={stepData.payOutOverview}
                setShowDate={() => {}}
                setCloseTitle={setCloseTitle}
                setStep={setStep}
            />
        ),
        payInInfo: (
            <PayInInfo
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                data={data}
                setShowDate={() => {}}
                setCloseTitle={setCloseTitle}
                setStep={setStep}
            />
        ),
        payIn: (
            <PayIn
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                data={data}
                setStepAndData={setStepAndData}
                setShowDate={() => {}}
                setCloseTitle={setCloseTitle}
                setStep={setStep}
            />
        ),
        payInOverview: (
            <PayInOverview
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                stepData={stepData.payInOverview}
                data={data}
                setShowDate={() => {}}
                setCloseTitle={setCloseTitle}
                setStep={setStep}
            />
        ),
        allActivities: (
            <AllActivities
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                setShowDate={() => {}}
                setStep={setStep}
                setCloseTitle={setCloseTitle}
                setStepAndData={setStepAndData}
            />
        ),
        activityDetails: (
            <ActivityDetails
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                setStep={setStep}
                stepData={stepData}
                setShowDate={() => {}}
                setCloseTitle={setCloseTitle}
            />
        ),
        allBankAccount: (
            <AllBankAccount
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                data={data}
                setShowDate={() => {}}
                setCloseTitle={setCloseTitle}
                setStepAndData={setStepAndData}
                setStep={setStep}
                stepData={stepData}
                updateData={updateData}
                setStepData={setStepData}
            />
        ),
        addBankAccount: (
            <AddBankAccount
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                data={data}
                setShowDate={() => {}}
                setCloseTitle={setCloseTitle}
                setStepAndData={setStepAndData}
                setStep={setStep}
                updateData={updateData}
                setStepData={setStepData}
            />
        ),
        reserveOrders: (
            <ReserveOrders
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                setShowDate={() => {}}
                stepData={stepData.reserveOrders}
                setStepAndData={setStepAndData}
                data={data}
                setCloseTitle={setCloseTitle}
                setStep={setStep}
            />
        ),
        retakeIF: (
            <RetakeIF
                backSlideEffect={backSlideEffect}
                setBackSlideEffect={setBackSlideEffect}
                setShowDate={() => {}}
                data={data}
                setCloseTitle={setCloseTitle}
                setStep={setStep}
                setStepAndData={setStepAndData}
                stepData={stepData.retakeIF}
            />
        ),
    };

    // const currentDate = getRegionFormattedDate(Date.now());

    return (
        <>
            <div className={`euro-wallet-side-modal side-modal editable-modal shadow${open ? " opened" : " closed"}`}>
                <div className={"close-container d-flex align-center justify-between"}>
                    <div className="d-flex">
                        {step === "walletOverview" && (
                            <div className="back-arrow-top">
                                <span className="back-arrow" onClick={props.onBackButtonClick}>
                                    <ArrowRight />
                                </span>
                            </div>
                        )}
                        <h4 className="modal-title my-0">{closeTitle}</h4>
                    </div>
                    <div className="d-flex align-center">
                        <Close className="close-button fw-600" onCloseButtonClick={onClose} />
                    </div>
                </div>
                <div className="side-modal-wrap flex px-0">
                    <div className="modal-body">
                        <div className="side-modal-container mw-100 pt-0">{renderStep[step]}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    userMangopayDetails: selectUserMangopay,
    payInWalletBalance: selectPayInWalletBalance,
    blockedWalletBalance: selectBlockedWalletBalance,
    userDetails: selectUserDetails,
    selectedBankAccount: selectUserSelectedBankAccount,
    primaryBankAccount: selectPrimaryBankAccount,
    bankAccounts: selectBankAccount,
    statusMangopay: selectStatusMangopay,
});

export default connect(mapStateToProps)(EuroWalletSideModal);
