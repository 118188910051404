import React, { useEffect, useState, Fragment, useRef } from "react";
import { connect } from "react-redux";
import { PAYMENT_TYPE } from "constants/status";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import { registerLocale } from "react-datepicker";
import { de, enUS } from "date-fns/locale";
import { selectLocale } from "store/app/selectors";
import { selectUserPaymentsFilter, selectDataFromUserDetails } from "store/user/account/selectors";
import FilterIcon from "assets/images/svg/filterIcon.svg";
import PrevButton from "components/PrevButton";
import Loader from "components/Loader";
import AllActivitiesBlock from "./components/allActivitiesBlock";
import DatePickerField from "components/DatePickerField";
import { splitDateStringForProperDate } from "utils";
import moment from "moment";

import { paymentStatusMapping, filterPaymentTypesMapping, filterPaymentStatusMapping, paymentTypeMapping } from "constants/index";

const messages = {
    allActivities: "show_all",
    filterByStatus: "filter_by_status",
    filterByType: "filter_ty_type",
    filterByDate: "filter_by_date",
    lastWeek: "last_week",
    lastMonth: "last_month",
    lastThDays: "last_th_days",
    from: "from",
    to: "to",
    deselectAll: "deselect_all",
    searchText: "search_text",
    startDate: "start_date",
    endDate: "end_date",
};

const AllActivities = ({
    setShowDate,
    setCloseTitle,
    setStep,
    payments: targetPayments = [],
    setStepAndData,
    userEmail,
    locale,
    backSlideEffect = "",
    setBackSlideEffect,
}) => {
    useSideModalScrollToTop();

    const [filterText, setFilterText] = useState("");
    const [newFilterData, setNewFilterData] = useState({});
    const [isShowFilter, setIsShowFilter] = useState(false);
    const [isSelectAllStatus, setIsSelectAllStatus] = useState(false);
    const [isSelectAllTypes, setIsSelectAllTypes] = useState(false);
    const [isSelectAllDate, setIsSelectAllDate] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [payments, setPayments] = useState([]);
    const [offsetData, setOffsetData] = useState({ start: 0, end: 0 });
    const { formatMessage } = useFormattedMessage();
    const initialRender = useRef(true);

    const lastWeekDate = new Date().setDate(new Date().getDate() - 7);
    const lastMonthDate = new Date().setDate(new Date().getDate() - 30);
    const lastHundredDate = new Date().setDate(new Date().getDate() - 100);
    const formatedLastWeekDate = new Date(lastWeekDate);
    const formatedLastMonthDate = new Date(lastMonthDate);
    const formatedLastHundredDate = new Date(lastHundredDate);

    const statusMapping = paymentStatusMapping();
    const typesMapping = paymentTypeMapping();

    const [status, setStatus] = useState(filterPaymentStatusMapping);
    const [types, setTypes] = useState(filterPaymentTypesMapping);

    const [dateValues, setDateValues] = useState({
        beginningDate: "",
        endingDate: "",
    });

    const [date, setDate] = useState({
        lastWeek: false,
        lastMonth: false,
        lastThDays: false,
        customDate: false,
    });

    useEffect(() => {
        setShowDate(false);
        setCloseTitle(formatMessage(messages.allActivities));
        setPayments(targetPayments);
        setOffsetData({ ...offsetData, start: 0, end: 50 });
    }, []);

    useEffect(() => {
        locale === "en" ? registerLocale("en", enUS) : registerLocale("de", de);
    }, [locale]);

    useEffect(() => {
        if (!initialRender.current) {
            setNewFilterData({ ...newFilterData, ...filterGroupData(payments.slice(offsetData.start, offsetData.end)) });
        }
    }, [offsetData.start, offsetData.end, payments]);

    useEffect(() => {
        if (!initialRender.current) {
            setPayments(filterByAllValues(targetPayments, status, types, date, filterText));
            setNewFilterData({});
            setOffsetData({ ...offsetData, start: 0, end: 200 });
        } else {
            initialRender.current = false;
        }
    }, [status, types, date, dateValues, filterText, isSelectAllStatus, isSelectAllTypes]);

    const filterGroupData = (filteredPayments = []) => {
        const newFilterGroupData = newFilterData;
        let month = "",
            baseObj = [];
        filteredPayments.map((item) => {
            month = `${moment(item.CreatedDate.split("_")[0]).format("MMM YYYY")}`;
            baseObj = newFilterGroupData[month] || [];
            baseObj.push(item);
            newFilterGroupData[month] = baseObj;
        });
        setLoading(false);
        return newFilterGroupData;
    };

    const filterByAllValues = (payments = [], status = {}, types = {}, date = {}, filterText = "") => {
        const fStatusData = payments.filter((item) => {
            const keys = Object.keys(status);
            let isValid = true;
            for (const key in keys) {
                if (status[keys[key]] && item.StatusPayment == keys[key]) {
                    isValid = true;
                    break;
                } else {
                    isValid = isValid && !status[keys[key]];
                }
            }
            return isValid;
        });
        const fTypeData = fStatusData.filter((item) => {
            const keys = Object.keys(types);
            let isValid = true;
            for (const key in keys) {
                if (types[keys[key]] && item.TypePayment == keys[key]) {
                    isValid = true;
                    break;
                } else {
                    isValid = isValid && !types[keys[key]];
                }
            }
            return isValid;
        });
        const fDateData = fTypeData.filter((item) => {
            const fCreatedDate = new Date(...splitDateStringForProperDate(item.CreatedDate));
            return (
                (date.lastWeek && fCreatedDate >= formatedLastWeekDate) ||
                (date.lastMonth && fCreatedDate >= formatedLastMonthDate) ||
                (date.lastThDays && fCreatedDate >= formatedLastHundredDate) ||
                (date.customDate && fCreatedDate >= dateValues.beginningDate && fCreatedDate <= dateValues.endingDate) ||
                !(
                    date.lastWeek ||
                    date.lastMonth ||
                    date.lastThDays ||
                    date.customDate ||
                    dateValues.endingDate ||
                    dateValues.beginningDate
                )
            );
        });
        return fDateData.filter((item) => {
            const filterTextNew = filterText.toLowerCase();
            let emailName = "";
            if (item.TypePayment === PAYMENT_TYPE.USER_TRANSFER_EXTERNAL) {
                if (userEmail == item.Creditor) {
                    emailName = item.Beneficiary;
                } else {
                    emailName = item.Creditor;
                }
            }
            return (
                item.StatusPayment.toLowerCase().includes(filterTextNew) ||
                item.TypePayment.toLowerCase().includes(filterTextNew) ||
                item.Amount.toLowerCase().includes(filterTextNew) ||
                item.CreatedDate.toLowerCase().includes(filterTextNew) ||
                emailName.toLowerCase().includes(filterTextNew) ||
                (item &&
                    item.LinkBankAccount &&
                    item.LinkBankAccount.Owner &&
                    item.LinkBankAccount.Owner.toLowerCase().includes(filterTextNew)) ||
                !(filterText || filterTextNew)
            );
        });
    };

    const onBankActivityClick = (activity) => {
        setStepAndData("activityDetails", {
            activityID: activity.uid,
            back: "allActivities",
        });
        setBackSlideEffect("step-slide-left");
    };

    const onStatusChange = (e) => {
        const value = e.target.checked;
        const statusKey = e.target.id;
        setStatus({ ...status, [statusKey]: value });
        setIsSelectAllStatus(false);
    };
    const onAllStatusDeSelect = () => {
        setIsSelectAllStatus(!isSelectAllStatus);
        setStatus((preState) => {
            Object.keys(preState).map((status) => (preState[status] = false));
            return preState;
        });
    };

    const onAllTypesDeSelect = () => {
        setIsSelectAllTypes(!isSelectAllTypes);
        setTypes((preTypes) => {
            Object.keys(preTypes).map((types) => (preTypes[types] = false));
            return preTypes;
        });
    };
    const onAllTypesDeDate = () => {
        setIsSelectAllDate(!isSelectAllDate);
        setDate({
            lastWeek: false,
            lastMonth: false,
            lastThDays: false,
            customDate: false,
        });
        setDateValues({
            beginningDate: "",
            endingDate: "",
        });
    };
    const onTypesChange = (e) => {
        const value = e.target.checked;
        const typeKey = e.target.id;
        setTypes({ ...types, [typeKey]: value });
        setIsSelectAllTypes(false);
    };

    const onDateChange = (e) => {
        const value = e.target.checked;
        const selectedDate = e.target.id;
        const newData = {
            lastWeek: false,
            lastMonth: false,
            lastThDays: false,
            customDate: false,
        };
        setDate({ ...newData, [selectedDate]: value });
        setIsSelectAllDate(false);
    };

    const beginningDateOnchange = (value) => {
        const date = new Date(value);
        setDateValues({
            beginningDate: date,
            endingDate: dateValues.endingDate,
        });
    };

    const endingDateOnchange = (value) => {
        const date = new Date(value);
        setDateValues({
            beginningDate: dateValues.beginningDate,
            endingDate: date,
        });
    };
    const onClickLoadMore = () => {
        setLoading(true);
        setOffsetData({ ...offsetData, start: offsetData.end, end: offsetData.end + 200 });
    };

    return (
        <div className={"all-activities pt-10 pb-25 mw-450 mx-auto"}>
            {!isShowFilter ? (
                <div className={`px-5 ${backSlideEffect}`}>
                    <div className="flex align-center mb-0 py-3 px-4 search-component">
                        <span className="d-inline-block mr-3">
                            <i className="fa fa-search" aria-hidden="true" />
                        </span>
                        <input
                            className="search-input"
                            id="search"
                            type="text"
                            placeholder={formatMessage(messages.searchText)}
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                        />
                        <span className="flex cursor-pointer" onClick={() => setIsShowFilter(true)}>
                            <FilterIcon style={{ width: "18px", height: "18px", fill: "#9e9fa4", paddingTop: "4px" }} />
                        </span>
                    </div>
                    {Object.entries(newFilterData).map((items, index) => {
                        return (
                            <div key={index}>
                                <div className="flex flex-wrap align-center justify-end payment-activities">
                                    <span className="text-12 another-grey-color mb-3 mt-13">{items[0]}</span>
                                    {items[1].map((item, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <div className="w-100">
                                                    <AllActivitiesBlock
                                                        payments={[item]}
                                                        onBankActivityClick={onBankActivityClick}
                                                    />
                                                </div>
                                            </Fragment>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                    {payments.length > offsetData.end && (
                        <div className="flex mt-5 justify-center">
                            {isLoading ? (
                                <Loader className="primary-color" />
                            ) : (
                                <span className="text-12 pointer primary-color" onClick={onClickLoadMore}>
                                    load more <i className="fas fa-angle-double-right" />
                                </span>
                            )}
                        </div>
                    )}
                    <div className="bottom-bar-container">
                        <PrevButton
                            onClick={() => {
                                setStep("walletOverview");
                                setBackSlideEffect("step-slide-right");
                            }}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className={`px-5 border-bottom ${backSlideEffect}`}>
                        <div className="flex justify-between mb-6">
                            <h5 className="my-0">{formatMessage(messages.filterByStatus)}</h5>
                            <span
                                className="text-14 another-grey-color text-decoration-underline cursor-pointer"
                                onClick={onAllStatusDeSelect}
                            >
                                {/* {isSelectAllStatus ? `{formatMessage(messages.deselectAll)}` : `Select all`} */}
                                {formatMessage(messages.deselectAll)}
                            </span>
                        </div>
                        <div className="flex flex-wrap mb-6">
                            {status &&
                                Object.keys(status).map((item, index) => {
                                    return (
                                        <div key={index} className="mb-4">
                                            <input
                                                value={status[item]}
                                                id={item}
                                                type="checkbox"
                                                name={item}
                                                onChange={onStatusChange}
                                                className="d-none filter-checkbox"
                                                checked={status[item]}
                                            />
                                            <label className="filter-btn mw-100" htmlFor={item}>
                                                {statusMapping[item]}
                                            </label>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    <div className="px-5 border-bottom mt-5">
                        <div className="flex justify-between mb-6">
                            <h5 className="my-0">{formatMessage(messages.filterByType)}</h5>
                            <span
                                className="text-14 another-grey-color text-decoration-underline cursor-pointer"
                                onClick={onAllTypesDeSelect}
                            >
                                {formatMessage(messages.deselectAll)}
                            </span>
                        </div>
                        <div className="flex flex-wrap mb-6">
                            {types &&
                                Object.keys(types).map((item, index) => {
                                    return (
                                        <div className="mb-4" key={index}>
                                            <input
                                                value={types[item]}
                                                id={item}
                                                type="checkbox"
                                                name={item}
                                                onChange={onTypesChange}
                                                className="d-none filter-checkbox"
                                                checked={types[item]}
                                            />
                                            <label className="filter-btn mw-100" htmlFor={item}>
                                                {typesMapping[item]}
                                            </label>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    <div className="px-5 border-bottom mt-5">
                        <div className="flex justify-between mb-6">
                            <h5 className="my-0">{formatMessage(messages.filterByDate)}</h5>
                            <span
                                className="text-14 another-grey-color text-decoration-underline cursor-pointer"
                                onClick={onAllTypesDeDate}
                            >
                                {formatMessage(messages.deselectAll)}
                            </span>
                        </div>
                        <div className="mb-6">
                            <div className="mb-4 flex align-center">
                                <input
                                    value={date.lastWeek}
                                    id="lastWeek"
                                    type="radio"
                                    name="date"
                                    onChange={onDateChange}
                                    className="d-none filter-checkbox"
                                    checked={date.lastWeek}
                                />
                                <label className="filter-radio" htmlFor="lastWeek">
                                    <div className={`filter-radio-inner ${date.lastWeek ? "active" : ""}`} />
                                </label>
                                <label className="ml-4" htmlFor="lastWeek">
                                    {formatMessage(messages.lastWeek)}
                                </label>
                            </div>
                            <div className="mb-4 flex align-center">
                                <input
                                    value={date.lastMonth}
                                    id="lastMonth"
                                    type="radio"
                                    name="date"
                                    onChange={onDateChange}
                                    className="d-none filter-checkbox"
                                    checked={date.lastMonth}
                                />
                                <label className="filter-radio" htmlFor="lastMonth">
                                    <div className={`filter-radio-inner ${date.lastMonth ? "active" : ""}`} />
                                </label>
                                <label className="ml-4" htmlFor="lastMonth">
                                    {formatMessage(messages.lastMonth)}
                                </label>
                            </div>
                            <div className="mb-4 flex align-center">
                                <input
                                    value={date.lastThDays}
                                    id="lastThDays"
                                    type="radio"
                                    name="date"
                                    onChange={onDateChange}
                                    className="d-none filter-checkbox"
                                    checked={date.lastThDays}
                                />
                                <label className="filter-radio" htmlFor="lastThDays">
                                    <div className={`filter-radio-inner ${date.lastThDays ? "active" : ""}`} />
                                </label>
                                <label className="ml-4" htmlFor="lastThDays">
                                    {formatMessage(messages.lastThDays)}
                                </label>
                            </div>
                            <div className="mb-4 flex align-center">
                                <input
                                    value={date.customDate}
                                    id="customDate"
                                    type="radio"
                                    name="date"
                                    onChange={onDateChange}
                                    className="d-none filter-checkbox"
                                    checked={date.customDate}
                                />
                                <label className="filter-radio" htmlFor="customDate">
                                    <div className={`filter-radio-inner ${date.customDate ? "active" : ""}`} />
                                </label>
                                <label className="ml-4" htmlFor="customDate">
                                    {formatMessage(messages.from)}
                                </label>
                                <DatePickerField
                                    id="beginningDate"
                                    name="beginningDate"
                                    maxDate={new Date()}
                                    selected={dateValues.beginningDate && (new Date(dateValues.beginningDate) || new Date())}
                                    className="my-0 ml-2 mw-140"
                                    placeholder={formatMessage(messages.startDate)}
                                    value={dateValues.beginningDate}
                                    onChange={beginningDateOnchange}
                                    peekNextMonth
                                    showMonthDropdown
                                    locale={locale}
                                    showYearDropdown
                                    disabled={!date.customDate}
                                />
                                <label className="ml-4">{formatMessage(messages.to)}</label>
                                <DatePickerField
                                    id="endingDate"
                                    name="endingDate"
                                    className="my-0 ml-2 mw-140"
                                    placeholder={formatMessage(messages.endDate)}
                                    maxDate={new Date()}
                                    minDate={dateValues.beginningDate}
                                    selected={dateValues.endingDate && (new Date(dateValues.endingDate) || new Date())}
                                    value={dateValues.endingDate}
                                    onChange={endingDateOnchange}
                                    peekNextMonth
                                    showMonthDropdown
                                    locale={locale}
                                    showYearDropdown
                                    disabled={!date.customDate || dateValues.beginningDate == ""}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="bottom-bar-container">
                        <PrevButton onClick={() => setIsShowFilter(false)} />
                    </div>
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    payments: selectUserPaymentsFilter(state),
    userEmail: selectDataFromUserDetails(state, ["Email"]),
    locale: selectLocale(state),
});

export default connect(mapStateToProps)(AllActivities);
