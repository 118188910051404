import React, { useEffect, useState, Fragment } from "react";
import Toggle from "react-toggle";
import { useFormattedMessage } from "hooks";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import SideModal from "components/SideModal";
import Link from "components/NavLink";
import Loader from "components/Loader";
import ProductSubscribedSideModal from "components/Account/ProductSubscribed";
import { subscribeTag } from "api";
import { fetchUserSubscriptionsStart, fetchUserSubscriptionsSuccess } from "store/user/account/actions";
import { selectUserDetails, selectSubscription, selectFilterUserRoles } from "store/user/account/selectors";
import "react-toggle/style.css";

const messages = {
    sendFormError: "send_form_error",
    weeklyNewsletterText: "weekly_newsletter",
    monthlyupdate: "email_abo_monthly_update_text",
    productupdate: "product_subscribed_title",
    interestsFieldNameProperty: "real_estate",
    interestsFieldNameAntiqueCar: "classic_cars",
    interestsFieldNameFineWine: "fine_wine",
    interestsFieldNamePrivateEquity: "private_equity",
    interestsFieldNameArt: "art",
    sideModalHeading: "confirm_subscription_to_list",
    sideModalContentLineFirst: "unsubscribed_from_newsletter",
    sideModalContentLineSecond: "manage_your_subscription",
    sideModalContentLineLink: "confrim_the_registration",
    notificationSubHeading: "notification_sub_heading",
    weeklySubTitle: "weekly_sub_title",
    monthlySubTitle: "monthly_sub_title",
    productUpdateSubTitle: "product_update_sub_title",
    productUpdateTitle: "product_update_title",
    nextButtonText: "tipster_form_button_text",
};

const Row = ({ name, id, value, setFieldValue }) => {
    const { formatMessage } = useFormattedMessage();
    const [state, setState] = useState(false);
    const ACCOUNT_NOTIFICATION_TAGS = {
        vip: "VIP News",
        weekly: formatMessage(messages.weeklyNewsletterText),
        monthly: formatMessage(messages.monthlyupdate),
        realEstate: formatMessage(messages.interestsFieldNameProperty),
        art: formatMessage(messages.interestsFieldNameArt),
        fineWine: formatMessage(messages.interestsFieldNameFineWine),
        oldtimer: formatMessage(messages.interestsFieldNameAntiqueCar),
        privateEquity: formatMessage(messages.interestsFieldNamePrivateEquity),
    };

    useEffect(() => {
        setState(value == "active" ? true : false);
    }, [value]);

    return (
        <div className="flex py-4 w-100 text-14">
            <div className="flex column w-70">
                <div className="fw-600 pb-2">{ACCOUNT_NOTIFICATION_TAGS[name] || name}</div>
            </div>
            <div className="flex justify-end w-30 mt-1 h-100">
                <Toggle
                    name={name}
                    className={"toggle-class toggle-item ml-2"}
                    id={id}
                    onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value);
                        setState(e.target.value == "active" ? true : false);
                    }}
                    checked={state}
                    value={state ? "inactive" : "active"}
                    icons={false}
                />
            </div>
        </div>
    );
};

const Table = ({ values, tagArray, setFieldValue }) => {
    return (
        Array.isArray(tagArray) &&
        tagArray.map((topic) => {
            return (
                topic && (
                    <Row
                        key={topic.Tag}
                        id={topic.Tag}
                        name={topic.Tag}
                        value={values[topic.Tag]}
                        setFieldValue={setFieldValue}
                    />
                )
            );
        })
    );
};

const NewsLetterNotification = ({
    SubscriptionsData,
    userData,
    fetchUserSubscriptionsStart,
    fetchUserSubscriptionsSuccess,
    userRoles,
    onCloseButtonClick,
}) => {
    const [tagsList, setTagList] = useState({});
    const { formatMessage } = useFormattedMessage();
    const [isOpenMailchimpModal, setOpenMailchimpModal] = useState(false);
    const [isProductModalOpen, setProductModalOpen] = useState(false);
    const [retData, setRetData] = useState({});
    const [isSubmitting, setSubmitting] = useState(false);
    const defualtTags = [userRoles.vip && { Tag: "vip" }, { Tag: "weekly" }, { Tag: "monthly" }];
    const DEFAULT_NOTIFICATION_TAGS = [...defualtTags];

    useEffect(() => {
        const ret = {};
        Array.isArray(SubscriptionsData.Subscriptions) &&
            SubscriptionsData?.Subscriptions?.map((item) => {
                if (item.ProjectID == "") ret[item.Tag] = item.StatusTag;
            });
        setTagList(ret);
    }, [SubscriptionsData?.Subscriptions]);

    const onChange = () => {
        setSubmitting(true);
        const subscription = SubscriptionsData?.Subscriptions || [];
        const arr = [];
        Object.keys(retData)?.map((Tag) => {
            if (retData[Tag] !== tagsList[Tag]) {
                arr.push({ Tag: Tag, StatusTag: retData[Tag] });
                subscription?.map((obj, index) => {
                    if (Tag === obj.Tag) {
                        subscription[index].StatusTag = retData[Tag];
                    }
                });
            }
        });

        subscribeTag({ tags: arr })
            .then((res) => {
                if (!res || res?.errors || !res?.data || res?.data?.errors || res?.data?.data?.errors) {
                    const error = res?.data?.errors[0]?.message;
                    if (error == "user_not_found_in_mailchimp") {
                        setOpenMailchimpModal(true);
                    } else {
                        console.error(error, "subscribe api error");
                    }
                } else {
                    setRetData({});
                    fetchUserSubscriptionsSuccess(subscription);
                }
                setSubmitting(false);
            })
            .catch((error) => {
                setSubmitting(false);
                console.error(error, "subscribe api error");
            });
    };

    useEffect(() => {
        fetchUserSubscriptionsStart(userData.uid);
    }, [userData.uid]);

    return (
        <Fragment>
            <SideModal
                heading={formatMessage(messages.sideModalHeading)}
                open={isOpenMailchimpModal}
                isBackArrowOnTop
                onBackButtonClick={() => setOpenMailchimpModal(false)}
                onCloseButtonClick={() => {
                    setOpenMailchimpModal(false);
                    onCloseButtonClick();
                }}
            >
                <p>
                    {formatMessage(messages.sideModalContentLineFirst)}
                    <Link className="primary-color" href="http://eepurl.com/gftLVP" isExternal>
                        {formatMessage(messages.sideModalContentLineLink)}{" "}
                    </Link>
                    {formatMessage(messages.sideModalContentLineSecond)}
                </p>
            </SideModal>
            <ProductSubscribedSideModal
                modalOpen={isProductModalOpen}
                onBackButtonClick={() => {
                    setProductModalOpen(false);
                    fetchUserSubscriptionsStart(userData.uid);
                }}
                onCloseButtonClick={() => {
                    setProductModalOpen(false);
                    onCloseButtonClick();
                    fetchUserSubscriptionsStart(userData.uid);
                }}
                refetchUserSubscriptions={() => fetchUserSubscriptionsStart(userData.uid)}
            />
            <div className={`flex column py-3`}>
                <div className="text-16">{formatMessage(messages.notificationSubHeading)}</div>
                <Table
                    tagArray={DEFAULT_NOTIFICATION_TAGS}
                    values={tagsList}
                    setFieldValue={(Tag, value) => {
                        setRetData({ ...retData, [Tag]: value });
                    }}
                />

                <div className="flex py-4 w-100 text-14">
                    <div className="flex column w-70">
                        <div className="fw-600 pb-2">{formatMessage(messages.productUpdateTitle)}</div>
                        <div className="text-12 another-grey-color">{formatMessage(messages.productUpdateSubTitle)}</div>
                    </div>
                    <div className="flex justify-end w-30 pointer" onClick={() => setProductModalOpen(true)}>
                        <div className="text-22 mr-4">
                            <i className="far fa-bell" />
                        </div>
                    </div>
                </div>
                <button
                    className={`mw-100 mt-5 ${isSubmitting || !Object.keys(retData).length ? "disable-grey" : ""}`}
                    onClick={() => onChange()}
                    disabled={isSubmitting || !Object.keys(retData).length}
                >
                    {isSubmitting ? (
                        <>
                            <Loader /> {formatMessage(messages.nextButtonText)}
                        </>
                    ) : (
                        formatMessage(messages.nextButtonText)
                    )}
                </button>
            </div>
        </Fragment>
    );
};

const mapStateToProps = createStructuredSelector({
    SubscriptionsData: selectSubscription,
    userRoles: selectFilterUserRoles,
    userData: selectUserDetails,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserSubscriptionsSuccess: (data) => dispatch(fetchUserSubscriptionsSuccess(data)),
    fetchUserSubscriptionsStart: (userId) => dispatch(fetchUserSubscriptionsStart(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsLetterNotification);
