import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectAdequacy } from "store/user/adequacy/selectors";
import PrevButton from "components/PrevButton";
import NextButton from "components/NextButton";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";
import IntlFormattedMessage from "components/IntlFormattedMessage";

const messages = {
    noteHeading: "adequacy_note",
    noteContent: "thankyou_for_your_infromation",
    importantNoteHeading: "important_note",
    importantNoteContent: "thankyou_for_your_information",
    importantNoteFooter: "understood_the_statement",
};

const ADEQUACY_ANSWER_SHEET = {
    survey_second_step: {
        investment_funds: {
            true: 1,
            3: 1,
            "3-5": 2,
            "5+": 2,
        },
        shares: {
            true: 1,
            3: 2,
            "3-5": 3,
            "5+": 3,
        },
        sharing_rights: {
            true: 1,
            3: 4,
            "3-5": 5,
            "5+": 5,
        },
        subordinated: {
            true: 1,
            3: 4,
            "3-5": 5,
            "5+": 5,
        },
        bonds: {
            true: 10,
            // "3": 2,
            // "3-5": 3,
            // "5+": 3,
            3: 10,
            "3-5": 10,
            "5+": 10,
        },
    },
    survey_third_step: {
        securities: {
            BondsXPAssisted: {
                none: 0,
                3: 1,
                "3-5": 1,
                "5+": 1,
            },
            BondsXPIndependent: {
                none: 0,
                3: 4,
                "3-5": 4,
                "5+": 4,
            },
            BondsXPConsulted: {
                none: 0,
                3: 2,
                "3-5": 2,
                "5+": 2,
            },
        },
        transactions: {
            BondsAmount: {
                "3.000": 3000,
                "3.001-5.000": 5000,
                "5.000-10.000": 10000,
                "10.000+": 100000,
            },
            BondsTransactions: {
                none: 0,
                5: 5,
                10: 10,
                "10+": 100,
            },
        },
    },
};

const survey_second_step = {
    investment_funds: {
        first_question_answer: "IsInvestFundsXPConfirmed",
        second_question_answer: "IsInvestFundsXPConfirmedDate",
    },
    shares: {
        first_question_answer: "IsSharesXPConfirmed",
        second_question_answer: "IsSharesXPConfirmedDate",
    },
    sharing_rights: {
        first_question_answer: "IsProfitPartXPConfirmed",
        second_question_answer: "IsProfitPartXPConfirmedDate",
    },
    subordinated: {
        first_question_answer: "IsSubordLoanXpConfirmed",
        second_question_answer: "IsSubordLoanXpConfirmedDate",
    },
    bonds: {
        first_question_answer: "IsBondsXPConfirmed",
        second_question_answer: "IsBondsXPConfirmedDate",
    },
};

function calculateTestScore(adequacyData, investmentAmount) {
    let first_step_score = 0;
    let second_step_score = 0;
    let third_step_score = 0;

    if (investmentAmount && adequacyData) {
        Object.keys(adequacyData).forEach((item) => {
            if (item === "survey_second_step" && ADEQUACY_ANSWER_SHEET[item] && adequacyData[item]) {
                Object.keys(adequacyData[item]).forEach((child_item) => {
                    if (child_item !== "step") {
                        first_step_score =
                            first_step_score +
                            (ADEQUACY_ANSWER_SHEET[item][child_item][
                                adequacyData[item][child_item][survey_second_step[child_item]["first_question_answer"]]
                            ] || 0);
                        if (adequacyData[item][child_item][survey_second_step[child_item]["second_question_answer"]]) {
                            first_step_score =
                                first_step_score +
                                (ADEQUACY_ANSWER_SHEET[item][child_item][
                                    adequacyData[item][child_item][survey_second_step[child_item]["second_question_answer"]].value
                                ] || 0);
                        }
                    }
                });
            }
            if (item === "survey_third_step" && ADEQUACY_ANSWER_SHEET[item] && adequacyData[item]) {
                Object.keys(adequacyData[item]).forEach((child_item) => {
                    if (child_item !== "step") {
                        if (child_item === "securities") {
                            Object.keys(adequacyData[item][child_item]).forEach((_item) => {
                                if (adequacyData[item][child_item][_item]) {
                                    second_step_score =
                                        second_step_score +
                                        (ADEQUACY_ANSWER_SHEET[item][child_item][_item][
                                            adequacyData[item][child_item][_item].value
                                        ] || 0);
                                }
                            });
                        }

                        if (child_item === "transactions") {
                            if (adequacyData[item][child_item]["BondsAmount"]) {
                                third_step_score =
                                    ADEQUACY_ANSWER_SHEET[item][child_item]["BondsAmount"][
                                        adequacyData[item][child_item]["BondsAmount"].value
                                    ] || 0;
                            }
                            if (adequacyData[item][child_item]["BondsTransactions"]) {
                                third_step_score =
                                    (third_step_score *
                                        (ADEQUACY_ANSWER_SHEET[item][child_item]["BondsTransactions"][
                                            adequacyData[item][child_item]["BondsTransactions"].value
                                        ] || 0)) /
                                    2;
                            }
                        }
                    }
                });
            }
        });
    }

    return {
        first_step: {
            score: first_step_score,
            // isPassed: first_step_score >= 6
            isPassed: first_step_score >= 20,
        },
        second_step: {
            score: second_step_score,
            isPassed: second_step_score >= 4,
        },
        third_step: {
            score: third_step_score,
            isPassed: third_step_score >= investmentAmount,
        },
        // isTestPassed: first_step_score >= 6 && second_step_score >= 4 && third_step_score >= investmentAmount
        isTestPassed: first_step_score >= 20 && second_step_score >= 4 && third_step_score >= investmentAmount,
    };
}

const WarningStep = ({ className, adequacyData, investSum, onPropsContinue, onPropsBack, open, backSlideEffect }) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    const investmentAmount = investSum || 12500;

    const testResult = calculateTestScore(adequacyData, investmentAmount);

    const activeStep = testResult && testResult.isTestPassed ? 1 : 2;

    const adequacyNote = (
        <>
            <div className="adequacy-note multisteps active">
                <div className="header-part">
                    <h3>{formatMessage(messages.noteHeading)}</h3>
                </div>
                <div className="content">
                    <IntlFormattedMessage id={messages.noteContent} />
                </div>
            </div>
            {open && (
                <div className="bottom-bar-container">
                    <PrevButton onClick={onPropsBack} />
                    <NextButton onClick={() => onPropsContinue({ IsXPConfirmed: true, IsMissingXPConfirmed: false })} />
                </div>
            )}
        </>
    );

    const adequacyImportantNote = (
        <>
            <div className="adequacy-important-note multisteps">
                <div className="header-part">
                    <h3>{formatMessage(messages.importantNoteHeading)}</h3>
                </div>
                <div className="content">
                    <IntlFormattedMessage id={messages.importantNoteContent} />
                    <span className="text-16 fw-600">
                        <IntlFormattedMessage id={messages.importantNoteFooter} />
                    </span>
                </div>
            </div>
            {open && (
                <div className="bottom-bar-container">
                    <PrevButton onClick={onPropsBack} />
                    <NextButton onClick={() => onPropsContinue({ IsXPConfirmed: false, IsMissingXPConfirmed: true })} />
                </div>
            )}
        </>
    );

    function renderStep(step) {
        switch (step) {
            case 1:
                return adequacyNote;
            case 2:
                return adequacyImportantNote;
            default:
                return adequacyNote;
        }
    }

    return <div className={`${className} adequacy-container eighth-step ${backSlideEffect}`}>{renderStep(activeStep)}</div>;
};

const mapStateToProps = createStructuredSelector({
    adequacyData: selectAdequacy,
});

export default connect(mapStateToProps)(WarningStep);
