import React from "react";
import Select, { components } from "react-select";
import Checkbox from "components/Checkbox";

const Option = (props) => {
    const title = typeof props.label === "object" ? props.data.title : props.label;
    return (
        <div>
            <components.Option {...props}>
                <div style={{ position: "absolute", width: "100%", height: "100%", top: 0, left: 0 }} title={title} />
                <Checkbox
                    inputId={title}
                    size="small"
                    value={props.isSelected}
                    label={props.label}
                    labelClassName={`text-14 ${props.data.isMultiLine ? "" : "single-line-label"}`}
                />
            </components.Option>
        </div>
    );
};

const MultiValue = (props) => {
    const title = typeof props.data.label === "object" ? props.data.title || props.data.value : props.data.label;
    return (
        <components.MultiValue {...props}>
            <span title={title}>{props.data.customSelectedLabel || props.data.label}</span>
        </components.MultiValue>
    );
};

const orderOptions = (values) => {
    return values.filter((v) => v.isFixed).concat(values.filter((v) => !v.isFixed));
};

const styles = {
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: "none" } : base;
    },
};

const SelectInput = ({
    selectClassName = "select",
    classNamePrefix = "select",
    className = "",
    errorMessage = "",
    success = false,
    currency = "",
    errorIcon = true,
    error,
    menuPlacement = "bottom",
    maxMenuHeight,
    isSearchable,
    label,
    errorClassName = "error-element",
    id = "select-input-instanceId",
    isMultiSelectWithCheckbox = false,
    hideSelectedOptions = true,
    innerRef,
    options,
    onChange = () => {},
    isOptionWithFixedValue = false,
    showAllSelectedItems = false,
    ...rest
}) => {
    const onChangeSelect = (value, actionMeta) => {
        switch (actionMeta.action) {
            case "remove-value":
            case "pop-value":
                if (actionMeta.removedValue.isFixed) {
                    return;
                }
                break;
            case "clear":
                value = options.filter((v) => v.isFixed);
                break;
        }

        value = orderOptions(value);
        onChange(value);
    };
    return (
        <div
            className={`form-element ${showAllSelectedItems ? "multiline-select" : ""} ${className} ${
                error && errorMessage && `${errorClassName}`
            } ${isMultiSelectWithCheckbox ? "custom-select-checkbox" : ""} ${
                selectClassName == "small-menu-list" ? "custom-small-select" : ""
            }`}
        >
            {label && <label className="mb-2 d-inline-block">{label}</label>}
            <div className="position-relative">
                <Select
                    {...rest}
                    ref={innerRef}
                    options={options || []}
                    menuPlacement={menuPlacement}
                    maxMenuHeight={maxMenuHeight}
                    isSearchable={isSearchable}
                    className={`${selectClassName === "select" ? "select" : `select ${selectClassName}`}`}
                    classNamePrefix={classNamePrefix}
                    instanceId={id}
                    closeMenuOnSelect={isMultiSelectWithCheckbox ? false : true}
                    components={isMultiSelectWithCheckbox ? { Option, MultiValue } : ""}
                    hideSelectedOptions={isMultiSelectWithCheckbox ? false : hideSelectedOptions}
                    backspaceRemovesValue={isMultiSelectWithCheckbox ? false : true}
                    onChange={isOptionWithFixedValue ? onChangeSelect : onChange}
                    styles={isOptionWithFixedValue ? styles : {}}
                />
                {currency && <span className="currency">{currency}</span>}
                {success && !errorMessage && <i className="fas fa-check" />}
                {error && (
                    <>
                        {errorMessage && errorIcon && <i className={`fas fa-exclamation-triangle ${label && " with-label"}`} />}
                        {errorMessage && <label className="error-label text-left">{errorMessage}</label>}
                    </>
                )}
            </div>
        </div>
    );
};

export default SelectInput;
