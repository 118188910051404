module.exports = {
    JOBS_URL: "https://finexity-ag.jobs.personio.de/",
    AMBASSADOR_EMAIL_URL: "ambassador@finexity.com",
    AMBASSADOR_ONLINE_URL: "https://jobs.finexity.com/o/student-brand-ambassador",
    IOS: "iOS",
    ANDROID: "Android",
    APP_STORE_LINK: "https://apps.apple.com/us/app/finexity/id1464876346",
    PLAY_STORE_LINK: "https://play.google.com/store/apps/details?id=com.finexity.androidapp",
    APPOINTMENT_URL:
        "https://app.prosperworks.com/public/meeting-scheduler/FINEXITY%20AG/support/324836:edfe23a8-6537-4f50-9c07-4f881ff8dc86",
    FINEXITY_SUPPORT_EMAIL: "support@finexity.com",
    SYSTEM_EMAIL: "system.blockchain@finexity.com",
    ALLOWED_EMAILS: [
        "nitesh.gour@encoresky.com",
        "tim.janssen@finexity.com",
        "paul@huelsmann-capital.de",
        "henning.wagner@finexity.com",
        "tim.janssen@simby.de",
        "finexity@mariawagner.eu",
        "janina.04.05@web.de",
        "ah@huelsmann-gruppe.de",
        "revenco.karina@yahoo.com",
        "vishal.chaturvedi@encoresky.com",
    ],
    MANGOPAY_TERMS_EN: "https://www.mangopay.com/terms/PSP/PSP_MANGOPAY_EN.pdf",
    MANGOPAY_TERMS_DE: "https://www.mangopay.com/terms/PSP/PSP_MANGOPAY_DE.pdf",
    ARTPRICE_LINK: "https://www.artprice.com/",
    WEBFLOW_BASE_URL: "https://beta.finexity.com",
    contactNumberLink: "+494082217720",
    contactNumberText: "+49 40 822 177 20",
    finexityHeathcareContactNumberLink: "+494087406115",
    finexityHeathcareContactNumberText: "+49 40 87406115",
    winvestaPrivatePlacement: "https://www.winvesta.de/private-placement/",
};
