import React, { useMemo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { setAdequacyStart } from "store/user/adequacy/actions";
import { userDashboardDetailsSuccess } from "store/user/account/actions";
import SideModal from "components/SideModal";
import IntroductionStep from "./IntroductionStep";
import CancelStep from "./CancelStep";
import OverviewStep from "./OverviewStep";
import JobEducationStep from "./JobEducationStep";
import FinancialInvestmentsListStep from "./FinancialInvestmentsListStep";
import FinancialInvestmentsStep from "./FinancialInvestmentsStep";
import SecurityServicesStep from "./SecurityServicesStep";
import WarningStep from "./WarningStep";
import ThankYouStep from "./ThankYouStep";
import { useFormattedMessage } from "hooks";

const messages = {
    adequacyMainHeading: "adequacy_check",
};

export const RenderAdequacyStep = ({
    step,
    investSum,
    onCloseButtonClick,
    onBackButtonClick,
    adequacyBack,
    setAdequacyBack,
    setInvestmentStepData,
    isWithInvestment,
    userDashboardDetailsSuccess,
    setAdequacyAtStep1,
    adequacyCompleted,
    kycCompleted,
    setAdequacyStart,
    isWithSecondaryMarket,
    onAdequacyBack,
    isWithOrder,
    isWithSetting,
    onWarningNext,
    open,
    StatusAdequacy,
    previousStep,
}) => {
    const [backSlideEffect, setBackSlideEffect] = useState("");
    const [changeStepBack, setChangeStepBack] = useState(false);
    step =
        (step === 9 && (isWithInvestment || isWithOrder)) ||
        (adequacyCompleted && step === 1 && (isWithInvestment || isWithOrder))
            ? 3
            : step;

    const hitButtonBack = () => {
        setChangeStepBack(false);
        setAdequacyBack();
    };

    useEffect(() => {
        if (adequacyBack) {
            if (step === 1) {
                if (isWithInvestment) {
                    setChangeStepBack(true);
                } else {
                    hitButtonBack();
                    setAdequacyAtStep1();
                }
            } else if (step === 2) {
                hitButtonBack();
                setAdequacyStart({ step: 1 });
            } else if (step === 3) {
                hitButtonBack();
                if (setInvestmentStepData && setAdequacyStart) {
                    adequacyCompleted && !kycCompleted && setInvestmentStepData({ investmentData: { step: 1 } });
                    adequacyCompleted && kycCompleted && setInvestmentStepData({ step: 2 });
                } else {
                    setAdequacyStart && setAdequacyStart({ step: 7 });
                }
            } else if (step === 5) {
                hitButtonBack();
                setAdequacyStart && setAdequacyStart({ step: 4 });
            } else if (step === 8) {
                hitButtonBack();
                setAdequacyStart && setAdequacyStart({ step: 3 });
            } else {
                setChangeStepBack(true);
            }
        }
    }, [adequacyBack]);

    function renderStep(step) {
        switch (step) {
            case 1:
                return (
                    <IntroductionStep
                        open={open}
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        isRequiredBackButton={isWithInvestment || isWithOrder || isWithSecondaryMarket}
                        isRequiredSkipButton={isWithInvestment || isWithOrder}
                        changeStepBack={changeStepBack}
                        isWithSetting={isWithSetting}
                        hitButtonBack={hitButtonBack}
                        onBack={(userDashboardData) => {
                            setBackSlideEffect("step-slide-right");
                            if (isWithOrder || isWithSecondaryMarket) {
                                onAdequacyBack && onAdequacyBack();
                            } else {
                                if (setInvestmentStepData && kycCompleted) {
                                    setInvestmentStepData({ investmentData: { step: 2 } });
                                    adequacyCompleted &&
                                        userDashboardDetailsSuccess &&
                                        userDashboardDetailsSuccess({ ...userDashboardData, StatusAdequacy: "hide" });
                                    setInvestmentStepData({ step: 2 });
                                } else {
                                    setInvestmentStepData({ investmentData: { step: 1 } });
                                }
                            }
                        }}
                        onSkip={() => {
                            setBackSlideEffect("step-slide-left");
                            setInvestmentStepData && setInvestmentStepData({ wantCancelProcess: true });
                        }}
                        returnOverview={(userDashboardData) => {
                            userDashboardDetailsSuccess &&
                                userDashboardDetailsSuccess({ ...userDashboardData, StatusAdequacy: "hide" });
                            onBackButtonClick && onCloseButtonClick();
                        }}
                    />
                );
            case 2:
                return (
                    <CancelStep
                        StatusAdequacy={StatusAdequacy}
                        open={open}
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        isWithInvestment={isWithInvestment}
                        isWithOrder={isWithOrder}
                        isWithSetting={isWithSetting}
                        isWithSecondaryMarket={isWithSecondaryMarket}
                        isRequiredSkipButton={isWithInvestment || isWithOrder}
                        onCloseButtonClick={onCloseButtonClick}
                        onBackButtonClick={onBackButtonClick}
                        previousStep={previousStep}
                        onSkip={() => {
                            setBackSlideEffect("step-slide-left");
                            setInvestmentStepData({ wantCancelProcess: true });
                        }}
                        onNotWantedSurveyProps={() => {
                            if ((isWithInvestment || isWithOrder) && setAdequacyStart) {
                                setAdequacyStart({ step: 8 });
                            }
                        }}
                    />
                );
            case 3:
                return (
                    <OverviewStep
                        open={open}
                        backSlideEffect={backSlideEffect}
                        onPropsSubmit={() => {
                            setBackSlideEffect("step-slide-left");
                            if ((isWithInvestment || isWithOrder) && setAdequacyStart) {
                                setAdequacyStart({ step: 8 });
                            }
                        }}
                        onPropsBack={() => {
                            setBackSlideEffect("step-slide-right");
                            if (isWithInvestment && setInvestmentStepData && setAdequacyStart) {
                                adequacyCompleted && !kycCompleted && setInvestmentStepData({ investmentData: { step: 1 } });
                                adequacyCompleted && kycCompleted && setInvestmentStepData({ step: 2 });
                            } else if (isWithOrder) {
                                onAdequacyBack && onAdequacyBack();
                            } else {
                                setAdequacyStart && setAdequacyStart({ step: 7 });
                            }
                        }}
                        onRenew={(userDashboardData, adequacyData) => {
                            setBackSlideEffect("step-slide-right");
                            setAdequacyStart &&
                                setAdequacyStart({
                                    step: 1,
                                    survey_second_step: {
                                        ...adequacyData.survey_second_step,
                                        step: 1,
                                    },
                                    survey_third_step: {
                                        ...adequacyData.survey_third_step,
                                        step: 1,
                                    },
                                });
                            userDashboardDetailsSuccess &&
                                userDashboardDetailsSuccess({ ...userDashboardData, StatusAdequacy: "renew" });
                        }}
                    />
                );
            case 4:
                return (
                    <JobEducationStep
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        labelIDPrefix={
                            isWithInvestment ? "investment" : isWithOrder ? "order" : isWithSecondaryMarket ? "sm" : ""
                        }
                        changeStepBack={changeStepBack}
                        hitButtonBack={hitButtonBack}
                        open={open}
                        StatusAdequacy={StatusAdequacy}
                    />
                );
            case 5:
                return (
                    <FinancialInvestmentsListStep
                        open={open}
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        StatusAdequacy={StatusAdequacy}
                    />
                );
            case 6:
                return (
                    <FinancialInvestmentsStep
                        changeStepBack={changeStepBack}
                        hitButtonBack={hitButtonBack}
                        open={open}
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        StatusAdequacy={StatusAdequacy}
                    />
                );
            case 7:
                return (
                    <SecurityServicesStep
                        open={open}
                        backSlideEffect={backSlideEffect}
                        setBackSlideEffect={setBackSlideEffect}
                        isWithSecondaryMarket={isWithSecondaryMarket}
                        changeStepBack={changeStepBack}
                        hitButtonBack={hitButtonBack}
                        onPropsSubmit={(nextUserDashboardData) => {
                            if ((isWithInvestment || isWithOrder) && setAdequacyStart) {
                                setAdequacyStart({ step: 8 });
                                nextUserDashboardData &&
                                    userDashboardDetailsSuccess &&
                                    userDashboardDetailsSuccess(nextUserDashboardData);
                            }
                        }}
                        StatusAdequacy={StatusAdequacy}
                    />
                );
            case 8:
                return (
                    <WarningStep
                        open={open}
                        backSlideEffect={backSlideEffect}
                        investSum={investSum && (isWithInvestment ? investSum.sum : isWithOrder ? investSum : null)}
                        onPropsContinue={(data) => {
                            setBackSlideEffect("step-slide-left");
                            if (isWithInvestment && setInvestmentStepData) {
                                setInvestmentStepData({ investmentData: { step: 3, ...data } });
                            } else if (isWithOrder) {
                                onWarningNext && onWarningNext();
                            } else {
                                setAdequacyStart &&
                                    setAdequacyStart({
                                        isAccepted: true,
                                        step: 3,
                                        submitted_date: new Date(),
                                    });
                            }
                        }}
                        onPropsBack={() => {
                            setBackSlideEffect("step-slide-right");
                            setAdequacyStart && setAdequacyStart({ step: 3 });
                        }}
                    />
                );
            case 9:
                return (
                    <ThankYouStep
                        open={open}
                        backSlideEffect={backSlideEffect}
                        hitButtonBack={hitButtonBack}
                        setAdequacyAtStep1={setAdequacyAtStep1}
                        changeStepBack={changeStepBack}
                        onBackButtonClick={() => {
                            onBackButtonClick();
                            setAdequacyStart && setAdequacyStart({ step: 1 });
                        }}
                        onCloseButtonClick={onCloseButtonClick}
                    />
                );
            default:
                return (
                    <IntroductionStep
                        open={open}
                        isRequiredBackButton={isWithInvestment || isWithOrder}
                        isRequiredSkipButton={isWithInvestment || isWithOrder}
                        onBack={() => {
                            if (setInvestmentStepData && isWithInvestment && kycCompleted) {
                                setInvestmentStepData({ step: 1 });
                            } else if (isWithOrder && kycCompleted) {
                                onAdequacyBack && onAdequacyBack();
                            } else {
                                setInvestmentStepData({ investmentData: { step: 1 } });
                            }
                        }}
                        onSkip={() => {
                            setInvestmentStepData && setInvestmentStepData({ wantCancelProcess: true });
                        }}
                    />
                );
        }
    }
    return renderStep(step);
};

const AdequacyModal = ({
    open,
    onCloseButtonClick,
    onBackButtonClick,
    setAdequacySuccessFlag,
    step = 1,
    setAdequacyStart,
    adequacyBack,
    setAdequacyBack,
    setAdequacyAtStep1,
    userDashboardDetailsStart,
    userDashboardDetailsSuccess,
    isWithSetting,
    StatusAdequacy,
}) => {
    const { formatMessage } = useFormattedMessage();
    const [previousStep, setPreviousStep] = useState({ step: 1 });

    useEffect(() => {
        const escFunction = (event) => {
            if (event.key === "Escape") {
                onCloseButtonClick();
            }
        };
        window.addEventListener("keydown", escFunction);
        return () => window.removeEventListener("keydown", escFunction, false);
    }, []);

    const memoizedRenderAdequacyStep = useMemo(() => {
        return (
            <RenderAdequacyStep
                open={open}
                adequacyBack={adequacyBack}
                setAdequacyBack={setAdequacyBack}
                setAdequacyAtStep1={setAdequacyAtStep1}
                onCloseButtonClick={StatusAdequacy == "renew" ? onBackButtonClick : onCloseButtonClick}
                setAdequacyStart={setAdequacyStart}
                step={step}
                userDashboardDetailsRequest={userDashboardDetailsStart}
                userDashboardDetailsSuccess={userDashboardDetailsSuccess}
                onBackButtonClick={onBackButtonClick}
                isWithSetting={isWithSetting}
                StatusAdequacy={StatusAdequacy}
                previousStep={previousStep}
            />
        );
    }, [step, adequacyBack, open]);

    return (
        <SideModal
            className="adequacy-modal"
            heading={formatMessage(messages.adequacyMainHeading)}
            open={open}
            isBackArrowOnTop={step === 1}
            onBackButtonClick={onBackButtonClick}
            onCloseButtonClick={() => {
                if (StatusAdequacy == "renew" && step != 9) {
                    setAdequacyStart({ step: 2 });
                    setPreviousStep({ step });
                } else {
                    onCloseButtonClick();
                    if (step === 9) {
                        setAdequacySuccessFlag && setAdequacySuccessFlag();
                        setAdequacyStart({ step: 1 });
                        setTimeout(() => {
                            userDashboardDetailsStart();
                        }, 5000);
                    }
                }
            }}
        >
            {memoizedRenderAdequacyStep}
        </SideModal>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setAdequacyStart: (data) => dispatch(setAdequacyStart(data)),
    userDashboardDetailsSuccess: (data) => dispatch(userDashboardDetailsSuccess(data)),
});

export default connect(null, mapDispatchToProps)(AdequacyModal);
