const admin_routes = [
    "admin",
    "/admin",
    "/admin/investments",
    "/admin/investments/[id]",
    "/admin/payouts",
    "/admin/payments",
    "/admin/users",
    "/admin/users/[id]",
    "/admin/users/create",
    "/admin/incomingcall/[phoneNumber]",
    "/admin/products",
    "/admin/blockchain",
    "/admin/broker",
    "/admin/miscellaneous",
    "/admin/testing-suite",
    "/admin/performance",
    "/admin/orders",
    "/admin/tenants",
    "/admin/issuers",
    "/admin/secondary-market",
    "/admin/documents",
    "/admin/notification",
];

const broker_routes = [
    "/broker/personal-data",
    "/broker/dashboard",
    "/broker/onboarding",
    "/broker/provision",
    "/broker/information",
    "/broker/marketing-materials",
    // "/broker/support",
    "/broker",
];

const clubdeals_route = ["/club-deals", "/club-deals/onboarding"];

const issuers_routes = [
    "issuer-manager",
    "/issuer-manager/investments",
    "/issuer-manager/products",
    "/issuer-manager/issuers",
    "/issuer-manager/users",
];

const tenant_routes = ["tenant", "/tenant/product-catalog", "/tenant/users", "/tenant/investments", "/tenant/setting"];

const umbrella_routes = ["/umbrella", "/umbrella/investments", "/umbrella/users", "/umbrella/setting"];

const private_routes = [
    "/dashboard",
    "/email-confirmation",
    "/user-onboarding",
    "/retail/accept-terms-conditions",

    // Account Routes
    "/account",
    "/account/profile",
    "/account/documents",
    "/account/referral",
    "/account/change-password",
    "/account/notification",
    "/account/tax",
    "/account/information",

    // Admin Routes
    ...admin_routes,

    // Broker Routes
    ...broker_routes,

    // ClubDeal Routes
    ...clubdeals_route,

    // Tenant Routes
    ...tenant_routes,

    // Issuer Routes
    ...issuers_routes,

    // Umbrella Routes
    ...umbrella_routes,
];

const tenant_allowed_routes = [
    "/favicon.ico",
    "/404",
    "/register",
    "/login",
    "/token",
    "/robots.txt",
    "/sitemap.xml",
    "/unsubscribe",
    "/unsubscribe-email",
    "/unsubscribe-fail",
    "/unsubscribe-success",
    "/forget-password",
    "/password-remind",
    "/password-remind-info",
    "/user-onboarding",
    "/provide-email",
    "/retail/accept-terms-conditions",
    "/email-confirmation",
    "/user-deleted",
    "/confirm",
    "/confirm-success",
    "/confirm-fail",
    "/dashboard",
    "/account",
    "/marketplace",
    "/marketplace/[id]",
    "/marketplace/",
    "/si-vermoegen1",
    "/si-vermoegen2",
    "/zemitz-solar",
    "/proptech-palma-01",
    "/watchvest-asset",
    "/BC277",
    "/winvesta-prop-01",
    "/winvesta-green-prop",
    "/club-deals",
    "/promocion-nova-one",
    "/aurora-one",
    "/delete-account-request",
    "/delete-account-request/[token]",

    // Tenant Allowed broker routes
    "/broker/personal-data",
    "/broker/dashboard",
    "/broker/provision",
    "/broker/information",
    "/broker/marketing-materials",
    "/broker",

    ...tenant_routes,
    ...issuers_routes,
];

const allowed_mobile_routes = [
    "/login",
    "/dashboard",
    "/marketplace",
    "/marketplace/[id]",
    "/marketplace/*",
    "/club-deals",
    "/club-deals/onboarding",
    "/account",
    "/imprint",
    "/policy",
    "/register",
    "/email-confirmation",
    "/user-onboarding",
    "/retail/accept-terms-conditions",
    "/404",
    "/confirm",
    "/confirm-success",
    "/confirm-fail",
    "/reserve/share",
    "/reserve/fail",
    "/reserve/success",
    "/reserve/outdated",
    "/user-deleted",
];

const blocked_prod_routes = ["/neupe-01", "/neupe-02", "/issuer/"];

const deleted_paths = ["/broker/support", "/broker/documents", "/broker/performance", "/broker/company-data", "/broker/customer"];

const prevent_routes_with_token = [
    "/register",
    "/login",
    "/token",
    "/password-remind",
    "/delete-account-request",
    "/delete-account-request/[token]",
];

const web_flow_routes = {
    MARKETPLACE: "/marketplace",
    CLUB_DEAL: "/program/club-deal",
    PARTNER_PROGRAM: "/program/partner",
    AMBASSADOR_PROGRAM: "/program/ambassador",
    PLATFORM_PROGRAM: "https://www.fnxt.io", // OLD link "/program/platform",
    PRIVACY_POLICY: "/policy",
    IMPRINT: "/imprint",
    REAL_ESTATE: "/wealth/real-estate",
    ART: "/wealth/art",
    FINE_WINE: "/wealth/fine-wine",
    CLASSIC_CARS: "/wealth/classic-cars",
    DIAMONDS: "/wealth/diamonds",
    WATCHES: "/wealth/watches",
    STRING_INSTRUMENTS: "/wealth/string-instruments",
    PRIVATE_EQUITY: "/wealth/private-equity",
    OUT_INVESTMENT_PHILOSOPHY: "/wealth/our-investment-philosophy",
    DUE_DILIGENCE: "/wealth/due-diligence",
    ABOUT: "/company/about",
    OUR_TEAM: "/company/our-team",
    PRESS: "/company/press",
    CONTACT: "/company/contact",
    KNOWLEDGE_BASE: "/knowledge",
    BLOG: "/knowledge/blog",
    FAQS: "/knowledge/faqs",
    GLOSSARY: "/knowledge/glossary",
    GUIDE: "/knowledge/guide",
    HEALTHCARE_INVESTMENT: "https://finexity-healthcare.com/digitales-vermogen",
    ABOUT_US: "https://finexity-healthcare.com/about-us",
    FAQ: "https://finexity-healthcare.com/faq",
};

const tab_bar_blocked_path = [
    "/login",
    "/imprint",
    "/policy",
    "/register",
    "/email-confirmation",
    "/user-onboarding",
    "/retail/accept-terms-conditions",
    "/confirm-success",
    "/confirm-fail",
    "/reserve/share",
    "/reserve/fail",
    "/reserve/success",
    "/reserve/outdated",
    "/user-deleted",
];

const paths_with_dashboard_header = [
    "/dashboard",

    "/club-deals",
    "/club-deals/onboarding",

    "/marketplace",
    "/marketplace/[id]",

    "/admin/products",
    "/admin/broker",
    "/admin/users",
    "/admin/users/[id]",
    "/admin/users/create",
    "/admin/incomingcall/[phoneNumber]",
    "/admin/testing-suite",
    "/admin/performance",
    "/admin/secondary-market",
    "/admin/orders",
    "/admin/investments",
    "/admin/payouts",
    "/admin/investments/[id]",
    "/admin/issuers",
    "/admin/payments",
    "/admin/blockchain",
    "/admin/miscellaneous",
    "/admin/tenants",
    "/admin/documents",
    "/admin/notification",

    "/tenant",
    "/tenant/product-catalog",
    "/tenant/users",
    "/tenant/investments",
    "/tenant/setting",

    "/issuer-manager",
    "/issuer-manager/products",
    "/issuer-manager/issuers",
    "/issuer-manager/investments",
    "/issuer-manager/users",

    "/broker/performance",
    "/broker/personal-data",
    "/broker/dashboard",
    "/broker/onboarding",
    "/broker/provision",
    "/broker/information",
    "/broker/support",
    "/broker/customer",
    "/broker/marketing-materials",
    "/broker/company-data",
    "/broker/documents",

    "/umbrella",
    "/umbrella/users",
    "/umbrella/investments",
    "/umbrella/settings",
];

const paths_without_footer = ["/token", "/confirm", "/register", "/password-remind-info", "/user-onboarding"];

const paths_without_default_og_image = ["/marketplace/[id]"];

module.exports = {
    admin_routes,
    broker_routes,
    issuers_routes,
    tenant_routes,
    umbrella_routes,
    private_routes,
    blocked_prod_routes,
    deleted_paths,
    prevent_routes_with_token,
    web_flow_routes,
    clubdeals_route,
    tenant_allowed_routes,
    allowed_mobile_routes,
    tab_bar_blocked_path,
    tenant_routes,
    paths_with_dashboard_header,
    paths_without_footer,
    paths_without_default_og_image,
};
