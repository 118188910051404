import { combineReducers } from "redux";

import accountReducer from "./user/account/reducer";
import appReducer from "./app/reducer";
import investmentReducer from "./user/investment/reducer";
import dashboardReducer from "./user/dashboard/reducer";
import projectReducer from "./project/reducer";
import adequacyReducer from "./user/adequacy/reducer";
import adminReducer from "./admin/reducer";
import messageReducer from "./user/message/reducer";

const rootReducer = combineReducers({
    user: accountReducer,
    app: appReducer,
    investment: investmentReducer,
    dashboard: dashboardReducer,
    project: projectReducer,
    adequacy: adequacyReducer,
    admin: adminReducer,
    message: messageReducer,
});

export { rootReducer };
