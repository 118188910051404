import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { formatMobileNumber } from "constants/index";
import { MOBILE_STATUS } from "constants/status";
import {
    selectTaxData,
    selectKycShow,
    selectTypeMobilePhonelDetails,
    selectUserMangopay,
    selectUserAdditionalData,
} from "store/user/account/selectors";
import { selectLocale } from "store/app/selectors";
import { useFormattedMessage } from "hooks";
import { getGenders } from "utils";

const messages = {
    verifyNumber: "verify",
    addNumber: "form_add",
    changeData: "change_data",
};

const WrappedProfileView = (ComposedComponent) => {
    const Component = (props) => {
        const { userAdditionalData, mobilePhoneDetails, locale } = props;

        const [userData, setUserData] = React.useState({});
        const { formatMessage } = useFormattedMessage();
        const genders = getGenders(formatMessage);

        React.useEffect(() => {
            setUserData({
                ...userAdditionalData.personal,
                ...userAdditionalData.identification,
                ...userAdditionalData.tile,
                TypeGender:
                    (userAdditionalData.personal.TypeGender &&
                        genders &&
                        genders.find((item) => userAdditionalData.personal.TypeGender === item.value)) ||
                    {},
            });
        }, [userAdditionalData]);

        const verifiedMobileNumber = React.useMemo(() => {
            return formatMobileNumber(mobilePhoneDetails.mobilePhone);
        }, [mobilePhoneDetails]);

        const nonVerifiedMobileNumber = React.useMemo(() => {
            return formatMobileNumber(mobilePhoneDetails.nonVerifiedMobilePhone);
        }, [mobilePhoneDetails]);

        const isMobileDataChange = React.useMemo(() => {
            return (
                (verifiedMobileNumber && mobilePhoneDetails.StatusTwoFactor === MOBILE_STATUS.SUCCESSFULLY_COMPLETED) ||
                !nonVerifiedMobileNumber
            );
        }, [mobilePhoneDetails]);

        const mobileSectionRightTitle = React.useMemo(() => {
            return verifiedMobileNumber
                ? mobilePhoneDetails.StatusTwoFactor === MOBILE_STATUS.SUCCESSFULLY_COMPLETED
                    ? formatMessage(messages.changeData)
                    : nonVerifiedMobileNumber
                    ? formatMessage(messages.verifyNumber)
                    : formatMessage(messages.changeData)
                : nonVerifiedMobileNumber
                ? formatMessage(messages.verifyNumber)
                : formatMessage(messages.addNumber);
        }, [locale, mobilePhoneDetails]);

        const userPhoneData = { verifiedMobileNumber, nonVerifiedMobileNumber, isMobileDataChange, mobileSectionRightTitle };

        return <ComposedComponent userPhoneData={userPhoneData} userData={userData} {...props} />;
    };

    const mapStateToProps = createStructuredSelector({
        kycShow: selectKycShow,
        mobilePhoneDetails: selectTypeMobilePhonelDetails,
        taxData: selectTaxData,
        userMangopayDetails: selectUserMangopay,
        userAdditionalData: selectUserAdditionalData,
        locale: selectLocale,
    });

    return connect(mapStateToProps)(Component);
};

export default WrappedProfileView;
