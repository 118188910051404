import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { setInvestmentStepData } from "store/user/investment/actions";
import { setUserDetailsStart } from "store/user/account/actions";
import { selectUserDetails, selectUserDashboardDetails, selectUserAdditionalData } from "store/user/account/selectors";
const pick = require("lodash/pick");
import { HIDE_KYC_TILE_STATUS_STEP, USER_KYC_DETAILS_INPUT } from "constants/index";
import SideModal from "components/SideModal";
import PersonalData from "./PersonalData";
import IdentificationData from "./IdentificationData";
import KycProvider from "./KycProvider";
import PostidentPdfInstructions from "./PostidentPdfInstructions";
import KycInfo from "./KycInfo";
import { useFormattedMessage } from "hooks";

const messages = {
    kycMainHeading: "your_identification",
};

export const RenderKycStep = ({
    step,
    userAdditionalData,
    setStep,
    setInvestmentStepData,
    setUserDetailsStart,
    kycCompleted,
    onCloseButtonClick,
    isWithSecondaryMarket,
    onPersonalBack,
    open,
}) => {
    const [backSlideEffect, setBackSlideEffect] = useState("");

    useEffect(() => {
        kycCompleted && setBackSlideEffect("step-slide-left");
    }, [kycCompleted]);

    function renderStep(step) {
        const userPersonalData = userAdditionalData && userAdditionalData.personal;

        if (
            !(
                userPersonalData.TypeGender === "female" ||
                userPersonalData.TypeGender === "male" ||
                userPersonalData.TypeGender === "divers"
            )
        ) {
            userPersonalData.TypeGender = "";
        }
        const identificationData =
            userAdditionalData &&
            userAdditionalData.identification &&
            Object.keys(userAdditionalData.identification).length &&
            userAdditionalData.identification;
        switch (step) {
            case 1:
                return (
                    <PersonalData
                        open={open}
                        isWithSecondaryMarket={isWithSecondaryMarket}
                        isRequiredBackButton={isWithSecondaryMarket}
                        backSlideEffect={backSlideEffect}
                        onBack={() => {
                            if (isWithSecondaryMarket) {
                                onPersonalBack();
                                setBackSlideEffect("step-slide-right");
                            }
                        }}
                        data={userPersonalData}
                        onSubmit={(values) => {
                            setStep && setStep(2);
                            setInvestmentStepData && setInvestmentStepData({ userData: { personalData: values } });
                            setBackSlideEffect("step-slide-left");
                        }}
                    />
                );
            case 2:
                return (
                    <IdentificationData
                        open={open}
                        onCloseButtonClick={onCloseButtonClick}
                        backSlideEffect={backSlideEffect}
                        onBack={() => {
                            if (setStep) {
                                setStep(1);
                                setBackSlideEffect("step-slide-right");
                            }
                        }}
                        data={identificationData}
                        onSubmit={(values) => {
                            setInvestmentStepData && setInvestmentStepData({ userData: { identificationData: values } });
                            const userDetails = { ...userAdditionalData, identification: values };
                            setUserDetailsStart(userDetails);
                        }}
                    />
                );
            case 3:
                return (
                    <KycProvider
                        open={open}
                        isRequiredBackButton
                        externalUrl={userAdditionalData?.IDnowRedirectURL}
                        onExternalLinkClick={() => {
                            setStep && setStep(5);
                            setBackSlideEffect("step-slide-left");
                        }}
                        backSlideEffect={backSlideEffect}
                        onBack={() => {
                            if (setStep) {
                                setStep(2);
                                setBackSlideEffect("step-slide-right");
                            }
                        }}
                        onPostidentClick={() => {
                            setStep && setStep(4);
                            setBackSlideEffect("step-slide-left");
                        }}
                    />
                );
            case 4:
                return (
                    <PostidentPdfInstructions
                        open={open}
                        backSlideEffect={backSlideEffect}
                        onBack={() => {
                            if (setStep) {
                                setStep(3);
                                setBackSlideEffect("step-slide-right");
                            }
                        }}
                        onDownloadClick={() => {
                            setStep && setStep(5);
                            setBackSlideEffect("step-slide-left");
                        }}
                    />
                );
            case 5:
                return (
                    <KycInfo
                        open={open}
                        onCloseButtonClick={onCloseButtonClick}
                        backSlideEffect={backSlideEffect}
                        // eslint-disable-next-line sonarjs/no-identical-functions
                        onBack={() => {
                            if (setStep) {
                                setStep(3);
                                setBackSlideEffect("step-slide-right");
                            }
                        }}
                    />
                );
            // eslint-disable-next-line sonarjs/no-duplicated-branches
            default:
                return (
                    <PersonalData
                        open={open}
                        isRequiredBackButton={isWithSecondaryMarket}
                        backSlideEffect={backSlideEffect}
                        // eslint-disable-next-line sonarjs/no-identical-functions
                        onBack={() => {
                            if (isWithSecondaryMarket) {
                                onPersonalBack();
                                setBackSlideEffect("step-slide-right");
                            }
                        }}
                        data={userPersonalData}
                        // eslint-disable-next-line sonarjs/no-identical-functions
                        onSubmit={(values) => {
                            setStep && setStep(2);
                            setInvestmentStepData && setInvestmentStepData({ userData: { personalData: values } });
                            setBackSlideEffect("step-slide-left");
                        }}
                    />
                );
        }
    }
    return renderStep(step);
};

const KycModal = ({
    open,
    onCloseButtonClick,
    onBackButtonClick,
    isCloseArrowOnRight,
    userAdditionalData,
    setKycAtStep1,
    setInvestmentStepData,
    setUserDetailsStart,
    userDashboardDetails,
    activeStep,
    setActiveStep,
}) => {
    const { formatMessage } = useFormattedMessage();

    const StatusKYC = userDashboardDetails && userDashboardDetails.StatusKYC;

    const isKycCompleted = !!HIDE_KYC_TILE_STATUS_STEP.find((status) => status === StatusKYC);
    useEffect(() => {
        isKycCompleted && setStep(3);
    }, [isKycCompleted]);

    function setUserDetails(userAdditionalData) {
        let userDetails = { ...userAdditionalData.personal, ...userAdditionalData.identification };
        userDetails = pick(userDetails, USER_KYC_DETAILS_INPUT);
        setUserDetailsStart(userDetails);
    }
    const [step, setStep] = useState(1);
    useEffect(() => {
        if (activeStep) {
            step !== 1 ? (step === 5 ? setStep(3) : setStep(step - 1)) : setKycAtStep1();
            setActiveStep();
        }
    }, [activeStep]);

    const memoizedRenderKycStep = useMemo(() => {
        return (
            <RenderKycStep
                open={open}
                setStep={(step) => (step === null ? onCloseButtonClick() : setStep(step))}
                step={step}
                userAdditionalData={userAdditionalData}
                setInvestmentStepData={setInvestmentStepData}
                setUserDetailsStart={setUserDetails}
                kycCompleted={isKycCompleted}
                onCloseButtonClick={onCloseButtonClick}
            />
        );
    }, [step, userAdditionalData, isKycCompleted, open]);

    useEffect(() => {
        const escFunction = (event) => {
            if (event.key === "Escape") {
                onCloseButtonClick();
            }
        };
        window.addEventListener("keydown", escFunction);
        return () => window.removeEventListener("keydown", escFunction, false);
    }, []);
    return (
        <SideModal
            className="kyc-modal"
            heading={formatMessage(messages.kycMainHeading)}
            open={open}
            isBackArrowOnTop={step === 1}
            isCloseArrowOnRight={isCloseArrowOnRight}
            onBackButtonClick={onBackButtonClick}
            onCloseButtonClick={onCloseButtonClick}
        >
            {memoizedRenderKycStep}
        </SideModal>
    );
};

const mapStateToProps = createStructuredSelector({
    userAdditionalData: selectUserAdditionalData,
    userDashboardDetails: selectUserDashboardDetails,
    userData: selectUserDetails,
});

const mapDispatchToProps = (dispatch) => ({
    setInvestmentStepData: (data) => dispatch(setInvestmentStepData(data)),
    setUserDetailsStart: (data) => dispatch(setUserDetailsStart(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KycModal);
