const pick = require("lodash/pick");

import { familyStatusesMapping, titles, groupedCountriesMapping, PHONE_TYPES } from "constants/index";
import { splitDateStringForProperDate } from "utils/index";

export const extractUserAdditionalData = (user) => {
    let identificationData = pick(user, ["Identification", "Birthday", "PreferredLanguage"]);
    identificationData = {
        PreferredLanguage: identificationData.PreferredLanguage && identificationData.PreferredLanguage.toLowerCase(),
        ...(identificationData.Identification || {}),
        IsPEP: user.IsPEP,
        IsUSTaxation: user.IsUSTaxation,
        IsEffectaCustomerInformationConfirmed: user.IsEffectaCustomerInformationConfirmed,
        taxID: user.TaxID,
        Birthday: identificationData.Birthday
            ? identificationData.Birthday.indexOf("_") > -1
                ? new Date(...splitDateStringForProperDate(identificationData.Birthday)).toISOString()
                : identificationData.Birthday.indexOf("T") > -1
                ? identificationData.Birthday.split("T")[0]
                : new Date(identificationData.Birthday).toISOString()
            : "",
    };

    // Delete after merging the identification fields to identificationData obj
    delete identificationData.Identification;

    identificationData = {
        ...identificationData,
        BirthCountry:
            identificationData &&
            identificationData.BirthCountry &&
            groupedCountriesMapping[1].options.find((item) => identificationData.BirthCountry === item.value),
        Nationality:
            identificationData &&
            identificationData.Nationality &&
            groupedCountriesMapping[1].options.find((item) => identificationData.Nationality === item.value),
    };

    let personalData = pick(user, [
        "BankAccount",
        "Address",
        "FirstName",
        "LastName",
        "TypeGender",
        "Phone",
        "TypeTitle",
        "Email",
        "StatusKYC",
    ]);
    const bankAccount =
        personalData?.BankAccount?.find((account) => account.TypeBankAccount === "typeBankAccountUserPrimary") ||
        personalData?.BankAccount?.[0] ||
        {};
    personalData = {
        ...personalData,
        ...bankAccount,
        ...((personalData && personalData.Address && personalData.Address[0]) || {}),
        ...((personalData && personalData.Phone && personalData.Phone[0]) || {}),
        MaritalStatus: user && user.Identification && user.Identification.MaritalStatus,
    };

    // Delete after merging the BankAccount, Address, Phone fields to personalData obj
    delete personalData.BankAccount;
    delete personalData.Address;
    delete personalData.Phone;

    personalData = {
        ...personalData,
        TypeTitle: (personalData && personalData.TypeTitle && titles.find((item) => personalData.TypeTitle === item.value)) || "",
        MaritalStatus:
            personalData &&
            personalData.MaritalStatus &&
            familyStatusesMapping.find((item) => personalData.MaritalStatus === item.value),
        Country:
            personalData &&
            personalData.Country &&
            groupedCountriesMapping[1].options.find((item) => personalData.Country === item.value),
    };

    const tileData = pick(user, ["Adequacy", "KYC", "Tipster"]);

    return {
        tileData: { adequacy: tileData.Adequacy, kyc: tileData.KYC, tipster: tileData.Tipster },
        personalData,
        identificationData,
    };
};

export const extractUserMobilePhone = (userPhone) => {
    return {
        mobilePhone:
            (Array.isArray(userPhone) &&
                userPhone.find((phone) => phone.TypePhone === PHONE_TYPES.TypePhoneMobile && phone.StatusConfirmed === "true")) ||
            {},
        nonVerifiedMobilePhone:
            (Array.isArray(userPhone) &&
                userPhone.find((phone) => phone.TypePhone === PHONE_TYPES.TypePhoneMobile && phone.StatusConfirmed !== "true")) ||
            null,
    };
};
