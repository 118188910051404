import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Link from "components/NavLink";

import { getBaseApiURL } from "common/helper";
import { selectPlatformSourceFromApp } from "store/app/selectors";

const CustomLink = ({ className = "", url, isExternal = false, children, isApp }) => {
    return (
        <>
            {isExternal && isExternal === true ? (
                isApp ? (
                    <div
                        className={`${className}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                            isApp &&
                                window?.ReactNativeWebView?.postMessage(
                                    JSON.stringify({
                                        external_url: url?.includes?.("http") ? url : getBaseApiURL() + url,
                                    })
                                );
                        }}
                    >
                        {children}
                    </div>
                ) : (
                    <a href={url} className={`${className}`} target="_blank" rel="noopener noreferrer">
                        {children}
                    </a>
                )
            ) : (
                <Link href={url} className={`${className}`}>
                    {children}
                </Link>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    isApp: selectPlatformSourceFromApp,
});

export default connect(mapStateToProps)(CustomLink);
