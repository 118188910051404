import PrevButton from "components/PrevButton";
import NextButton from "components/NextButton";
import SelectInput from "components/SelectInput";
import { yearsOptions } from "constants/index";
import { adequacySkipLinkText } from "i18n/messages/buttonTitles";
import { useSideModalScrollToTop } from "hooks";

const SharingRights = ({
    isSharingRightsVaild,
    setActiveStep,
    formatMessage,
    messages,
    formData,
    setFormData,
    setAdequacyStart,
    adequacyData,
    backSlideEffect = "",
    setBackSlideEffect,
    open,
    StatusAdequacy,
}) => {
    useSideModalScrollToTop();

    return (
        <>
            <div className={`sharing-rights multisteps ${backSlideEffect}`}>
                <div className="header-part">
                    <h4 className="primary-color">{formatMessage(messages.fourthHeading)}</h4>
                </div>
                <div className="content">
                    <form method="post" className="survey-form">
                        <div className="first-question">
                            <h3 className="mt-4 mb-7">{formatMessage(messages.fourthQuestion)}</h3>
                            <div className="tabs">
                                <div>
                                    <input
                                        id="sharingRightsYes"
                                        type="radio"
                                        name="sharing-rights-experience"
                                        onChange={() =>
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                sharing_rights: {
                                                    ...prevState.sharing_rights,
                                                    IsProfitPartXPConfirmed: true,
                                                },
                                            }))
                                        }
                                        checked={formData?.sharing_rights?.IsProfitPartXPConfirmed === true}
                                    />
                                    <label
                                        className="new-btn medium mw-100"
                                        htmlFor="sharingRightsYes"
                                        onClick={(e) =>
                                            jQuery(e.target).closest(".survey-form").find(".second-question").slideDown()
                                        }
                                    >
                                        {formatMessage(messages.yesText)}
                                    </label>
                                </div>

                                <div>
                                    <input
                                        id="sharingRightsNo"
                                        type="radio"
                                        name="sharing-rights-experience"
                                        onChange={() =>
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                sharing_rights: {
                                                    ...prevState.sharing_rights,
                                                    IsProfitPartXPConfirmed: false,
                                                    IsProfitPartXPConfirmedDate: "",
                                                },
                                            }))
                                        }
                                        checked={formData?.sharing_rights?.IsProfitPartXPConfirmed === false}
                                    />
                                    <label
                                        className="new-btn medium mw-100"
                                        htmlFor="sharingRightsNo"
                                        onClick={(e) =>
                                            jQuery(e.target).closest(".survey-form").find(".second-question").slideUp()
                                        }
                                    >
                                        {formatMessage(messages.noText)}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div
                            className="second-question"
                            style={{ display: formData.sharing_rights.IsProfitPartXPConfirmed ? "block" : "none" }}
                        >
                            <div className="border-x" />
                            <h4>{formatMessage(messages.fourthQuestionOther)}</h4>
                            <div className="">
                                <SelectInput
                                    maxMenuHeight={400}
                                    isSearchable={false}
                                    menuPlacement="top"
                                    className="select form-item"
                                    classNamePrefix="select"
                                    name="sharing-rights-experience-years"
                                    options={yearsOptions()}
                                    placeholder={formatMessage(messages.pleaseSelect)}
                                    value={formData?.sharing_rights?.IsProfitPartXPConfirmedDate || ""}
                                    onChange={(selectedOption) => {
                                        setFormData((prevState) => ({
                                            ...prevState,
                                            sharing_rights: {
                                                ...prevState.sharing_rights,
                                                IsProfitPartXPConfirmedDate: selectedOption,
                                            },
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                </div>
                {StatusAdequacy != "renew" && (
                    <span
                        onClick={() => {
                            setAdequacyStart({ step: 2 });
                            setBackSlideEffect("step-slide-right");
                        }}
                        className="skip-link"
                    >
                        {formatMessage(adequacySkipLinkText)}
                    </span>
                )}
            </div>
            {open && (
                <div className="bottom-bar-container">
                    <PrevButton
                        onClick={() => {
                            setActiveStep(3);
                            setBackSlideEffect("step-slide-right");
                        }}
                    />
                    <NextButton
                        className={`${isSharingRightsVaild ? "" : "disable-grey"}`}
                        onClick={() => {
                            if (isSharingRightsVaild) {
                                setAdequacyStart({
                                    step: 6,
                                    survey_second_step: { ...adequacyData.survey_second_step, ...formData, step: 5 },
                                });
                                setActiveStep(5);
                                setBackSlideEffect("step-slide-left");
                            }
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default SharingRights;
